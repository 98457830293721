import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Context } from 'shared-home/src/home/Context';

function Footer({ selectedModel }) {
  let navigate = useNavigate();
  const { form, onComponentChange } = useContext(Context);

  const { t } = useTranslation();

  //MOBILE CHECK
  const [height, setHeight] = useState(window.innerHeight);
  function handleWindowSizeChange() {
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isSmallScreenHeight = height <= 612; // TRUE/FALSE

  const saveURL = () => {
    //NEEDS TO UPDATE BEFORE LEAVING THE PAGE TO SAVE CONFIGURATOR URL WHEN SHARING ON LAST PAGE
    onComponentChange({ configuratorURL: window.location.href });
    return true;
  };

  const redirectInquiry = () => {
    window.dataLayer.push({
      event: 'initiate_configuration'
    });

    let result = saveURL();
    if (result === true) {
      navigate('/inquiry', { replace: true });
    }
  };

  const redirectCustomerInfo = () => {
    window.dataLayer.push({
      event: 'initiate_configuration'
    });

    let result = saveURL();
    if (result === true) {
      navigate('/inquiryCustomerInfo', { replace: true });
    }
  };

  const onLinkShare = () => {
    window.dataLayer.push({
      event: 'configuration_share'
    });

    let result = saveURL();
    if (result === true) {
      if (window.location.protocol === 'https:') {
        // SHOWS INSTALLED APPS WHERE LINK CAN BE SHARED ON MOBILE; ELSE COPIES LINK TO CLIPBOARD
        if (navigator.share) {
          navigator
            .share({
              title: 'Configurator',
              text: 'Door configuration',
              url: form.configuratorURL
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing:', error));
        } else {
          navigator.clipboard.writeText(form.configuratorURL);
          window.alert('URL Copied to clipboard');
        }
      } else {
        window.alert('Copy link in the URL');
      }
    }
  };

  return (
    <>
      {/* DESKTOP */}
      {!isSmallScreenHeight && (
        <footer className="footer footer-configurator fixed-position mobile-hide margin-zero">
          <div className="flex-line align-stretch mobile-hide">
            <div className="footer-configurator-selection bg-dark-gray one-fourth flex-line space-between mobile-hide">
              <h3 className="upper-case white-font">
                {t('chosenModel')} <strong>{selectedModel}</strong>
              </h3>
              <button onClick={onLinkShare} className="white-link flex-line review-order-button">
                <i className="ico ico-save-return review-actions__icon"></i>
                <span className="small upper-case white-font" style={{ textAlign: 'left' }}>
                  {t('save')}
                </span>
              </button>
            </div>
            <button type="button" className="button-footer button-footer--orange upper-case three-fourth flex-line flex-line--desktop justify-center mobile-hide" onClick={redirectInquiry}>
              {t('sendInquiry')}
            </button>
          </div>
        </footer>
      )}

      {/* SMALL HEIGHT || MOBILE LANDSCAPE SWITCH, MINIMIZE ...*/}
      {isSmallScreenHeight && (
        <footer className="footer footer-configurator fixed-position mobile-hide margin-zero">
          <div className="flex-line align-stretch mobile-hide">
            <div className="footer-configurator-selection bg-dark-gray one-fourth flex-line space-between mobile-hide">
              <h3 className="upper-case white-font">
                {t('chosenModel')} <strong>{selectedModel}</strong>
              </h3>
              <button onClick={onLinkShare} className="white-link flex-line review-order-button">
                <i className="ico ico-save-return review-actions__icon"></i>
                <span className="small upper-case white-font" style={{ textAlign: 'left' }}>
                  {t('save')}
                </span>
              </button>
            </div>
            <button type="button" className="button-footer button-footer--orange upper-case three-fourth flex-line flex-line--desktop justify-center mobile-hide" onClick={redirectInquiry}>
              {t('sendInquiry')}
            </button>
          </div>
        </footer>
      )}

      {/* MOBILE */}
      <footer className="footer footer-configurator fixed-position desktop-hide margin-zero">
        <div className="flex-line align-stretch desktop-hide">
          <button type="button" className="button-footer button-footer--orange upper-case full flex-line justify-center desktop-hide" onClick={redirectCustomerInfo}>
            {t('sendInquiry')}
          </button>
        </div>
      </footer>
    </>
  );
}

export default Footer;
