import React, { useContext, useState, useEffect } from 'react';
import { Context } from 'shared-home/src/home/Context';
import { useTranslation } from 'react-i18next';

const TipKonstrukcije = React.forwardRef((props, ref) => {
  const { form, onComponentChange, catalogue } = useContext(Context);

  const [imageIndex, setImageIndex] = useState();
  const { t } = useTranslation();

  const selectedConstructionType = (item, idx) => {
    setImageIndex(idx);
    onComponentChange({
      constructionType: item.id
      //nastavim vrednost se na form.dimwidth/height??
    });
  };

  //NASTAVI SELECTED TIP NA KONFIGURATORJU KO PRIDE IZ URL
  useEffect(() => {
    if (catalogue.tipiKonstrukcijeDesno !== undefined) {
      catalogue.tipiKonstrukcijeDesno.forEach((item, idx) => {
        if (item.id === form.constructionType) {
          setImageIndex(idx);
        }
      });
    }

    if (catalogue.tipiKonstrukcijeLevo !== undefined) {
      catalogue.tipiKonstrukcijeLevo.forEach((item, idx) => {
        if (item.id === form.constructionType) {
          setImageIndex(idx);
        }
      });
    }
  }, [catalogue.tipiKonstrukcijeDesno, catalogue.tipiKonstrukcijeLevo]);

  //NASTAVI SELECTED TIP OB KLIKU
  useEffect(() => {
    if (form.doorOpeningLeftSide === true) {
      if (catalogue.tipiKonstrukcijeLevo !== undefined) {
        catalogue.tipiKonstrukcijeLevo.forEach((item, idx) => {
          if (item.id === form.constructionType) {
            setImageIndex(idx);
          }
        });
      }
    }
    if (form.doorOpeningRightSide === true) {
      if (catalogue.tipiKonstrukcijeDesno !== undefined) {
        catalogue.tipiKonstrukcijeDesno.forEach((item, idx) => {
          if (item.id === form.constructionType) {
            setImageIndex(idx);
          }
        });
      }
    }
  }, [form.doorOpeningRightSide, form.doorOpeningLeftSide, form.constructionType]);

  if (catalogue.tipiKonstrukcijeLevo === undefined || catalogue.tipiKonstrukcijeDesno === undefined) {
    return <div className="section select-construction" id="conf-step-num-3" step="3" ref={ref} />;
  } else {
    return (
      <>
        <div className="section select-construction" id="conf-step-num-3" step="3" ref={ref}>
          <div className="container">
            <h2 className="upper-case">{t('confConstructionType')}</h2>
            <ul className="no-bullets flex-line flow-wrap">
              {form.doorOpeningLeftSide &&
                catalogue.tipiKonstrukcijeLevo.map((item, idx) => (
                  <li key={idx} className={idx === imageIndex ? 'active' : 'unactive'}>
                    <div key={idx}>
                      <img src={'./images/DIN/' + item.dinType + '.svg'} onClick={() => selectedConstructionType(item, idx)} className="active cursor-pointer" alt={item.dinType} />
                    </div>
                  </li>
                ))}
              {form.doorOpeningRightSide &&
                catalogue.tipiKonstrukcijeDesno.map((item, idx) => (
                  <li key={idx} className={idx === imageIndex ? 'active' : 'unactive'}>
                    <div key={idx}>
                      <img src={'./images/DIN/' + item.dinType + '.svg'} onClick={() => selectedConstructionType(item, idx)} className="active cursor-pointer" alt={item.dinType} />
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </>
    );
  }
});
export default TipKonstrukcije;
