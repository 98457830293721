import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import OdpiranjeVrat from './konfiguratorComponents/OdpiranjeVrat';
import TipKonstrukcije from './konfiguratorComponents/TipKonstrukcije';
import Barve from './konfiguratorComponents/Barve';
import Dimenzije from './konfiguratorComponents/Dimenzije';
import Stekla from './konfiguratorComponents/Stekla';
import Drzala from './konfiguratorComponents/Drzala';
import Zaklepanje from './konfiguratorComponents/Zaklepanje';
import Dodatki from './konfiguratorComponents/Dodatki';
import Header from './konfiguratorComponents/Header';
import PrototipVrat from './konfiguratorComponents/PrototipVrat';
import IzborModela from './konfiguratorComponents/IzborModela';
import Footer from './konfiguratorComponents/Footer';
import { Context } from 'shared-home/src/home/Context';

function DoorConfiguration() {
  const { form, setForm, onComponentChange } = useContext(Context);

  const izborModelaRef = React.useRef();
  const odpiranjeVratRef = React.useRef();
  const tipKonstrukcijeRef = React.useRef();
  const dimenzijeRef = React.useRef();
  const barveRef = React.useRef();
  const steklaRef = React.useRef();
  const drzalaRef = React.useRef();
  const zaklepanjeRef = React.useRef();
  const dodatkiRef = React.useRef();
  let urlParams = useParams();
  const dovoljeniDodatki = form.defaults && form.defaults.dovoljeniDodatki;
  //MOBILE CHECK
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768; // TRUE/FALSE

  // Scroll to top on page render
  useMemo(() => {
    if (isMobile) {
      window.scrollTo(0, 0);
    }
  }, []);

  //NAPOLNI FORMO IZ URL PARAMETROV
  useEffect(() => {
    if (Object.keys(form).length === 0) {
      let filtered = Object.entries(urlParams).reduce((arr, [k, v]) => {
        if (k === 'modelName' && v !== '-1') {
          arr.push([k, v]);
        }
        if (k === 'houseNumber' && v !== '-1') {
          arr.push([k, v]); //houseNumber can contain non-numeric characters
        } else if ((k === 'colorWingOutsideId' && v !== '-1') || (k === 'colorFrameOutsideId' && v !== '-1') || (k === 'colorAplikacijeOutsideId' && v !== '-1') || (k === 'colorDekorOutsideId' && v !== '-1') || (k === 'colorOkvirckiOutsideId' && v !== '-1') || (k === 'colorWingInsideId' && v !== '-1') || (k === 'colorFrameInsideId' && v !== '-1') || (k === 'colorAplikacijeInsideId' && v !== '-1') || (k === 'colorDekorInsideId' && v !== '-1') || (k === 'colorOkvirckiInsideId' && v !== '-1') || (k === 'outsideHandleColorsId' && v !== '-1')) {
          arr.push([k, v.split(',')]);
        } else if ((k === 'knockerPosition' && v !== '-1') || (k === 'houseNumberPosition' && v !== '-1') || (k === 'peepholePosition' && v !== '-1')) {
          arr.push([k, v.split(',').map(Number)]);
        } else if (+v >= 0) {
          arr.push([k, +v]);
        }
        return arr;
      }, []);

      console.log('form set!');
      setForm(Object.fromEntries(filtered));
    }
  }, []);

  if (form.defaults && form.defaults.modelName !== form.modelName) {
    onComponentChange({ modelName: form.defaults.modelName });
  }
  const dodatkiVidni = dovoljeniDodatki && (dovoljeniDodatki.tolkala || dovoljeniDodatki.kukala || dovoljeniDodatki.hisneStevilke || dovoljeniDodatki.odkapnaLetev);

  return (
    <>
      <body id="main">
        <div id="grid-wrapper">
          <Header menuRefs={[izborModelaRef, odpiranjeVratRef, tipKonstrukcijeRef, dimenzijeRef, barveRef, steklaRef, drzalaRef, zaklepanjeRef, dodatkiRef]} />
          <div className="configurator-wrapper">
            <PrototipVrat />
            <div className="filters-selection main-canvas main-canvas--bg-gray main-door-removed">
              <IzborModela ref={izborModelaRef} selected={form.modelName} />
              <OdpiranjeVrat ref={odpiranjeVratRef} />
              <TipKonstrukcije ref={tipKonstrukcijeRef} />
              <Dimenzije ref={dimenzijeRef} />
              <Barve ref={barveRef} />
              <Stekla ref={steklaRef} />
              <Drzala ref={drzalaRef} />
              <Zaklepanje ref={zaklepanjeRef} />
              {dodatkiVidni && <Dodatki ref={dodatkiRef} />}
            </div>
          </div>
          <Footer selectedModel={form.modelName} />
        </div>
      </body>
    </>
  );
}

export default DoorConfiguration;
