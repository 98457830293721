export const translationHr = {
	modelType: "Modeli",
	chooseModel: "PROGRAMI ULAZNIH VRATA",
	glass: "Staklo",
	modelShape: "Oblika stakla",
	SearchApp: "Aplikacije na modelu",
	chosenModel: "Izabrani model vrat",
	configureDoors: "Sastavi svoja vrata",
	allDoors: "Svi",
	findModel: "Pretraga modela",
	showResults: "Prikaži rezultate",
	filter: "Filteri",
	save: "Spremi i nastavi kasnije",
	sendInquiry: "Pošalji upit",
	headerModel: "Izbor modela vrata",
	configurator: "Konfigurator",
	confDoorOpening: "Smjer otvaranja",
	confLeft: "Lijevo",
	confRight: "Desno",
	confConstructionType: "Tip konstrukcije",
	confDimensions: "Dimenzije",
	confHeight: "Visina",
	confWidth: "Širina",
	confHeightExample: "Npr. 2100 mm",
	confWidthExample: "Npr. 1050 mm",
	confExample: "Npr.",
	confSkipDimensions: "Ako trenutno nemate podatke o dimenzijama možete preskoćiti ovaj korak",
	confColors: "Boja",
	confColWhole: "Farbaj cjelinu",
	confColAdvanced: "Napredno farbanje",
	confCol1: "Boja 1",
	confCol2: "Boja 2",
	confMoreColors: "Prikaži više boja",
	confColorTop: "TOP 10 najprodavanijih boja",
	confFindColor: "Potraži boju",
	confColorGroup: "GRUPE BOJA",
	confColorShade: "Struktura boje",
	confColMa: "Mat",
	confColSt: "Strukturna",
	confColShine: "Sjaj",
	confColLess: "Sakrij više boja",
	confColReset: "Poništi",
	confColWing: "Krilo",
	confColOutside: "Izvana",
	confColInside: "Iznutra",
	confColFrame: "Okvir vrata",
	confGlass: "Stakla",
	confMotGlass: "STAKLA SA MOTIVOM",
	confOrnGlass: "ORNAMENTNA STAKLA",
	confHandle: "Rukohvati",
	confHandleOutside: "RUKOHVAT IZVANA",
	confHandleInside: "Kvaka",
	confHandleDesc: "Opis",
	confHandleDim: "Dimenzije",
	confLock: "ZAKLJUČAVANJE",
	confLockTop: "Najpopularniji sistemi zaključavanja",
	confLockTypes: "Prikaži više načina zaključavanja",
	confLockAll: "Svi sistemi zaključavanja",
	confLockLess: "Sakrij više načina zaključavanja",
	confExtras: "Dodaci",
	confExtrasBattens: "Zaštitni profil",
	confSummary: "Sažetak konfiguracije",
	confContinue: "Nastavi kasnije",
	confLook: "Pogled",
	confBackground: "Pozadina",
	confPicture: "Slika",
	confChooseModel: "Izbor modela vrata",
	confPickedModel: "Odabrani model",
	confSwitchModel: "Promjeni model",
	confHouseNo: "Kućni broj",
	confPeephole: "Špijunka",
	confKnocker: "Kucalo",
	confHouseInput: "Upišite vaš kućni broj",
	confAdjust: "Prilagodi",
	confData: "Vaši podaci",
	InqName: "Ime",
	InqSurname: "Prezime",
	InqAddress: "Adresa",
	InqPost: "Poštanski br.",
	InqCity: "Mjesto",
	InqEmail: "E-mail",
	InqEmDesc: "Potrebna nam je vaša email adresa kako bismo vam poslali okvirnu ponudu za vaša ulazna vrata",
	InqPhone: "Telefon",
	InqPhoneDesc: "Vaš broj telefona trebamo samo u slučaju da nam zatrebaju dodatne informacije za pripremu vaše ponude.",
	InqMessage: "Napomena",
	InqDoor: "Da li se radi o sanaciji ili novogradnji?",
	InqMark: "Prikladno označite",
	InqNew: "Novogradnja",
	InqChange: "Sanacija",
	InqContact: "Molimo kontaktirati u vezi savjetovanja, prodaje i ugradnje.",
	InqAgreement: "Suglasan sam, da se u svrhu savjetovanja i ponude, moji kontakt podaci proslijede prodavatelju ili ovlaštenom partneru.",
	InqNews: "Besplatno slanje kataloga.",
	InqFinish: "Pošalji upit",
	InqRequired: "Obavezno polje",
	InqSummary: "Sažetak konfiguracije",
	InqConfSummary: "Popis cijele konfiguracije",
	InqPDF: "Prenesi u PDF",
	InqShare: "Dijeli vezu",
	InqVisit: "Posjetite nas u salonu",
	InqModel: "Model",
	InqColOutside: "Izvana",
	InqColInside: "Iznutra",
	InqLeverInside: "Kvaka",
	InqLeverOutside: "Rukohvat",
	InqHouseNo: "Kućni broj",
	InqDripBattens: "Zaštitni profil",
	InqKnocker: "Kucalo",
	InqPeephole: "Špijunka",
	close: "Zatvori",
	classicType: "Klasični",
	modernType: "Moderni",
	extravagantType: "Ekstravagantni",
	simpleType: "Jednostavni",
	topSeller: "Najprodavaniji",
	glassNone: "Bez",
	glassSmall: "Malo",
	glassMedium: "Srednje",
	glassBig: "Veliko",
	shapeRectangle: "Pravokutnici",
	shapeSquare: "Kvadrati",
	shapeCurves: "Krivulje i lukovi",
	shapeOther: "Ostali oblici",
	searchMulticolor: "Dekor",
	searchInox: "Inox dodaci",
	searchUtori: "Reljefi",
	BGColor: "Izaberi boju pozadine",
	SelectBGColor: "Odaberi boju",
	BGPictureTitle: "Umetnite sliku",
	BGPictureDesc: "Umetnite sliku svoje kuće ili odaberite sliku iz niza fotografija u nastavku.",
	BGPicturestep1: "1. korak",
	BGPictureTip1: "Učitaj ili izaberi sliku",
	BGPicturestep2: "2. korak",
	BGPictureTip2: "Pozicioniraj vrata",
	BGPicturestep3: "2. korak",
	BGPictureTip3: "Nastavi s konfiguracijom",
	BGPictureUpload: "Učitaj sliku iz računala",
	BGPictureDesc2: "ili izaberi sliku iz donjeg niza fotografija",
	BGPictureChoose: "Upotrebi sliku",
	confOpening: "Otvaranje",
	confThankYouPage: "Zahvaljujemo na upitu!",
	noModelResults: "Nema rezultata",
	confAppOut: "Aplikacija izvana",
	confAppIn: "Aplikacija iznutra",
	confDekorOut: "Dekor izvana",
	confDekorIn: "Dekor iznutra",
	confWindowsOut: "Okviri izvana",
	confWindowsIn: "Okviri iznutra",
	confHandleOutsideMainColor: "Izabrana boja",
	CustomerInformation: "Vaši podaci",
	CustomerContact: "Kontakt podaci",
	CustomerNameSurname: "Ime i prezime",
	CustomerAddress: "Ulica i kućni broj",
	CustomerPostCity: "Mjesto i poštanski broj",
	CustomerCountry: "Država",
	CustomerEmail: "E-mail",
	CustomerPhone: "Telefonski broj",
	CustomerNote: "Napomene uz narudžbu",
	CustomerDoorSetup: "Vrata trebate za sanaciju ili novogradnju?",
	TyPageDownload: "Prenesi konfiguraciju u pdf dokument",
	TyPageShare: "Dijeli vezu do konfiguracije",
	TyPageAppreciation: "Sretni smo i počašćeni što ste zainteresirani za kupnju Inotherm ulaznih vrata. Čvrsto vjerujemo da će savršeno pristajati uz vaše pročelje, a ujedno ćete se njihovim odabirom pridružiti tisućama zadovoljnih korisnika diljem svijeta čije domove već krase Inotherm ulazna vrata.",
	TyPageHeader: "Hvala na upitu!",
	TyPageDescription1: "U gornjoj tablici možete vidjeti sve detalje vaše trenutne konfiguracije, za koju ćete također dobiti neobvezujuću ponudu unutar sljedeća 24 sata.",
	TyPageDescription2: "Ubrzo će Vam se javiti i naš prodajni stručnjak s kojim možete dogovoriti daljnje korake poput izmjene konfiguracije, mjerenja zidnog otvora i konačne narudžbe.",
	model: "Model",
	confHandleOutsideExtraColoring: "Dodatne boje",
	confHandleOutsideColor: "Boja rukohvata",
	confHandleOutsideAdditionalColor: "Boja udubljenja",
	confColorsOutside: "Vanjske boje",
	confColorsInside: "Unutarnje boje",
	TyPageCopyRights: "All rights reserved 2023",
	logoLink: "https://www.inotherm.hr/",
	InoWebsite: "Na našu web stranicu",
	positionHouseNum: "Pozicija kućnog broja na vratima",
	positionKnocker: "Pozicija kucala na vratima",
	positionPeephole: "Pozicija špijunke na vratima",
	positionInfo: "Pomoću donjeg alata postavite dodatnu opremu u željeni položaj na vratima.",
	positionHorizontal: "HORIZONTALNO",
	positionVertical: "VERTIKALNO",
	headerFilterVsi: "Svi modeli",
	headerFilterAkcija24: "Akcija 24",
	headerFilterZimska: "Zimska akcija",
	headerFilterTop: "Top modeli",
	headerFilterAkcija: "Black Edition",
	openFilters: "FILTERI",
	clearFilters: "Očisti filtere",
	useFilters: "Prikaži rezultate",
	bannerText: "IZABERITE MODEL ULAZNIH VRATA I POČNITE SA OBLIKOVANJEM SVOG ULAZA",
	miniCokel: "Niski parapet",
}
