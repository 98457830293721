import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from 'shared-home/src/home/Context';
import BarveMultiBarvanja from './BarveMultiBarvanja';

const Barve = React.forwardRef((props, ref) => {
  const { form, catalogue } = useContext(Context);

  const { t } = useTranslation();
  const [tabColoring, setTabColoring] = useState(true);
  const [tabAdvanced, setTabAdvanced] = useState(false);
  const [basicEditor, setBasicEditor] = useState(true);
  const [blackEdition, setBlackEdition] = useState(false);
  const [akcija2024, setAkcija2024] = useState(false);

  //PRIKAŽI / SKRIJ OSNOVNO BARVANJE GLEDE NA 'enableBasicEditor' BOOL
  useEffect(() => {
    if (window.inoAPI) {
      const blackEdition = window.inoAPI.getSestav().isBlackEdition();
      const akcija2024 = window.inoAPI.getSestav().isAkcija2024();
      setBlackEdition(blackEdition);
      setAkcija2024(akcija2024);
      if (blackEdition) {
        setBasicEditor(false);
        setTabAdvanced(true);
        setTabColoring(false);
      } else if (akcija2024) {
        setBasicEditor(true);
        setTabAdvanced(false);
        setTabColoring(true);
      } else {
        const sestavaBarv = JSON.parse(window.inoAPI.getSestav().getBarveUtil().getBarve());
        if (sestavaBarv.enableBasicEditor === true) {
          setBasicEditor(true);
        } else {
          setBasicEditor(false);
        }
      }
    }
  }, [form.colorsMain, form.colorWingOutside, form.colorWingInside, form.colorFrameInside, form.colorFrameOutside, form.colorDekorInside, form.colorDekorOutside, form.colorOkvirckiInside, form.colorOkvirckiOutside, form.colorAplikacijeInside, form.colorAplikacijeOutside]);

  const onTabChange = (e) => {
    if (e.target.name === 'fullColoring') {
      setTabColoring(true);
      setTabAdvanced(false);
    }
    if (e.target.name === 'advancedColoring') {
      setTabAdvanced(true);
      setTabColoring(false);
    }
  };
  const barvePanela = form['colorWingOutside'];
  const barveDekorja = form['colorDekorOutside'];

  function isLesenaBarva(barve) {
    if (barve) {
      for (const b of barve) {
        if (b.barva.treeId == 5 || b.barva.treeId == 41) {
          return true;
        }
      }
    }
    return false;
  }
  return (
    <>
      <div className="section select-color" id="conf-step-num-5" step="5" ref={ref}>
        <div className="container">
          <h2 className="upper-case">{t('confColors')}</h2>
          {!blackEdition && !akcija2024 && (
            <ul className="no-bullets flex-line tabs-menu tabs-menu--colors">
              <li className="tabs-menu__element one-half" style={{ display: 'flex', justifyContent: 'center' }}>
                <button className={'tabs-menu__link tabs-titles tabs-button-width ' + (tabColoring && 'tabs-menu__link--active')} name="fullColoring" onClick={onTabChange} style={{ cursor: 'pointer', textTransform: 'uppercase' }}>
                  {t('confColWhole')}
                </button>
              </li>
              <li className="tabs-menu__element one-half">
                <button className={'tabs-menu__link tabs-titles tabs-button-width ' + (tabAdvanced && 'tabs-menu__link--active')} name="advancedColoring" onClick={onTabChange} style={{ cursor: 'pointer', textTransform: 'uppercase' }}>
                  {t('confColAdvanced')}
                </button>
              </li>
            </ul>
          )}
          <div className="tabs tabs--colors">
            {/* OSNOVNO BARVANJE */}
            {tabColoring && (
              <div id="full-coloring" className="tabs__tab">
                {basicEditor ? <>{catalogue.mainColorsListTop && catalogue.mainColorsList ? <BarveMultiBarvanja title={t('confColors')} colForm={'colorsMain'} topList={catalogue.mainColorsListTop} list={catalogue.mainColorsList} mainColors={true} /> : ''}</> : 'Too many colors selected for basic coloring'}
              </div>
            )}
            {/* NAPREDNO BARVANJE */}
            {tabAdvanced && (
              <div id="advanced-coloring" className="tabs__tab">
                {/* ZUNANJE BARVE */}
                <h3 className="tabs__title">{t('confColOutside')}</h3>

                <BarveMultiBarvanja title={t('confColWing')} colForm={'colorWingOutside'} topList={catalogue.kriloTopList} list={catalogue.kriloList} mainColors={false} blackEdition={window.inoAPI.getSestav().isBlackEdition()} blackEditionLimitColors={blackEdition && isLesenaBarva(barveDekorja)} />

                {!blackEdition && <BarveMultiBarvanja title={t('confColFrame')} colForm={'colorFrameOutside'} topList={catalogue.okvirTopList} list={catalogue.okvirList} mainColors={false} blackEdition={window.inoAPI.getSestav().isBlackEdition()} />}
                {form.colorAplikacijeOutside && form.colorAplikacijeOutside.length > 0 && <BarveMultiBarvanja title={t('confAppOut')} colForm={'colorAplikacijeOutside'} topList={catalogue.aplikacijeTopList} list={catalogue.aplikacijeList} mainColors={false} blackEdition={window.inoAPI.getSestav().isBlackEdition()} />}

                {form.colorDekorOutside && form.colorDekorOutside.length > 0 && <BarveMultiBarvanja title={t('confDekorOut')} colForm={'colorDekorOutside'} topList={catalogue.dekorjiTopList} list={catalogue.dekorjiList} mainColors={false} blackEdition={window.inoAPI.getSestav().isBlackEdition()} blackEditionLimitColors={blackEdition && isLesenaBarva(barvePanela)} />}

                {form.colorOkvirckiOutside && form.colorOkvirckiOutside.length > 0 && <BarveMultiBarvanja title={t('confWindowsOut')} colForm={'colorOkvirckiOutside'} topList={catalogue.okvirckiTopList} list={catalogue.okvirList} mainColors={false} blackEdition={window.inoAPI.getSestav().isBlackEdition()} />}

                {/* NOTRANJE BARVE */}
                <h3 className="tabs__title">{t('confColInside')}</h3>

                <BarveMultiBarvanja title={t('confColWing')} colForm={'colorWingInside'} topList={catalogue.kriloTopList} list={catalogue.kriloList} mainColors={false} blackEdition={window.inoAPI.getSestav().isBlackEdition()} blackEditionLimitColors={true} />

                {!blackEdition && <BarveMultiBarvanja title={t('confColFrame')} colForm={'colorFrameInside'} topList={catalogue.okvirTopList} list={catalogue.okvirList} mainColors={false} blackEdition={window.inoAPI.getSestav().isBlackEdition()} blackEditionLimitColors={true} />}

                {form.colorAplikacijeInside && form.colorAplikacijeInside.length > 0 && <BarveMultiBarvanja title={t('confAppIn')} colForm={'colorAplikacijeInside'} topList={catalogue.aplikacijeTopList} list={catalogue.aplikacijeList} mainColors={false} blackEdition={window.inoAPI.getSestav().isBlackEdition()} blackEditionLimitColors={true} />}

                {form.colorDekorInside && form.colorDekorInside.length > 0 && <BarveMultiBarvanja title={t('confDekorIn')} colForm={'colorDekorInside'} topList={catalogue.dekorjiTopList} list={catalogue.dekorjiList} mainColors={false} blackEdition={window.inoAPI.getSestav().isBlackEdition()} blackEditionLimitColors={true} />}

                {form.colorOkvirckiInside && form.colorOkvirckiInside.length > 0 && <BarveMultiBarvanja title={t('confWindowsIn')} colForm={'colorOkvirckiInside'} topList={catalogue.okvirckiTopList} list={catalogue.okvirList} mainColors={false} blackEdition={window.inoAPI.getSestav().isBlackEdition()} blackEditionLimitColors={true} />}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
});

export default Barve;
