import React, { useContext, useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { Context } from 'shared-home/src/home/Context';
import { useTranslation } from 'react-i18next';
import { settings } from '../common/sliderSettings';
import InoAPIclient from '../../api/InoAPIclient';
import Barvanje from './Barvanje';
import { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import { overrideTooltipPosition } from '../../utils/helper';

const Drzala = React.forwardRef((props, ref) => {
  const { form, onComponentChange, catalogue, setCatalogue } = useContext(Context);

  const [dimenzijeShow, setDimenzijeShow] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState();
  const [selectedKljukaDim, setSelectedKljukaDim] = useState();
  const [selectedKljuka, setSelectedKljuka] = useState();
  const [barva1, setBarva1] = useState();
  const [barva2, setBarva2] = useState();
  const [dimensionsCounter, setDimensionsCounter] = useState();
  const [blackEdition, setBlackEdition] = useState(false);
  const [outsideHandleIdLoaded, setOutsideHandleIdLoaded] = useState(false);
  let barveIdArray = [];

  const sliderOutsideHandlesRef = useRef();
  const sliderInsideHandlesRef = useRef();
  const sliderDimensionsRef = useRef();
  const { t } = useTranslation();
  const inoApiCall = new InoAPIclient();

  //MOBILE CHECK
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768 || height <= 612; // TRUE/FALSE

  useEffect(() => {
    selectedGroup && allDimensions(selectedGroup, true, true);

    if (window.inoAPI) {
      const blackEdition = window.inoAPI.getSestav().isBlackEdition();
      const akcija2024 = window.inoAPI.getSestav().isAkcija2024();
      setBlackEdition(blackEdition || akcija2024);
    }
  }, [catalogue.zunDrzala]);

  const selectedZunDrzaloSkupine = (item, fromUrl) => {
    const skupineDolzina = item.skupineByDolzina;
    const skupine = item.skupine;
    setSelectedGroup(item);
    if (Object.keys(skupineDolzina).length === 0) {
      setDimenzijeShow(false);
      //če pride iz URL ne ponastavimo trenutno kljuko
      !fromUrl && selectedZunDrzaloDim(skupine[0]);
      setCatalogue((cat) => ({ ...cat, outsideHandleGroups: skupine }));
      onComponentChange({
        insideHandle: null
      });
    } else {
      setDimenzijeShow(true);
    }
    allDimensions(item, fromUrl);
    setCatalogue((cat) => ({ ...cat, outsideHandleCol1List: null, outsideHandleCol2List: null }));

    //Skrij/pokazi notranje kljuke glede na izbrano skupino

    //če pridejo barve iz url se ne pobrišejo iz linka
    !fromUrl &&
      onComponentChange({
        outsideHandleColorsId: null,
        outsideHandleCol1: null,
        outsideHandleCol2: null
      });
  };
  const selectedZunDrzaloDim = (item, fromUrl) => {
    setSelectedKljukaDim(item);
    if (item.kljukeBarve.length > 0) {
      //check ali izbrana barva obstaja tudi pri drugi dimenziji
      if (selectedKljuka && item.kljukeBarve.length > 1) {
        let selectedBarva = item.kljukeBarve && item.kljukeBarve.find((e) => e.barva.composedId === selectedKljuka.barvaKljuke.composedId); //CHECK
        //če izbrana barva ne obstaja na drugem drzalu nastavimo na prvo
        selectedBarva ? selectedZunDrzalo(selectedBarva) : selectedZunDrzalo(item.kljukeBarve[0]);
        onComponentChange({
          outsideHandleDimensions: true
        });
      } else {
        selectedZunDrzalo(item.kljukeBarve[0]);
      }
      setCatalogue((cat) => ({ ...cat, outsideHandles: item.kljukeBarve }));
    } else {
      onComponentChange({
        outsideHandle: item.defaultKljuka.id,
        outsideHandleDimensions: true
      });
      selectedKljukaBarveList(item.defaultKljuka);
      setCatalogue((cat) => ({ ...cat, outsideHandles: null }));
    }
    setCatalogue((cat) => ({ ...cat, outsideHandleCol1List: null, outsideHandleCol2List: null }));
  };

  const selectedZunDrzalo = (item) => {
    setSelectedKljuka(item.kljuka);
    selectedKljukaBarveList(item.kljuka);
    onComponentChange({
      outsideHandle: item.kljuka.id
    });
  };

  //NASTAVI IDJE V URL
  useEffect(() => {
    if (form.outsideHandleCol1) {
      barveIdArray[0] = form.outsideHandleCol1.composedId.replace(/[^a-zA-Z0-9]/g, '');
    }
    if (form.outsideHandleCol2) {
      barveIdArray[1] = form.outsideHandleCol2.composedId.replace(/[^a-zA-Z0-9]/g, '');
    }

    onComponentChange({
      outsideHandleColorsId: barveIdArray
    });
  }, [form.outsideHandleCol1, form.outsideHandleCol2]);

  //preveri če ima kljuka barve, če ima nastavi katalog barv za vsako barvanje
  const selectedKljukaBarveList = (item) => {
    const model = JSON.parse(window.inoAPI.getSestav().getBarveUtil().getFilteredProspekt());
    let selectedColor = item.kljuka ? item.kljuka : item;
    const outsideHandleCol1ListTop = JSON.parse(window.inoAPI.getSestav().getBarveUtil().getKljukaBarveBlackEdition());
    if (selectedColor.barva) {
      setBarva1(form.outsideHandleCol1 ? form.outsideHandleCol1 : selectedColor.barvaKljuke);

      inoApiCall.barveTip({ types: [selectedColor.barvnaSkupina], filterProspekt: model }).then((res) => {
        if (res) {
          setCatalogue((cat) => ({ ...cat, outsideHandleCol1List: res }));
        }
      });
      if (blackEdition) {
        setCatalogue((cat) => ({ ...cat, outsideHandleCol1ListTop: outsideHandleCol1ListTop }));
      } else {
        inoApiCall.barveTop({ types: [selectedColor.barvnaSkupina], filterProspekt: model }).then((res) => {
          if (res) {
            setCatalogue((cat) => ({ ...cat, outsideHandleCol1ListTop: res }));
          }
        });
      }
    }
    if (selectedColor.barva2) {
      setBarva2(form.outsideHandleCol2 ? form.outsideHandleCol2 : selectedColor.barvaKljuke2);
      inoApiCall.barveTip({ types: [selectedColor.barvnaSkupina2], filterProspekt: model }).then((res) => {
        if (res) {
          setCatalogue((cat) => ({ ...cat, outsideHandleCol2List: res }));
        }
      });
      if (blackEdition) {
        setCatalogue((cat) => ({ ...cat, outsideHandleCol2ListTop: outsideHandleCol1ListTop }));
      } else {
        inoApiCall.barveTop({ types: [selectedColor.barvnaSkupina2], filterProspekt: model }).then((res) => {
          if (res) {
            setCatalogue((cat) => ({ ...cat, outsideHandleCol2ListTop: res }));
          }
        });
      }
    } else {
      setCatalogue((cat) => ({ ...cat, outsideHandleCol1List: null, outsideHandleCol2List: null }));
    }
  };

  const selectedNotrDrzalo = (item) => {
    onComponentChange({
      insideHandle: item.id
    });
  };

  //če dobimo kljuko iz url
  useEffect(() => {
    let kljukaId = form.outsideHandle + 'id';
    if (catalogue.zunanjaDrzalaMap) {
      var selectedPath = catalogue.zunanjaDrzalaMap[kljukaId];
      if (selectedPath) {
        var selectedGroup = selectedPath[0];
        var selectedKljukaDim = selectedPath[1];
        var selectedBarvaKljuka = selectedPath[2];
        selectedZunDrzaloSkupine(selectedGroup, true);
        setSelectedKljukaDim(selectedKljukaDim, true);
        if (selectedKljukaDim.kljukeBarve.length > 0) {
          if (selectedBarvaKljuka.kljuka) {
            setSelectedKljuka(selectedBarvaKljuka.kljuka);
          } else {
            setSelectedKljuka(selectedBarvaKljuka);
          }
          setCatalogue((cat) => ({ ...cat, outsideHandles: selectedKljukaDim.kljukeBarve }));
        }
        selectedKljukaBarveList(selectedBarvaKljuka);
      }
    }
  }, [catalogue.zunanjaDrzalaMap]);

  const allDimensions = (selectedGroup, fromUrl, refresh) => {
    if (selectedGroup) {
      const dimenzijeZunDrzal =
        catalogue.zunDrzala &&
        catalogue.zunDrzala
          .filter(function (item) {
            return item.id === selectedGroup.id;
          })
          .map(function ({ skupine }) {
            return { skupine };
          });
      if (!fromUrl) {
        // če pride iz urlja ne nastavis prvo dimenzijo po defaultu
        selectedZunDrzaloDim(dimenzijeZunDrzal[0].skupine[0]);
      }
      if (refresh) {
        let skupine = dimenzijeZunDrzal[0].skupine;
        let dimenzijaFound = skupine && skupine.find((e) => e.id === selectedKljukaDim.id);
        if (!dimenzijaFound) {
          if (skupine[skupine.length - 1].infoB2C === 'Flügellang') {
            selectedZunDrzaloDim(skupine[skupine.length - 2]);
          } else selectedZunDrzaloDim(skupine[skupine.length - 1]);
        }
      }
      setCatalogue((cat) => ({ ...cat, handleDimensions: dimenzijeZunDrzal[0].skupine }));
      setDimensionsCounter(dimenzijeZunDrzal[0].skupine.length);
    }
  };

  //SCROLL INSTANTLY TO SELECTED INSIDE/OUTSIDE HANDLE AND DIMENSION ON RELOAD
  useEffect(() => {
    if (sliderInsideHandlesRef.current) {
      const selectedInsideHandleIdx = catalogue.notrDrzala && catalogue.notrDrzala.findIndex((item) => form.insideHandle === item.id);
      sliderInsideHandlesRef.current.slickGoTo(Math.max(Math.min(selectedInsideHandleIdx, selectedInsideHandleIdx - 4), 0), false);
    }

    if (sliderOutsideHandlesRef.current && selectedGroup) {
      const selectedOutsideHandleIdx = catalogue.zunDrzala && catalogue.zunDrzala.findIndex((item) => selectedGroup.id === item.id);
      sliderOutsideHandlesRef.current.slickGoTo(Math.max(Math.min(selectedOutsideHandleIdx, selectedOutsideHandleIdx - 4), 0), false);
    }
  }, [catalogue.notrDrzala, catalogue.zunDrzala, outsideHandleIdLoaded]);

  useMemo(() => {
    if (sliderDimensionsRef.current && selectedKljukaDim) {
      const selectedDimension = catalogue.handleDimensions && catalogue.handleDimensions.findIndex((item) => selectedKljukaDim.id === item.id);

      if (selectedDimension > 5) {
        sliderDimensionsRef.current.slickGoTo(Math.max(Math.min(selectedDimension, selectedDimension - 4), 0), false);
      }
    }
  }, [catalogue.handleDimensions, outsideHandleIdLoaded]);

  useEffect(() => {
    if (blackEdition && catalogue.zunanjaDrzalaMap && window.inoAPI) {
      let kljukaId = form.outsideHandle + 'id';
      var item = catalogue.zunanjaDrzalaMap[kljukaId];
      let selectedColor = item.kljuka ? item.kljuka : item[2];
      let outsideHandleCol1ListTop = JSON.parse(window.inoAPI.getSestav().getBarveUtil().getKljukaBarveBlackEdition());
      if (selectedColor.barva) {
        setCatalogue((cat) => ({
          ...cat,
          outsideHandleCol1ListTop: outsideHandleCol1ListTop
        }));
      }
      if (selectedColor.barva2) {
        setCatalogue((cat) => ({
          ...cat,
          outsideHandleCol2ListTop: outsideHandleCol1ListTop
        }));
      }
    }
  }, [form.colorWingOutside, form.colorDekorOutside, form.updateOutsideHandleColorsBlackEdition]);

  //NEED SEPERATE STATE TO ONLY REFRESH USEEFFECT ON FIRST LOAD (+WHEN selectedGroup LOADS)
  if (catalogue.zunDrzala && selectedGroup && outsideHandleIdLoaded === false) {
    setOutsideHandleIdLoaded(true);
  }

  return (
    <>
      <div className="section select-door-handle" id="conf-step-num-7" step="7" ref={ref}>
        <div className="container">
          <h2 className="upper-case">{t('confHandle')}</h2>
          <h3 className="upper-case">{t('confHandleOutside')}</h3>
          {catalogue.zunDrzala && (
            <ul className={'door-handle-list no-bullets align-top '}>
              {(() => {
                const Children =
                  //SKUPINE DRŽAL
                  catalogue.zunDrzala &&
                  catalogue.zunDrzala.map(
                    (item, idx) =>
                      (Object.keys(item.skupine).length !== 0 || Object.keys(item.skupineByDolzina).length !== 0) && (
                        <li key={idx} className={'more-colors__pattern ' + (selectedGroup && selectedGroup.id === item.id && 'more-colors__pattern--active')} onClick={() => selectedZunDrzaloSkupine(item)}>
                          <span className="more-colors__pattern-color inline-as-block color-pattern color-pattern--big">
                            <img src={'./images/' + item.imagePath} alt={item.name} />
                          </span>
                          <span className="more-colors__pattern-txt xSmall inline-as-block">{item.model}</span>
                        </li>
                      )
                  );
                return (
                  <Slider ref={sliderOutsideHandlesRef} {...settings(width)}>
                    {Children}
                  </Slider>
                );
              })()}
            </ul>
          )}

          {selectedGroup && selectedGroup.opis && (
            <>
              <p>
                <strong>{t('confHandleDesc')}</strong>
              </p>
              <ul className="classic-list">
                <li dangerouslySetInnerHTML={{ __html: selectedGroup.opis }}></li>
              </ul>
            </>
          )}
          {dimenzijeShow ? (
            selectedGroup && (
              <>
                <p>
                  <strong>{t('confHandleDim')}</strong>
                </p>
                <ul className={'no-bullets align-top slick-slider-dimensions '}>
                  {(() => {
                    const Children =
                      catalogue.handleDimensions &&
                      catalogue.handleDimensions.map((item, idx) => (
                        <li key={idx} className="more-colors__pattern">
                          <button type="button" className={'square-round square-round-dimensions ' + (selectedKljukaDim && selectedKljukaDim.id === item.id && 'square-round--active')} onClick={() => selectedZunDrzaloDim(item)} style={{ width: '100%', minWidth: '80px', minHeight: '50px' }}>
                            <span className="square-round__text dimenzije">{item.dolzinaLabel}</span>
                          </button>
                        </li>
                      ));
                    return (
                      <Slider ref={sliderDimensionsRef} {...settings(width)}>
                        {Children}
                      </Slider>
                    );
                  })()}
                </ul>
              </>
            )
          ) : (
            <ul className={'door-handle-list no-bullets align-top ' + (catalogue.outisdeHandles && catalogue.outisdeHandles.length <= 5 && 'overflow-buttons--desktop-flex')}>
              {(() => {
                const Children =
                  catalogue.outsideHandleGroups &&
                  catalogue.outsideHandleGroups.map((item, idx) => (
                    <li key={idx} className={'more-colors__pattern ' + (selectedKljukaDim.id === item.id && 'more-colors__pattern--active')} onClick={() => selectedZunDrzaloDim(item)}>
                      <span className="more-colors__pattern-color inline-as-block color-pattern color-pattern--big">
                        <img src={'./images/' + item.previewPath} alt={item.name} />
                      </span>
                      <p className="more-colors__pattern-txt xSmall inline-as-block full-bold">{item.model}</p>
                      <p className="more-colors__pattern-txt-extra square-round__text label-info">{item.infoB2C}</p>
                    </li>
                  ));
                return <Slider {...settings(width)}>{Children}</Slider>;
              })()}
            </ul>
          )}
          {catalogue.outsideHandles && selectedKljuka && selectedKljuka.barvaKljuke && (
            <>
              <p>
                <strong>{t('confColors')}</strong>
              </p>
              <div className="one-color-section">
                <div className="selected-color flex-line">
                  <p className="upper-case">{t('confHandleOutsideMainColor')}</p>
                  <i className="ico ico-delimiter-straight ico-delimiter-straight--longer"></i>
                  <div className="selected-color__color flex-line">
                    <div className="color-pattern selected-color__pattern ">
                      <img src={'./images/' + selectedKljuka.barvaKljuke.slika} alt={selectedKljuka.barvaKljuke.name} style={{ width: '44px', height: '44px' }} />
                    </div>
                    <div className="selected-color__name-of-color">
                      <p className="selected-color__main-name">{selectedKljuka && selectedKljuka.barvaKljuke.name}</p>
                      <p className="selected-color__sub-name small-normal">{selectedKljuka && selectedKljuka.barvaKljuke.opis}</p>
                    </div>
                  </div>
                </div>
                <ul id="top-colors-slider-2" className={'top-colors__slider overflow-buttons no-bullets flex-line ' + (isMobile && 'colors-padding')}>
                  {(() => {
                    const Children = catalogue.outsideHandles.map((item, idx) => (
                      <li key={idx} className={'top-colors__pattern inline-blocks color-pattern ' + (selectedKljuka && selectedKljuka.barvaKljuke.composedId === item.barva.composedId && ' top-colors__pattern--active ')}>
                        <img data-tip data-for={item.barva.composedId + 'kljuka_colors1'} src={'./images/' + item.barva.slika} onClick={() => selectedZunDrzalo(item)} alt={item.image} style={{ width: '44px', height: '44px' }} />
                        {!isMobile && (
                          <ReactTooltip className={'react-tooltip'} overridePosition={overrideTooltipPosition} id={item.barva.composedId + 'kljuka_colors1'} place="bottom" type="dark" effect="solid">
                            <span>{item.barva.name + ' - ' + item.barva.opis}</span>
                          </ReactTooltip>
                        )}
                      </li>
                    ));
                    return Children;
                  })()}
                </ul>
              </div>
            </>
          )}
          {(catalogue.outsideHandleCol1List || catalogue.outsideHandleCol2List) && (
            <p>
              <strong>{t('confHandleOutsideExtraColoring')}</strong>
            </p>
          )}
          {catalogue.outsideHandleCol1List && catalogue.outsideHandleCol1ListTop && barva1 && <Barvanje title={t('confHandleOutsideColor')} topList={catalogue.outsideHandleCol1ListTop} list={catalogue.outsideHandleCol1List} colForm={'outsideHandleCol1'} col={form.outsideHandleCol1 ? form.outsideHandleCol1 : barva1} dimensionGroup={selectedKljukaDim} group={selectedGroup} handleColoring={true} blackEditionKljuke={blackEdition} />}

          {(!blackEdition || (blackEdition && !catalogue.outsideHandleCol1List)) && catalogue.outsideHandleCol2List && catalogue.outsideHandleCol2ListTop && barva2 && <Barvanje title={t('confHandleOutsideAdditionalColor')} topList={catalogue.outsideHandleCol2ListTop} list={catalogue.outsideHandleCol2List} colForm={'outsideHandleCol2'} col={form.outsideHandleCol2 ? form.outsideHandleCol2 : barva2} dimensionGroup={selectedKljukaDim} group={selectedGroup} handleColoring={true} blackEditionKljuke={blackEdition} />}

          {!form.notranjeGarnitureShow && (
            <>
              <h3 className="upper-case">{t('confHandleInside')}</h3>
              <ul className={'door-handle-list no-bullets align-top ' + (catalogue.notrDrzala && catalogue.notrDrzala.length <= 5 && 'overflow-buttons--desktop-flex')}>
                {(() => {
                  const Children =
                    catalogue.notrDrzala &&
                    catalogue.notrDrzala.map((item, idx) => (
                      <li key={idx} className={'more-colors__pattern ' + (form.insideHandle === item.id && 'more-colors__pattern--active')} onClick={() => selectedNotrDrzalo(item)}>
                        <span className="more-colors__pattern-color inline-as-block color-pattern color-pattern--big">
                          <img src={'./images' + item.previewPath} alt={item.name} />
                        </span>
                        <p className="more-colors__pattern-txt xSmall inline-as-block">{item.model}</p>
                        <p className="more-colors__pattern-txt-extra square-round__text label-info" style={isMobile ? { width: '84px' } : {}}>
                          {item.infoB2C}
                        </p>
                      </li>
                    ));
                  return (
                    <Slider ref={sliderInsideHandlesRef} {...settings(width)}>
                      {Children}
                    </Slider>
                  );
                })()}
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
});

export default Drzala;
