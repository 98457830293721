export const conf = {

    filterCount:[
        {
            selectCount: 0,
            exclusiveCount: 0,
            akcijaCount: 0
        }
    ],

    tipModelaList:[
        {
            name: 'classicType',
            label: 'Klasicni',
            selected: false
        },
        {
            name: 'modernType',
            label: 'Moderni',
            selected: false
        },
        {
            name: 'extravagantType',
            label: 'Ekstravagantni',
            selected: false
        },
        {
            name: 'simpleType',
            label: 'Enostavni',
            selected: false
        },
        {
            name: 'topSeller',
            label: 'Najbolj Prodajani',
            directFilter: true,
            selected: false
        }
    ],

    stekloList: [
        {
            name: 'glassNone',
            label: 'Brez',
            selected: false
        },
        {
            name: 'glassSmall',
            label: 'Malo',
            selected: false
        },
        {
            name: 'glassMedium',
            label: 'Srednje',
            selected: false
        },
        {
            name:'glassBig',
            label:'Veliko',
            selected: false
        }
    ],

    oblikaSteklaList: [
        {
            name: 'shapeRectangle',
            label: 'Pravokotniki',
            selected: false
        },
        {
            name: 'shapeSquare',
            label: 'Kvadrati',
            selected: false
        },
        {
            name: 'shapeCurves',
            label: 'Okrogline in Loki',
            selected: false
        },
        {
            name: 'shapeOther',
            label: 'Ostale Oblike',
            selected: false
        }
    ],

    iskanjeList: [
        {
            name: 'searchMulticolor',
            label: 'Večbarvno',
            selected: false
        },
        {
            name: 'searchInox',
            label: 'Inox Aplikacije',
            selected: false
        },
        {
            name: 'searchUtori',
            label: 'Utori',
            selected: false
        }
    ],
    directFiltersList: {all: true, sale: false, top15: false, akcija: false}



}