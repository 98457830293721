import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from 'shared-home/src/home/Context';
import Slider from 'react-slick';
import { settings } from '../common/sliderSettings';
import ReactTooltip from 'react-tooltip';
import { overrideTooltipPosition } from '../../utils/helper';
import cloneDeep from 'lodash/cloneDeep';

export default function Barvanje({ title, colForm, topList, list: hierarhijaBarv, col, colType, colLayer, mainColors, colKey, colorIdFromUrl, changeColor, setColors, dimensionGroup, group, handleColoring, blackEdition = false, blackEditionLimitColors = false, blackEditionKljuke = false }) {
  const { t } = useTranslation();
  const { onComponentChange, form } = useContext(Context);
  const [currentColor, setCurrentColor] = useState(col.barva ? col.barva : col);
  const [barveSeznam, setBarveSeznam] = useState();
  const [allBarveList, setAllBarveList] = useState([]);
  const [searchOpen, setSearchOpen] = useState(false);
  const [showMoreCol, setshowMoreCol] = useState(blackEdition);
  const [baseColor, setBaseColor] = useState(currentColor.name);
  const [strukturna, setStruktruna] = useState();
  const [matt, setMatt] = useState();
  const [sijaj, setSijaj] = useState();
  const [colorSearch, setColorSearch] = useState();
  const sliderRef = useRef();
  const colorSearchRef = useRef();

  const allowedCategoriesNotranjaBlackEdition = [1, 4, 36];

  let barveArray = [];

  useEffect(() => {
    setCurrentColor(col.barva ? col.barva : col);
  }, [col]);

  useEffect(() => {
    if (!handleColoring) return;

    let drzaloBarva1 = JSON.parse(window.inoAPI.getSestav().getZunanjaKljukaBarva1());
    let drzaloBarva2 = JSON.parse(window.inoAPI.getSestav().getZunanjaKljukaBarva2());

    if (colForm === 'outsideHandleCol1' && drzaloBarva1) {
      setCurrentColor(drzaloBarva1);
    } else if (colForm === 'outsideHandleCol2' && drzaloBarva2) {
      setCurrentColor(drzaloBarva2);
    }
  }, [group]);

  //MOBILE CHECK
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768 || height <= 612; // TRUE/FALSE

  const selectedColor = (color, clicked, top10) => {
    if (handleColoring) {
      onComponentChange({
        [colForm]: color
      });
    } else {
      if (mainColors) {
        changeColor({ types: colType, barva: color });
      } else {
        changeColor({ type: colType, barva: color, layer: colLayer });
      }

      setColors(col, clicked);
    }
    //ČE NASTAVIMO BARVO IZ TOP 10 POBRISEMO TRENUTNO IZBRANO BARVNO SKUPINO
    if (top10) {
      setBarveSeznam();
      onComponentChange({
        [colForm + 'TreeId' + colKey]: ''
      });
    }
  };

  //PONASTAVI TRENUTNI IZBOR
  const resetCol = () => {
    setBaseColor();
    setBarveSeznam();
    setshowMoreCol(blackEdition);
    onComponentChange({
      [colForm + 'TreeId' + colKey]: ''
    });

    //ČE RESETAMO BARVO NA NAPREDNEM BARVANJU, ODSTRANIMO ID TRENUTNE BARVE IZ URL-JA
    if (!mainColors) {
      onComponentChange({
        [colForm + 'Id']: ''
      });
    }
    // resetColor(col)

    if (handleColoring) {
      if (colForm === 'outsideHandleCol1') {
        JSON.parse(window.inoAPI.getSestav().getZunanjaKljukaBarva1());
      } else if (colForm === 'outsideHandleCol2') {
        JSON.parse(window.inoAPI.getSestav().getZunanjaKljukaBarva2());
      }
    }
    !handleColoring && setColors(col, false, true);
  };

  const barveList = (item) => {
    onComponentChange({
      [colForm + 'TreeId' + colKey]: item.tree_id
    });
    setBarveSeznam(item.seznam);
    setMatt();
    setStruktruna();
    setSijaj();
  };

  const fletBarve = useMemo(() => {
    let fletBarve = [];
    if (hierarhijaBarv && Object.entries(fletBarve.length === 0)) {
      topList.forEach((color) => {
        var evnosTop = [null, color, null];
        fletBarve[color.composedId.replace(/[^a-zA-Z0-9]/g, '')] = evnosTop;
      });
      for (let i in hierarhijaBarv) {
        var skupina = hierarhijaBarv[i];
        var barveSkupine = skupina.seznam;
        var evnos;
        for (let specificnaBarva in barveSkupine) {
          if (barveSkupine[specificnaBarva].base && barveSkupine[specificnaBarva].base.composedId) {
            evnos = [skupina, barveSkupine[specificnaBarva], 'base'];
            fletBarve[barveSkupine[specificnaBarva].base.composedId.replace(/[^a-zA-Z0-9]/g, '')] = evnos;
          }
          if (barveSkupine[specificnaBarva].strukturna && barveSkupine[specificnaBarva].strukturna.composedId) {
            evnos = [skupina, barveSkupine[specificnaBarva], 'strukturna'];
            fletBarve[barveSkupine[specificnaBarva].strukturna.composedId.replace(/[^a-zA-Z0-9]/g, '')] = evnos;
          }
          if (barveSkupine[specificnaBarva].matt && barveSkupine[specificnaBarva].matt.composedId) {
            evnos = [skupina, barveSkupine[specificnaBarva], 'matt'];
            fletBarve[barveSkupine[specificnaBarva].matt.composedId.replace(/[^a-zA-Z0-9]/g, '')] = evnos;
          }
          if (barveSkupine[specificnaBarva].sijaj && barveSkupine[specificnaBarva].sijaj.composedId && barveSkupine[specificnaBarva].base.composedId !== barveSkupine[specificnaBarva].sijaj.composedId) {
            evnos = [skupina, barveSkupine[specificnaBarva], 'sijaj'];
            fletBarve[barveSkupine[specificnaBarva].sijaj.composedId.replace(/[^a-zA-Z0-9]/g, '')] = evnos;
          }
        }
      }
    }
    return fletBarve;
  }, [hierarhijaBarv]);

  useEffect(() => {
    //ČE DOBIMO NAPREDNO BARVO IZ URL JO FLETNEMO, ZA FLET OSNOVNIH UPORABIMO ID TRENUTNE IZBRANE BARVE
    let colorId;
    if (handleColoring) {
      colorId = form[colForm] && form[colForm].composedId.replace(/[^a-zA-Z0-9]/g, '');
    } else {
      colorId = mainColors ? currentColor.composedId.replace(/[^a-zA-Z0-9]/g, '') : colorIdFromUrl;
    }

    if (fletBarve) {
      var selectedPath = fletBarve[colorId];
      if (selectedPath) {
        var selectedGroup = selectedPath[0];
        var selectedBarva = selectedPath[1]; //==item
        var selectedVariacija = selectedPath[2];
        if (selectedGroup) {
          barveList(selectedGroup);
          tipBarve(selectedBarva, selectedVariacija);
        } else {
          selectedColor(selectedBarva);
        }
      }
    }
  }, [hierarhijaBarv]);

  const tipBarve = (item, selectedVariacija, clicked) => {
    selectedColor(item.base, clicked);
    setBaseColor(item.base);

    for (const [key, value] of Object.entries(item)) {
      if (key === 'strukturna') {
        if (value) {
          barveArray.push(value);
          setStruktruna(value);
          if (selectedVariacija === 'strukturna') {
            selectedColor(value);
          }
        } else setStruktruna();
      }

      if (key === 'matt') {
        if (value) {
          barveArray.push(value);
          setMatt(value);
          if (selectedVariacija === 'matt') {
            selectedColor(value);
          }
        } else setMatt();
      }

      if (key === 'sijaj') {
        if (value) {
          barveArray.push(value);
          setSijaj(value);
          if (selectedVariacija === 'sijaj') {
            selectedColor(value);
          }
        } else setSijaj();
      }
    }
  };

  function toggleSearch() {
    setSearchOpen(!searchOpen || (colorSearch && colorSearch.length > 0));
  }

  function updateColorSearch(search) {
    setColorSearch(search);
    setMatt();
    setStruktruna();
    setSijaj();
    var barveSearchArray = [];
    hierarhijaBarv.forEach((el) => {
      el.seznam.forEach((element) => {
        if (element.base.filterName.toLowerCase().includes(search.toLowerCase())) {
          barveSearchArray.push(element);
        }
      });
    });
    setAllBarveList(barveSearchArray);
  }

  //SCROLL INSTANTLY TO SELECTED COLOR GROUP ON
  useEffect(() => {
    if (sliderRef.current) {
      //RETURNS CURRENT INDEX OF ACTIVE/SELECTED COLOR
      const selectedColorGroupIdx = hierarhijaBarv && hierarhijaBarv.findIndex((item) => form[colForm + 'TreeId' + colKey] === item.tree_id);

      //.slickGoTo(index, dontAnimate)
      //Math.min/max to prevent bug when any of the last 4 colors were chosen - scrolling wouldn't work until pressed multiple times
      sliderRef.current.slickGoTo(Math.max(Math.min(selectedColorGroupIdx, selectedColorGroupIdx - 4), 0), false);
    }
  }, [showMoreCol]);

  useEffect(() => {
    //FOCUS ON INPUT FIELD
    colorSearchRef.current && colorSearchRef.current.focus();
  });

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      toggleSearch();
    }
  };

  return (
    <>
      <div className="one-color-section">
        <div className="selected-color flex-line">
          <p className="upper-case" style={{ whiteSpace: 'nowrap' }}>
            {title}
          </p>
          <i className="ico ico-delimiter-straight ico-delimiter-straight--longer"></i>
          <div className="selected-color__color flex-line">
            <div className="color-pattern selected-color__pattern">
              <img src={currentColor.slika && './images/' + currentColor.slika} alt={currentColor.name} style={{ width: '44px', height: '44px' }} />
            </div>
            <div className="selected-color__name-of-color">
              <p className="selected-color__main-name">{currentColor.name && currentColor.name}</p>
              <p className="selected-color__sub-name small-normal" style={{ overflow: 'auto', textOverflow: 'ellipsis', maxHeight: '28px' }}>
                {currentColor.opis && currentColor.opis}
              </p>
            </div>
          </div>
          {!blackEdition && !blackEditionKljuke && (
            <div className="selected-color__search-color">
              {!searchOpen && <i className="ico ico-search-interface" onClick={() => toggleSearch()}></i>}
              {searchOpen && colorSearch && colorSearch.length > 0 && (
                <i
                  className={'ico ico-close'}
                  style={{
                    position: 'absolute',
                    top: '5px',
                    height: '12px',
                    cursor: 'pointer',
                    opacity: '0.5',
                    zIndex: 999
                  }}
                  onMouseDown={() => updateColorSearch('')}
                  onTouchStart={() => updateColorSearch('')}></i>
              )}
              <input id="search-color-2" name="search-color-2" type="search" placeholder={t('confFindColor')} className={searchOpen ? 'smaller-search' : 'hidden smaller-search'} value={colorSearch} onChange={(e) => updateColorSearch(e.target.value)} onBlur={() => toggleSearch()} ref={colorSearchRef} onKeyPress={(e) => onEnter(e)} />
            </div>
          )}
        </div>
        {!colorSearch ? (
          <>
            {!showMoreCol ? (
              <div className={!colorSearch ? 'top-colors ' : 'top-colors hidden'}>
                {!blackEditionKljuke && <p className="top-colors__title">{t('confColorTop')}</p>}
                <ul id="top-colors-slider-2" className={'top-colors__slider overflow-buttons no-bullets flex-line ' + (isMobile && 'colors-padding')}>
                  {topList &&
                    topList.map((item) => {
                      return (
                        <li key={item.composedId} className={'top-colors__pattern inline-blocks color-pattern ' + (currentColor.name === item.name && 'top-colors__pattern--active')}>
                          <img data-tip data-for={item.composedId + 'top-colors-slider-2'} src={'./images/' + item.slika} onClick={() => selectedColor(item, true, true)} alt={item.name} style={{ width: '44px', height: '44px' }} />

                          {!isMobile && (
                            <ReactTooltip className={'react-tooltip'} overridePosition={overrideTooltipPosition} id={item.composedId + 'top-colors-slider-2'} type="dark" place={'bottom'} effect="solid">
                              <span>{item.name + ' - ' + item.opis}</span>
                            </ReactTooltip>
                          )}
                        </li>
                      );
                    })}
                </ul>
                {!showMoreCol && (
                  <p className="flex-line">
                    {!blackEditionKljuke && (
                      <button className="orange-link open-more-colors" onClick={() => setshowMoreCol(!showMoreCol)}>
                        {t('confMoreColors')}
                      </button>
                    )}
                    {!handleColoring && (
                      <button onClick={() => resetCol()} className="gray-link" style={{ paddingLeft: '15px' }}>
                        {t('confColReset')}
                      </button>
                    )}
                  </p>
                )}
              </div>
            ) : (
              // <div className={"more-colors" + (showMoreCol || colorForm.treeId === undefined || !colorSearch ? '' : ' hidden')}>
              <div className="more-colors">
                <p className="more-colors__title">{t('confColorGroup')}</p>
                <ul id="more-colors-slider-3" className={'top-colors__slider overflow-buttons no-bullets flex-line align-top ' + (hierarhijaBarv && hierarhijaBarv.length <= 5 && ' overflow-buttons--desktop-flex ') + (isMobile && ' colors-padding ')}>
                  {(() => {
                    const Children =
                      hierarhijaBarv &&
                      hierarhijaBarv
                        .filter((b) => !blackEditionLimitColors || (blackEditionLimitColors && allowedCategoriesNotranjaBlackEdition.includes(b.tree_id)))
                        .map((item) => (
                          <li key={item.composedId} className={'more-colors__pattern more-colors-coloring__pattern ' + (form[colForm + 'TreeId' + colKey] === item.tree_id && 'more-colors__pattern--active ')}>
                            {/* INLINE STYLING WITH FIXED HEIGHT BECAUSE OF OUTSIDE HANDLES COLOR GROUPS */}
                            <span className="more-colors__pattern-color inline-as-block color-pattern color-pattern--big " style={isMobile ? { height: '46px' } : { height: '58px' }}>
                              <img src={'./images/' + item.seznam[0].base.slika} onClick={() => barveList(item)} alt={item.title} style={isMobile ? { minWidth: '78px', minHeight: '40px' } : { width: '100px', height: '58px' }} />
                            </span>
                            <span className="more-colors__pattern-txt xSmall inline-as-block" style={{ padding: '0 8px' }}>
                              {item.title}
                            </span>
                          </li>
                        ));
                    if (isMobile || (!isMobile && hierarhijaBarv && hierarhijaBarv.length <= 5)) {
                      return Children;
                    } else {
                      return (
                        <Slider ref={sliderRef} {...settings(width)}>
                          {Children}
                        </Slider>
                      );
                    }
                  })()}
                </ul>
                {barveSeznam && (
                  <>
                    <p className="more-colors__title small-normal">
                      {t('confColorShade')} <strong>{currentColor.name && currentColor.opis && `${currentColor.name + ' ' + currentColor.opis}`}</strong>
                    </p>
                    <ul id="more-colors-slider-4" className={'top-colors__slider overflow-buttons no-bullets flex-line ' + (isMobile && 'colors-padding')}>
                      {barveSeznam &&
                        barveSeznam.map((item) => (
                          <li key={item.base.composedId} className={'top-colors__pattern inline-blocks color-pattern ' + (baseColor && baseColor.name === item.base.name && 'top-colors__pattern--active')}>
                            <img data-tip data-for={item.base.composedId + 'top-colors-slider-4'} src={'./images/' + item.base.slika} onClick={() => tipBarve(item, undefined, true)} alt={item.name} style={{ width: '44px', height: '44px' }} />
                            {!isMobile && (
                              <ReactTooltip className={'react-tooltip'} overridePosition={overrideTooltipPosition} id={item.base.composedId + 'top-colors-slider-4'} place="bottom" type="dark" effect="solid">
                                <span>{item.base.name + ' - ' + item.base.opis}</span>
                              </ReactTooltip>
                            )}
                          </li>
                        ))}
                    </ul>
                    <ul className="more-colors__type buttons-in-line no-bullets flex-line">
                      {strukturna && (
                        <li className={'square-round ' + (currentColor.name === strukturna.name && 'square-round--active')} onClick={() => selectedColor(strukturna, true)}>
                          <span className="square-round__text">{t('confColSt')}</span>
                        </li>
                      )}
                      {matt && (
                        <li className={'square-round ' + (currentColor.name === matt.name && 'square-round--active')} onClick={() => selectedColor(matt, true)}>
                          <span className="square-round__text">{t('confColMa')}</span>
                        </li>
                      )}
                      {sijaj && (
                        <li className={'square-round ' + (currentColor.name === sijaj.name && 'square-round--active')} onClick={() => selectedColor(sijaj, true)}>
                          <span className="square-round__text">{t('confColShine')}</span>
                        </li>
                      )}
                    </ul>
                  </>
                )}
                <p className="flex-line">
                  {!blackEdition && (
                    <button onClick={() => setshowMoreCol(false)} className="gray-link close-more-colors">
                      {t('confColLess')}
                    </button>
                  )}
                  {!handleColoring && (
                    <>
                      {!blackEdition && <span className="ico-delimiter-straight ico-delimiter-straight--small"></span>}
                      <button onClick={() => resetCol()} className="gray-link">
                        {t('confColReset')}
                      </button>
                    </>
                  )}
                </p>
              </div>
            )}
          </>
        ) : (
          <>
            <p className="more-colors__title small-normal">
              {t('confColorShade')} <strong>{`${currentColor.name + ' ' + currentColor.opis}`}</strong>
            </p>
            <ul id="more-colors-slider-4" className={'top-colors__slider overflow-buttons no-bullets flex-line ' + (isMobile && 'colors-padding')}>
              {allBarveList &&
                allBarveList.map((item) => (
                  <li key={item.base.name} className={'top-colors__pattern inline-blocks color-pattern ' + (currentColor.name === item.base.name && ' top-colors__pattern--active')}>
                    <img src={'./images/' + item.base.slika} onClick={() => tipBarve(item, true)} alt={item.name} style={{ width: '44px', height: '44px' }} />
                  </li>
                ))}
            </ul>
            <ul className="more-colors__type buttons-in-line no-bullets flex-line">
              {strukturna && (
                <li className={'square-round ' + (currentColor.name === strukturna.name && 'square-round--active')} onClick={() => selectedColor(strukturna, true)}>
                  <span className="square-round__text">{t('confColSt')}</span>
                </li>
              )}
              {matt && (
                <li className={'square-round ' + (currentColor.name === matt.name && 'square-round--active')} onClick={() => selectedColor(matt, true)}>
                  <span className="square-round__text">{t('confColMa')}</span>
                </li>
              )}
              {sijaj && (
                <li className={'square-round ' + (currentColor.name === sijaj.name && 'square-round--active')} onClick={() => selectedColor(sijaj, true)}>
                  <span className="square-round__text">{t('confColShine')}</span>
                </li>
              )}
            </ul>
          </>
        )}
      </div>
      {/* {mainColors && form.colorsModel.aggregatedBarva.length -1  !== colKey && */}
      <div className="ico-delimiter-line"></div>
      {/* } */}
    </>
  );
}
