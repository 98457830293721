import React, { useContext, useEffect, useState } from 'react';
import { Context } from 'shared-home/src/home/Context';
import { useTranslation } from 'react-i18next';

const Dimenzije = React.forwardRef((props, ref) => {
  const { form, onComponentChange } = useContext(Context);

  const { t } = useTranslation();

  const [visina, setVisina] = useState(undefined);
  const [sirina, setSirina] = useState(undefined);

  //MOBILE CHECK
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768 || height <= 612; // TRUE/FALSE

  //POBRIŠE QUERY VISINA/SIRINA OB MENJAVI TIPA KONSTRUKCIJE (TODO: TUDI ČE IZBEREŠ ISTO KONSTRUKCIJO)
  //ČE ZAMENJAŠ ODPIRANJE VRAT PA OSTANEJO QUERYJI ENAKI
  useEffect(() => {
    setSirina('');
    setVisina('');
  }, [form.dimensionQueryReset]);

  //NASTAVI QUERY V STATE VISINA/SIRINA
  const onChange = (e) => {
    if (e.target.name === 'dimensionHeight') {
      setVisina(e.target.value);
    }

    if (e.target.name === 'dimensionWidth') {
      setSirina(e.target.value);
    }
  };

  // ONBLUR ZAPIŠE VISINO/SIRINO V FORMO
  const updateHeightInput = () => {
    if (visina) {
      onComponentChange({ dimensionHeight: Number(visina) });
    } else {
      onComponentChange({ dimensionHeight: form.dimensionHeightDefault });
    }
  };

  const updateWidthInput = () => {
    if (sirina) {
      onComponentChange({ dimensionWidth: Number(sirina) });
    } else {
      onComponentChange({ dimensionWidth: form.dimensionWidthDefault });
    }
  };

  useEffect(() => {
    //KO PRIDEJO DIMENZIJE IZ URL-JA SE NASTAVIJO V QUERY VISINA/SIRINA
    if (form.dimensionHeight !== form.dimensionHeightDefault && form.dimensionHeightDefault !== undefined && !visina) {
      setVisina(form.dimensionHeight);
    }
    if (form.dimensionWidth !== form.dimensionWidthDefault && form.dimensionWidthDefault !== undefined && !sirina) {
      setSirina(form.dimensionWidth);
    }
  }, [form.dimensionHeight, form.dimensionWidth, form.dimensionHeightDefault, form.dimensionWidthDefault]);

  const onEnter = (e) => {
    if (e.target.name === 'dimensionHeight') {
      if (e.key === 'Enter') {
        updateHeightInput();

        if (isMobile) {
          e.target.blur();
        }
      }
    }

    if (e.target.name === 'dimensionWidth') {
      if (e.key === 'Enter') {
        updateWidthInput();

        if (isMobile) {
          e.target.blur();
        }
      }
    }
  };

  //CALCULATE PLACEHOLDER AND ADJUST WIDTH
  // if (form.dimensionHeightDefault && form.dimensionWidthDefault) {
  //     const inputWidth = document.getElementById("dimension-width");
  //     const inputHeight = document.getElementById("dimension-height");

  //     if (inputWidth) {
  //         let widthPlaceholderLength = inputWidth.placeholder.length + 5 + 'ch';
  //         let heightPlaceholderLength = inputHeight.placeholder.length + 5 + 'ch';
  //         let widthDimensionChanged = ((widthPlaceholderLength !== (inputWidth.style.width)) || inputWidth.style.width === undefined)
  //         let heightDimensionChanged = ((heightPlaceholderLength !== (inputHeight.style.width)) || inputHeight.style.width === undefined)

  //         if (widthDimensionChanged) {
  //             inputWidth.style.width = widthPlaceholderLength;
  //         }
  //         if (heightDimensionChanged) {
  //             inputHeight.style.width = heightPlaceholderLength;
  //         }
  //     }
  // }

  const warningCheck = () => {
    if (form.widthDimensionWarning) {
      return form.widthDimensionWarning;
    } else if (form.heightDimensionWarning) {
      return form.heightDimensionWarning;
    } else return false;
  };

  return (
    <div className="section select-dimensions" id="conf-step-num-4" step="4" ref={ref}>
      <div className="container">
        <h2 className="upper-case">{t('confDimensions')}</h2>
        <ul className="no-bullets flex-line">
          <li>
            <label htmlFor="dimension-width" className="label-up small upper-case">
              {t('confWidth')}
            </label>
            <input type="text" pattern="\d*" maxLength="5" onChange={onChange} id="dimension-width" className="square-round square-round--input-small" placeholder={t('confExample') + ' ' + form.dimensionWidthDefault + ' mm'} name="dimensionWidth" onBlur={updateWidthInput} onKeyPress={(e) => onEnter(e)} value={sirina} style={{ width: '145px', fontSize: '15px' }} />
          </li>
          <li>
            <label htmlFor="dimension-height" className="label-up small upper-case">
              {t('confHeight')}
            </label>
            {/* TODO NO DECIMALS ALLOWED */}
            <input type="text" pattern="\d*" maxLength="5" onChange={onChange} id="dimension-height" className="square-round square-round--input-small" placeholder={t('confExample') + ' ' + form.dimensionHeightDefault + ' mm'} name="dimensionHeight" onBlur={updateHeightInput} onKeyPress={(e) => onEnter(e)} value={visina} style={{ width: '145px', fontSize: '15px' }} />
          </li>
        </ul>
        <p className="notice" dangerouslySetInnerHTML={{ __html: warningCheck() ? warningCheck() : t('confSkipDimensions') }}></p>
      </div>
    </div>
  );
});

export default Dimenzije;
