import React, { useContext } from 'react';
import { Context } from 'shared-home/src/home/Context';
import { useTranslation } from 'react-i18next';

const OdpiranjeVrat = React.forwardRef((props, ref) => {
  const { form, onComponentChange } = useContext(Context);

  const { t } = useTranslation();

  const onSideChange = (e) => {
    if (e.target.name === 'leftSide' && form.doorOpeningLeftSide !== true) {
      onComponentChange({ doorOpeningLeftSide: true, doorOpeningRightSide: false });
    }

    if (e.target.name === 'rightSide' && form.doorOpeningRightSide !== true) {
      onComponentChange({ doorOpeningRightSide: true, doorOpeningLeftSide: false });
    }
  };

  return (
    <>
      <div className="section select-opening-door" id="conf-step-num-2" step="2" ref={ref}>
        <div className="container">
          <h2 id="odpiranje" className="upper-case">
            {t('confDoorOpening')}
          </h2>
          <ul className="buttons-in-line no-bullets flex-line">
            <li>
              <button type="button" name="leftSide" className={'square-round square-round--min-width ' + (form.doorOpeningLeftSide === true && 'square-round--active')} onClick={onSideChange}>
                {t('confLeft')}
              </button>
            </li>
            <li>
              <button type="button" name="rightSide" className={'square-round square-round--min-width ' + (form.doorOpeningRightSide === true && 'square-round--active')} onClick={onSideChange}>
                {t('confRight')}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
});

export default OdpiranjeVrat;
