import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import { Context } from 'shared-home/src/home/Context';

const Header = (props) => {
  const { t } = useTranslation();
  const { form, onComponentChange } = useContext(Context);

  const [showMenu, setShowMenu] = useState(false);
  const [isSelected, setSelected] = useState('1');
  const [mobileScrollHeight, setMobileScrollHeight] = useState();

  //MOBILE CHECK
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width < 782 || height <= 612; // TRUE/FALSE

  useEffect(() => {
    jQueryHeader();
  }, []);

  window.$ = window.jQuery = require('jquery');

  const jQueryHeader = () => {
    const windowWidth = $(window).width();
    const windowHeight = $(window).height();

    $(document).ready(function () {
      if ($('.filters-selection').length) {
        if (windowWidth >= 782 && windowWidth > windowHeight) {
          //UPDATE HEADER SECTION NUMBERS/STEP WHILE SCROLLING
          $('.filters-selection').scroll(function () {
            $('.filters-selection .section').each(function () {
              var ConfStep = $(this).offset().top - $(window).scrollTop();
              if (ConfStep >= 75 && ConfStep <= 100) {
                var currentStep = $(this).attr('step');
                if ($('.configurator-steps .steps .step-number.current').attr('step') !== currentStep) {
                  $('.configurator-steps .steps .step-number.current').removeClass('current');
                  var scrollCurrentStep = '#current-step-number-' + currentStep;
                  $('.configurator-steps .steps .step-number' + scrollCurrentStep).addClass('current');
                  setSelected(currentStep); // SCROLL ANCHOR
                }
              }
            });
          });
        } else {
          const header = $('.header');
          const headerHeight = header.outerHeight();

          const configurator = $('.show-door');
          const configuratorHeight = configurator.outerHeight();

          const filters = $('.filters-selection');
          const filtersFromTop = headerHeight + configuratorHeight;
          setMobileScrollHeight(filtersFromTop);

          configurator.css('top', headerHeight);

          filters.css({
            'margin-top': filtersFromTop
          });

          //UPDATE HEADER SECTION NUMBERS/STEP WHILE SCROLLING
          $(window).scroll(function () {
            $('.filters-selection .section').each(function () {
              var ConfStep = $(this).offset().top - $(window).scrollTop() - filtersFromTop;
              if ($(window).scrollTop() === 0) {
                setSelected(1);
              }
              if (ConfStep >= 75 && ConfStep <= 100) {
                var currentStep = $(this).attr('step');
                if ($('.configurator-steps .steps .step-number.current').attr('step') !== currentStep) {
                  $('.configurator-steps .steps .step-number.current').removeClass('current');
                  var scrollCurrentStep = '#current-step-number-' + currentStep;
                  $('.configurator-steps .steps .step-number' + scrollCurrentStep).addClass('current');
                  setSelected(currentStep); // SCROLL ANCHOR
                }
              }
            });
          });
        }
      }
    });
  };

  const switchTitle = () => {
    switch (isSelected) {
      case '1':
        return t('headerModel');
      case '2':
        return t('confDoorOpening');
      case '3':
        return t('confConstructionType');
      case '4':
        return t('confDimensions');
      case '5':
        return t('confColors');
      case '6':
        return t('confGlass');
      case '7':
        return t('confHandle');
      case '8':
        return t('confLock');
      case '9':
        return t('confExtras');
      default:
        return t('headerModel');
    }
  };

  var headerPager = [];
  var stepCounter = '1';
  for (var i = 0; i < 9; i++) {
    var elemId = 'conf-step-num-' + stepCounter;
    var elem = document.getElementById(elemId);
    if (elem !== null) {
      headerPager.push(elem);
    }
    stepCounter++;
  }

  let currentStepNumber = headerPager.findIndex(function (item) {
    return item.getAttribute('step') === isSelected;
  });

  if (currentStepNumber < 0) currentStepNumber = 0;

  const arrowNextStep = () => {
    if (headerPager.length > currentStepNumber + 1) {
      if (isMobile) {
        currentStepNumber++;
        let selectedMenuComp = headerPager[currentStepNumber];
        selectedMenuComp.scrollIntoView(true);
        window.scrollBy(0, -mobileScrollHeight);
        setSelected(selectedMenuComp.getAttribute('step'));
      } else {
        currentStepNumber++;
        let selectedMenuComp = headerPager[currentStepNumber];
        selectedMenuComp.scrollIntoView({ behavior: 'smooth' });
        // setSelected(selectedMenuComp.getAttribute("step"));
      }
    }
  };

  const arrowPreviousStep = () => {
    if (currentStepNumber >= 1) {
      if (isMobile) {
        currentStepNumber--;
        let selectedMenuComp = headerPager[currentStepNumber];
        selectedMenuComp.scrollIntoView(true);
        window.scrollBy(0, -mobileScrollHeight);
        setSelected(selectedMenuComp.getAttribute('step'));
      } else {
        currentStepNumber--;
        let selectedMenuComp = headerPager[currentStepNumber];
        selectedMenuComp.scrollIntoView({ behavior: 'smooth' });
        // setSelected(selectedMenuComp.getAttribute("step"));
      }
    }
  };

  //SPISEK KONFIGURACIJE PRILAGODI REDIRECT
  useEffect(() => {
    if (form.prilagodiScroll !== undefined) {
      props.menuRefs[form.prilagodiScroll].current.scrollIntoView(true);
      setSelected(props.menuRefs[form.prilagodiScroll].current.id.slice(-1));
      // onComponentChange({ prilagodiScroll: undefined });
    }
  }, [form.prilagodiScroll]);

  //SCOLL USING HAMBURGER MENU
  const scrollToComponent = (step) => {
    if (isMobile) {
      props.menuRefs[step].current.scrollIntoView(true);
      window.scrollBy(0, -mobileScrollHeight);
      setShowMenu(false);
      setSelected(props.menuRefs[step].current.id.slice(-1));
    } else {
      props.menuRefs[step].current.scrollIntoView({ behavior: 'smooth' });
      setShowMenu(false);
      // setSelected(props.menuRefs[step].current.id)
    }
  };

  const onLinkShare = () => {
    if (form.configuratorURL) {
      if (window.location.protocol === 'https:') {
        // SHOWS INSTALLED APPS WHERE LINK CAN BE SHARED ON MOBILE; ELSE COPIES LINK TO CLIPBOARD
        if (navigator.share) {
          navigator
            .share({
              url: form.configuratorURL
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing:', error));
        } else {
          navigator.clipboard.writeText(form.configuratorURL);
          window.alert('URL Copied to clipboard');
        }
      } else {
        window.alert('Copy link in the URL');
      }
    }
  };

  const goHomePage = () => {
    const getLink = window.inoAPI.getHomePageLink();

    if (getLink === null) {
      window.location.replace(`${t('logoLink')}`);
    } else {
      window.location.replace(`${getLink}`);
    }
  };

  return (
    <>
      <header className="header fixed-position">
        <div className="header__main flex-line space-between desktop-space-between">
          <div className="logo cursor-pointer" onClick={goHomePage}>
            <div className="logo__image"></div>
          </div>
          <div className="configurator-steps flex-line">
            <div className="steps flex-line">
              <i className={'ico ico-arrow-left ' + (currentStepNumber < 1 && 'ico-arrow-left--disabled')} onClick={arrowPreviousStep}>
                <svg name="arrowLeft" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 1L1 6L6 11" stroke="black" />
                </svg>
              </i>
              <span>{currentStepNumber + 1}</span>
              <span className="delimiter">/</span>
              <span className="max-steps">{headerPager.length}</span>
              <i className={'ico ico-arrow-right ' + (headerPager.length <= currentStepNumber + 1 && 'ico-arrow-left--disabled')} onClick={arrowNextStep}>
                <svg name="arrowRight" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6 6L1 11" stroke="black" />
                </svg>
              </i>
              <h3 className="upper-case mobile-hide steps__name-of-section">{switchTitle()}</h3>
            </div>
            <i className="ico ico-delimiter-straight"></i>
            <div
              className="hamburger-menu cursor-pointer"
              onClick={() => {
                setShowMenu(true);
                onComponentChange({ configuratorURL: window.location.href });
              }}>
              <i className="ico ico-hamburger">
                <span className="ico-hamburger__mid"></span>
              </i>
            </div>
          </div>
        </div>
        {showMenu && (
          <div className="mobile-header-filters-hamburgerMenu mobile-header-filters--desktop">
            <div className="mobile-header-filters__top">
              <div className="mobile-header-filters__section mobile-header-filters__section--header flex-line justify-end">
                <button className="close-configurator-menu close-configurator-menu-text" style={{ marginRight: '16px' }}>
                  <h3 className="upper-case">
                    <strong onClick={() => setShowMenu(false)}>{t('close')}</strong>
                  </h3>
                </button>
                <button className="close-configurator-menu close-configurator-menu-icon cursor-pointer">
                  <i className="ico ico-close" onClick={() => setShowMenu(false)}></i>
                </button>
              </div>
              <div className="mobile-header-configurator-steps">
                <h2 className="mobile-header-configurator-steps__title upper-case orange-font">Konfigurator</h2>
                <ul className="mobile-header-configurator-steps__menu no-bullets">
                  <li className={'mobile-header-configurator-steps__item ' + ((isSelected === 'conf-step-num-1' || isSelected === '1' || isSelected === 1) && 'mobile-header-configurator-steps__item--active')} step="1">
                    <button className="light-black-font" onClick={() => scrollToComponent(0)}>
                      <h3 className="upper-case cursor-pointer">
                        <strong> {t('headerModel')} </strong>
                      </h3>
                    </button>
                  </li>
                  <li className={'mobile-header-configurator-steps__item ' + ((isSelected === 'conf-step-num-2' || isSelected === '2') && 'mobile-header-configurator-steps__item--active')} step="2">
                    <button className="light-black-font" onClick={() => scrollToComponent(1)}>
                      <h3 className="upper-case cursor-pointer">
                        <strong>{t('confDoorOpening')}</strong>
                      </h3>
                    </button>
                  </li>
                  <li className={'mobile-header-configurator-steps__item ' + ((isSelected === 'conf-step-num-3' || isSelected === '3') && 'mobile-header-configurator-steps__item--active')} step="3">
                    <button className="light-black-font" onClick={() => scrollToComponent(2)}>
                      <h3 className="upper-case cursor-pointer">
                        <strong>{t('confConstructionType')}</strong>
                      </h3>
                    </button>
                  </li>
                  <li className={'mobile-header-configurator-steps__item ' + ((isSelected === 'conf-step-num-4' || isSelected === '4') && 'mobile-header-configurator-steps__item--active')} step="4">
                    <button className="light-black-font" onClick={() => scrollToComponent(3)}>
                      <h3 className="upper-case cursor-pointer">
                        <strong>{t('confDimensions')}</strong>
                      </h3>
                    </button>
                  </li>
                  <li className={'mobile-header-configurator-steps__item ' + ((isSelected === 'conf-step-num-5' || isSelected === '5') && 'mobile-header-configurator-steps__item--active')} step="5">
                    <button className="light-black-font" onClick={() => scrollToComponent(4)}>
                      <h3 className="upper-case cursor-pointer">
                        <strong>{t('confColors')}</strong>
                      </h3>
                    </button>
                  </li>
                  {form.doorHasGlass ? (
                    <li className={'mobile-header-configurator-steps__item ' + ((isSelected === 'conf-step-num-6' || isSelected === '6') && 'mobile-header-configurator-steps__item--active')} step="6">
                      <button className="light-black-font" onClick={() => scrollToComponent(5)}>
                        <h3 className="upper-case cursor-pointer">
                          <strong>{t('confGlass')}</strong>
                        </h3>
                      </button>
                    </li>
                  ) : (
                    <li className="mobile-header-configurator-steps__item">
                      <h3 className="upper-case">
                        <strong style={{ opacity: 0.2 }}>{t('confGlass')}</strong>
                      </h3>
                    </li>
                  )}
                  <li className={'mobile-header-configurator-steps__item ' + ((isSelected === 'conf-step-num-7' || isSelected === '7') && 'mobile-header-configurator-steps__item--active')} step="7">
                    <button className="light-black-font" onClick={() => scrollToComponent(6)}>
                      <h3 className="upper-case cursor-pointer">
                        <strong>{t('confHandle')}</strong>
                      </h3>
                    </button>
                  </li>
                  <li className={'mobile-header-configurator-steps__item ' + ((isSelected === 'conf-step-num-8' || isSelected === '8') && 'mobile-header-configurator-steps__item--active')} step="8">
                    <button className="light-black-font" onClick={() => scrollToComponent(7)}>
                      <h3 className="upper-case cursor-pointer">
                        <strong>{t('confLock')}</strong>
                      </h3>
                    </button>
                  </li>
                  <li className={'mobile-header-configurator-steps__item ' + ((isSelected === 'conf-step-num-9' || isSelected === '9') && 'mobile-header-configurator-steps__item--active')} step="9">
                    <button className="light-black-font" onClick={() => scrollToComponent(8)}>
                      <h3 className="upper-case cursor-pointer">
                        <strong>{t('confExtras')}</strong>
                      </h3>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="mobile-header-configurator-selection desktop-hide">
                <div className="bg-white-gray flex-line justify-center">
                  {/* <button onClick={() => navigate('/inquiryConfigurator', { replace: true })} className="flex review-order-button cursor-pointer">
                                        <i className="ico ico-pdf-file-black review-actions__icon"></i>
                                        <span className="upper-case small light-black-font word-wrap">{t('confSummary')}</span>
                                    </button> */}
                  <button onClick={onLinkShare} className="flex review-order-button cursor-pointer">
                    <i className="ico ico-save-return-black review-actions__icon"></i>
                    <span className="upper-case small light-black-font">{t('confContinue')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
