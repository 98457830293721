import React, { useState, useContext, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { Context } from 'shared-home/src/home/Context';
import { useTranslation } from 'react-i18next';
import { settings } from '../common/sliderSettings';

const Zaklepanje = React.forwardRef((props, ref) => {
  const { form, onComponentChange, catalogue } = useContext(Context);
  const { t } = useTranslation();
  const sliderLocksRef = useRef();

  //MOBILE CHECK
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width < 782 || height <= 612; // TRUE/FALSE

  const selectedDoorLock = (item) => {
    if (form.lockingType !== item.id) {
      onComponentChange({
        lockingType: item.id
      });
    }
  };

  useEffect(() => {
    let kljucavnicaObstaja = catalogue.zaklepanja && catalogue.zaklepanja.find((e) => e.id === form.lockingType);

    if (!kljucavnicaObstaja && window.inoAPI) {
      let kljucavnicaCurrent = JSON.parse(window.inoAPI.getSestav().getKljucavnica());
      onComponentChange({
        lockingType: kljucavnicaCurrent.id
      });
    }
  }, [catalogue.zaklepanja]);

  //SCROLL INSTANTLY TO SELECTED LOCK ON RELOAD
  useEffect(() => {
    if (sliderLocksRef.current) {
      const selectedLockIdx = catalogue.zaklepanja && catalogue.zaklepanja.findIndex((item) => form.lockingType === item.id);
      sliderLocksRef.current.slickGoTo(Math.max(Math.min(selectedLockIdx, selectedLockIdx - 4), 0), false);
    }
  }, [catalogue.zaklepanja]);

  return (
    <>
      <div className="section select-lock" id="conf-step-num-8" step="8" ref={ref}>
        <div className="container">
          <h2 className="upper-case">{t('confLock')}</h2>
          <div className="popular-lock-systems">
            <ul className={'locks-list no-bullets align-top'}>
              {(() => {
                const Children =
                  catalogue.zaklepanja &&
                  catalogue.zaklepanja.map((item, idx) => (
                    <li key={idx} className={'more-colors__pattern ' + (form.lockingType === item.id && 'more-colors__pattern--active')} onClick={() => selectedDoorLock(item)}>
                      <span className={'more-colors__pattern-color inline-as-block color-pattern color-pattern--big'}>
                        <img src={'./images/' + item.previewPath} alt={item.model} />
                      </span>
                      <p className="more-colors__pattern-txt xSmall inline-as-block">{item.model}</p>
                      <p className="more-colors__pattern-txt-extra square-round__text label-info" style={isMobile ? { width: '84px' } : {}}>
                        {item.infoB2C}
                      </p>
                    </li>
                  ));
                return (
                  <Slider ref={sliderLocksRef} {...settings(width)}>
                    {Children}
                  </Slider>
                );
              })()}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
});

export default Zaklepanje;
