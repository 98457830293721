import React from 'react';
import { CustomArrow } from './arrows';

export const settings = (clientWidth = 0) => {
  let slidesToShow = 5;
  let showArrow = true;
  if (clientWidth < 782) {
    slidesToShow = 3;
    showArrow = false;
  } else if (clientWidth >= 782 && clientWidth < 1000) {
    slidesToShow = 3;
  } else if (clientWidth >= 1000 && clientWidth < 1240) {
    slidesToShow = 4;
  }
  return {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: <CustomArrow visible={showArrow} />,
    prevArrow: <CustomArrow visible={showArrow} />,
    variableWidth: true
  };
};
