const homeParams = (form) => {
  const parseAlwaysParam = (param) => {
    let arrSelected = [];

    if (param === "directFilters" && form[param]) {
      const p = form[param];
      return [p.all ? 1 : 0, p.sale ? 1 : 0, p.top15 ? 1 : 0, p.akcija ? 1 : 0];
    } else if (form[param]) {
      for (const [key, value] of Object.entries(form[param])) {
        if (value.selected === true) {
          arrSelected = [...arrSelected, value.name];
        } else {
          arrSelected = [...arrSelected, 0];
        }
      }
      return arrSelected;
    }
  };

  return parseAlwaysParam("izberiModel") + "/" + parseAlwaysParam("tipModela") + "/" + parseAlwaysParam("steklo") + "/" + parseAlwaysParam("oblikaStekla") + "/" + parseAlwaysParam("iskanje") + "/" + parseAlwaysParam("directFilters");
};

export default homeParams;
