import React, {useContext, useEffect, useState} from "react";
import {Context} from "./Context.jsx";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import i18n from "i18next";

export default function List({doors, selectedModel, setSelectedModel, configPath, preloadSestav, showPrice}) {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const navigate = useNavigate();

  const [selectedDoor, setSelectedDoor] = useState(0);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  
  const isMobile = width <= 768; // TRUE/FALSE

  const {t} = useTranslation();
  const {form, onComponentChange, initializeInoAPI} = useContext(Context);

  const onDoorSelect = (door, direct) => {
    if (direct) {
      setSelectedDoor(door.id.idInoKalk);
    }

    function getModelType(data) {
		const filterState= data.resultFilterState;
		const tipModela = filterState.tipModela;		
		
      if (tipModela.length > 1) {
        return tipModela;
      } else {
        return tipModela[0];
      }
    }

    if (direct) {	     
      if (preloadSestav) {
        initializeInoAPI(door.id.idInoKalk, door.desc, () => navigate(configPath))
      } else {
        navigate(configPath + door.id.idInoKalk);
      }
    } else {
      onComponentChange({
        modelName: door.desc,
        modelImg: door.imageUrl,
        modelId: door.id.idInoKalk,
        modelProgram: door.id.program,
        modelType: getModelType(door),
        modelGlassAmount: door.resultFilterState.kolicinaStekla[0],
      });
    }
  };

  const openingDirectionOutsideCookie = document.cookie.replace(/(?:(?:^|.*;\s*)openingDirectionOutside\s*\=\s*([^;]*).*$)|^.*$/, "$1");
  const openingDirectionOutside = openingDirectionOutsideCookie && openingDirectionOutsideCookie === "true";
  const numberFormat = new Intl.NumberFormat(i18n.language, {minimumFractionDigits: 2, maximumFractionDigits: 2});
  return (
    <>
      {doors &&
        doors.map((door, index) => (
          <div key={index} className={"grid-doors__single " + (selectedDoor && selectedDoor === door.id.idInoKalk ? "selected " : " ") + (openingDirectionOutside && !door.mozenNavzven ? "disabled " : " " )} name="modelName" onClick={() => {
            if (openingDirectionOutside && !door.mozenNavzven) {
              return;
            }else {
              onDoorSelect(door, !isMobile);
            }
          }} value={door.id.modelName}>
            <button key={index} className={"home-button grid-doors__select " + (door.id.modelName === form.modelName && isMobile && " grid-doors__select--active")}>
              <img loading="lazy" className="door-img" src={"./images/" + door.imageUrl} alt="vrata1" style={{objectFit: "cover"}}/>
              <span className="grid-doors__name-of-the-door small white-font upper-case">

                  {door.desc}
                {showPrice && door.id.cena ? (
                  <div><br/>
                    {numberFormat.format(door.id.cena.bruto1)} {door.id.cena.valuta}
                  </div>
                ) : <></>}
              </span>
              <span className="grid-doors__price-of-the-door small white-font upper-case">
              </span>
              {!isMobile ? (
                <div className="grid-doors__configure-door-holder">
                  <span className="grid-doors__configure-door small white-font upper-case">{t("configureDoors")}</span>
                </div>
              ) : (
                <></>
              )}
            </button>
          </div>
        ))}
    </>
  );
}
