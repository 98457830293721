import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import navigateToConf from '../konfiguratorComponents/KonfiguratorURLParams';
import { Context } from 'shared-home/src/home/Context';

const SpisekKonfiguracije = (props) => {
  const { form, setForm, onComponentChange, doorSvg, catalogue } = useContext(Context);

  const [summary, setSummary] = useState(false);
  const { t } = useTranslation();
  let navigate = useNavigate();

  //MOBILE CHECK
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768; // TRUE/FALSE

  const url = './images/';

  const ActiveColors = ({ colorArray, outside, title }) => {
    return (
      <>
        {colorArray &&
          colorArray.map((value, index) => (
            <>
              {outside === true ? (
                <div className="review-selection__door-wrapper flex-line align-top">
                  <div className="review-selection__door-color-wrapper">
                    <img src={url + value.barva.slika} alt={index} className="review-selection__door-image" style={{ width: '64px', height: '64px' }} />
                  </div>
                  <p className="review-selection__door-name">
                    {title}
                    <br />
                    {value.barva.name}
                  </p>
                </div>
              ) : (
                <div className="review-selection__door-wrapper flex-line align-top">
                  <div className="review-selection__door-color-wrapper">
                    <img src={url + value.barva.slika} alt={index} className="review-selection__door-image" style={{ width: '64px', height: '64px' }} />
                  </div>
                  <p className="review-selection__door-name">
                    {title}
                    <br />
                    {value.barva.name}
                  </p>
                </div>
              )}
            </>
          ))}
      </>
    );
  };

  const goHome = () => {
    setForm((f) => ({
      constructionType: f.constructionType,
      dimensionsHeight: f.dimensionsHeight,
      dimensionsWidth: f.dimensionsWidth,
      doorOpeningRightSide: f.doorOpeningRightSide,
      doorOpeningLeftSide: f.doorOpeningLeftSide
    }));

    navigate('../home', { replace: true });
  };

  function getModel(cat, selected) {
    return (
      cat &&
      cat
        .filter(function (item) {
          return item.id === selected;
        })
        .map(function ({ model, previewPath, imagePath }) {
          return { model, previewPath, imagePath };
        })[0]
    );
  }

  const peepholeModel = getModel(catalogue.kukala, form.peephole);

  const knockerModel = getModel(catalogue.tolkala, form.knocker);

  const dripBattenModel = getModel(catalogue.odkapneLetve, form.dripBatten);

  const lockModel = getModel(catalogue.zaklepanja, form.lockingType);

  const insideHandleModel = getModel(catalogue.notrDrzala, form.insideHandle);

  const ornamentnaModel = getModel(catalogue.steklaOrn, form.glassType);

  const motivnaModel =
    catalogue.steklaMot &&
    catalogue.steklaMot
      .filter(function (item) {
        return item.id.id === form.motivType;
      })
      .map(function ({ id }) {
        return { id };
      });

  const houseNumberImage =
    catalogue.hisneStevilke &&
    catalogue.hisneStevilke
      .filter(function (item) {
        return item.id === form.houseNumberIMG;
      })
      .map(function ({ previewPath }) {
        return { previewPath };
      });

  let kljukaId = form.outsideHandle + 'id';
  const outsideHandle = catalogue.zunanjaDrzalaMap && catalogue.zunanjaDrzalaMap[kljukaId] && catalogue.zunanjaDrzalaMap[kljukaId][0];
  // let outsideHandle;
  // if (catalogue.zunanjaDrzalaMap && catalogue.zunanjaDrzalaMap[kljukaId]){
  //     outsideHandle = catalogue.zunanjaDrzalaMap[kljukaId][0];
  // }

  const scrollToComponent = (e) => {
    if (e.target.name === 'odpiranje') {
      onComponentChange({ prilagodiScroll: 1 });
    } else if (e.target.name === 'dimenzije') {
      onComponentChange({ prilagodiScroll: 3 });
    } else if (e.target.name === 'barve') {
      onComponentChange({ prilagodiScroll: 4 });
    } else if (e.target.name === 'stekla') {
      onComponentChange({ prilagodiScroll: 5 });
    } else if (e.target.name === 'drzala') {
      onComponentChange({ prilagodiScroll: 6 });
    } else if (e.target.name === 'zaklepanja') {
      onComponentChange({ prilagodiScroll: 7 });
    } else if (e.target.name === 'dodatki') {
      onComponentChange({ prilagodiScroll: 8 });
    }

    navigate('/configurator/' + navigateToConf(form));
  };

  return (
    <>
      <div className="review-selection">
        <div className="review-selection__title mobile-hide">
          <h2>{t('InqConfSummary')}</h2>
          <i
            className={'cursor-pointer ' + (!summary ? 'ico ico-plus' : 'ico ico-minus')}
            onClick={() => {
              setSummary(!summary);
            }}
          />
        </div>
        {(isMobile || summary) && (
          <div className="review-selection__sections">
            {form.modelName && (
              <div className="review-selection__section flex-line align-stretch">
                <div className="review-selection__type">
                  <h3 className="upper-case">{t('InqModel')}</h3>
                </div>
                <div className="review-selection__delimiter ico-delimiter-straight"></div>
                <div className="review-selection__selected">
                  <div className="review-selection__door-wrapper flex-line align-top">
                    <div className="review-selection__door-image-wrapper">
                      <div id="svgContainer" className="review-selection__door-image" dangerouslySetInnerHTML={{ __html: doorSvg }}></div>
                    </div>
                    <p className="review-selection__door-name">{form.modelName}</p>
                  </div>
                  <button className="orange-link" onClick={goHome}>
                    {t('confSwitchModel')}
                  </button>
                </div>
              </div>
            )}
            {(form.doorOpeningLeftSide || form.doorOpeningRightSide) && (
              <div className="review-selection__section flex-line align-stretch">
                <div className="review-selection__type">
                  <h3 className="upper-case">{t('confOpening')}</h3>
                </div>
                <div className="review-selection__delimiter ico-delimiter-straight"></div>
                <div className="review-selection__selected">
                  <p>{form.doorOpeningLeftSide ? t('confLeft') : t('confRight')}</p>
                  <button className="orange-link" name="odpiranje" onClick={scrollToComponent}>
                    {t('confAdjust')}
                  </button>
                </div>
              </div>
            )}
            {form.dimensionHeight && form.dimensionWidth && (
              <div className="review-selection__section flex-line align-stretch">
                <div className="review-selection__type">
                  <h3 className="upper-case">{t('confDimensions')}</h3>
                </div>
                <div className="review-selection__delimiter ico-delimiter-straight"></div>
                <div className="review-selection__selected">
                  <p>
                    {form.dimensionHeight}mm x {form.dimensionWidth}mm
                  </p>
                  <button className="orange-link" name="dimenzije" onClick={scrollToComponent}>
                    {t('confAdjust')}
                  </button>
                </div>
              </div>
            )}
            {(form.colorWingOutside || form.colorAplikacijeOutside || form.colorDekorOutside || form.colorOkvirckiOutside || form.colorFrameOutside || form.colorWingInside || form.colorAplikacijeInside || form.colorDekorInside || form.colorOkvirckiInside || form.colorFrameInside) && (
              <div className="review-selection__section flex-line align-stretch">
                <div className="review-selection__type">
                  <h3 className="upper-case">{t('confColors')}</h3>
                </div>
                <div className="review-selection__delimiter ico-delimiter-straight"></div>
                <div className="review-selection__selected">
                  <p>{t('InqColOutside')}</p>
                  <ActiveColors colorArray={form.colorWingOutside} outside={true} title={t('confColWing')} />
                  <ActiveColors colorArray={form.colorAplikacijeOutside} outside={true} title={t('confAppOut')} />
                  <ActiveColors colorArray={form.colorDekorOutside} outside={true} title={t('confDekorOut')} />
                  <ActiveColors colorArray={form.colorOkvirckiOutside} outside={true} title={t('confWindowsOut')} />
                  <ActiveColors colorArray={form.colorFrameOutside} outside={true} title={t('confColFrame')} />
                  <p>{t('InqColInside')}</p>
                  <ActiveColors colorArray={form.colorWingInside} outside={false} title={t('confColWing')} />
                  <ActiveColors colorArray={form.colorAplikacijeInside} outside={false} title={t('confAppIn')} />
                  <ActiveColors colorArray={form.colorDekorInside} outside={false} title={t('confDekorIn')} />
                  <ActiveColors colorArray={form.colorOkvirckiInside} outside={false} title={t('confWindowsIn')} />
                  <ActiveColors colorArray={form.colorFrameInside} outside={false} title={t('confColFrame')} />
                  <button className="orange-link" name="barve" onClick={scrollToComponent}>
                    {t('confAdjust')}
                  </button>
                </div>
              </div>
            )}
            {form.doorHasGlass && ((form.motivType && form.motivType !== -1) || (form.glassType && form.glassType !== -1)) && (
              <div className="review-selection__section flex-line align-stretch">
                <div className="review-selection__type">
                  <h3 className="upper-case">{t('confGlass')}</h3>
                </div>
                <div className="review-selection__delimiter ico-delimiter-straight"></div>
                <div className="review-selection__selected">
                  {form.motivType && form.motivType !== -1 && (
                    <div className="review-selection__door-wrapper flex-line align-top">
                      <div className="review-selection__door-color-wrapper">
                        <img src={url + motivnaModel[0].id.previewSlika} alt="glass" className="review-selection__door-image" />
                      </div>
                      <p className="review-selection__door-name">{motivnaModel[0].id.model}</p>
                    </div>
                  )}
                  {form.glassType && form.glassType !== -1 && (
                    <div className="review-selection__door-wrapper flex-line align-top">
                      <div className="review-selection__door-color-wrapper">
                        <img src={url + ornamentnaModel.previewPath} alt="glass" className="review-selection__door-image" />
                      </div>
                      <p className="review-selection__door-name">{ornamentnaModel.model}</p>
                    </div>
                  )}
                  <button className="orange-link" name="stekla" onClick={scrollToComponent}>
                    {t('confAdjust')}
                  </button>
                </div>
              </div>
            )}
            {(form.outsideHandle || form.insideHandle) && (
              <div className="review-selection__section flex-line align-stretch">
                <div className="review-selection__type">
                  <h3 className="upper-case">{t('confHandle')}</h3>
                </div>
                <div className="review-selection__delimiter ico-delimiter-straight"></div>
                <div className="review-selection__selected">
                  {form.outsideHandle && outsideHandle && (
                    <div className="review-selection__door-wrapper flex-line align-top">
                      <div className="review-selection__door-color-wrapper">
                        <img src={url + outsideHandle.imagePath} alt="Zunanja držala" className="review-selection__door-image" />
                      </div>
                      <p className="review-selection__door-name">
                        {t('InqLeverOutside')} <br />
                        {outsideHandle.model}
                      </p>
                    </div>
                  )}
                  {form.insideHandle && (
                    <div className="review-selection__door-wrapper flex-line align-top">
                      <div className="review-selection__door-color-wrapper">
                        <img src={url + insideHandleModel.previewPath} alt="Notranja držala" className="review-selection__door-image" />
                      </div>
                      <p className="review-selection__door-name">
                        {t('InqLeverInside')}
                        <br />
                        {insideHandleModel.model}
                      </p>
                    </div>
                  )}
                  <button className="orange-link" name="drzala" onClick={scrollToComponent}>
                    {t('confAdjust')}
                  </button>
                </div>
              </div>
            )}
            {form.lockingType && (
              <div className="review-selection__section flex-line align-stretch">
                <div className="review-selection__type">
                  <h3 className="upper-case">{t('confLock')}</h3>
                </div>
                <div className="review-selection__delimiter ico-delimiter-straight"></div>
                <div className="review-selection__selected">
                  <div className="review-selection__door-wrapper flex-line align-top">
                    <div className="review-selection__door-color-wrapper">
                      <img src={url + lockModel.previewPath} alt="Zaklepanje" className="review-selection__door-image" />
                    </div>
                    <p className="review-selection__door-name">{lockModel.model}</p>
                  </div>
                  <button className="orange-link" name="zaklepanja" onClick={scrollToComponent}>
                    {t('confAdjust')}
                  </button>
                </div>
              </div>
            )}
            {!!(form.houseNumber || form.dripBatten || form.knocker || form.peephole) && (
              <div className="review-selection__section flex-line align-stretch">
                <div className="review-selection__type">
                  <h3 className="upper-case">{t('confExtras')}</h3>
                </div>
                <div className="review-selection__delimiter ico-delimiter-straight"></div>
                <div className="review-selection__selected">
                  {form.houseNumberIMG && (
                    <div className="review-selection__door-wrapper flex-line align-top">
                      <div className="review-selection__door-color-wrapper">
                        <img src={url + houseNumberImage[0].previewPath} alt="hišna številka" className="review-selection__door-image" />
                      </div>
                      <p className="review-selection__door-name">
                        {t('InqHouseNo')} <br />
                        {form.houseNumber}
                      </p>
                    </div>
                  )}
                  {form.dripBatten && (
                    <div className="review-selection__door-wrapper flex-line align-top">
                      <div className="review-selection__door-color-wrapper">
                        <img src={url + dripBattenModel.previewPath} alt="Odkapna letev" className="review-selection__door-image" />
                      </div>
                      <p className="review-selection__door-name">
                        {t('InqDripBattens')}
                        <br />
                        {dripBattenModel.model}
                      </p>
                    </div>
                  )}
                  {!!(form.knocker && form.knocker !== 0) && (
                    <div className="review-selection__door-wrapper flex-line align-top">
                      <div className="review-selection__door-color-wrapper">
                        <img src={url + knockerModel.previewPath} alt="Tolkalo" className="review-selection__door-image" />
                      </div>
                      <p className="review-selection__door-name">
                        {t('InqKnocker')}
                        <br />
                        {knockerModel.model}
                      </p>
                    </div>
                  )}
                  {!!(form.peephole && form.peephole !== 0) && (
                    <div className="review-selection__door-wrapper flex-line align-top">
                      <div className="review-selection__door-color-wrapper">
                        <img src={url + peepholeModel.previewPath} alt="Kukalo" className="review-selection__door-image" />
                      </div>
                      <p className="review-selection__door-name">
                        {t('InqPeephole')}
                        <br />
                        {peepholeModel.model}
                      </p>
                    </div>
                  )}
                  <button className="orange-link" name="dodatki" onClick={scrollToComponent}>
                    {t('confAdjust')}
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SpisekKonfiguracije;
