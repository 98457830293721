export const translationIt = {
  modelType: "Modelli",
  chooseModel: "Gamma delle porte d'ingresso",
  glass: "Vetro",
  modelShape: "Forma del vetro",
  SearchApp: "Finitura opzionale",
  chosenModel: "Modello selezionato",
  configureDoors: "Crea la porta",
  allDoors: "Tutti i modelli",
  findModel: "Cerca modello",
  showResults: "Mostra i risultati",
  filter: "Filtro",
  save: "Salvare e continuare più tardi",
  sendInquiry: "Invia la tua richiesta qui!",
  headerModel: "Scelta del modello",
  configurator: "Configuratore",
  confDoorOpening: "Senso di apertura DIN",
  confLeft: "Sinistra",
  confRight: "Destra",
  confConstructionType: "Tipologia di costruzione:",
  confDimensions: "Dimensioni",
  confHeight: "Altezza",
  confWidth: "Larghezza",
  confHeightExample: "e.g. 2100 mm",
  confWidthExample: "e.g. 1050 mm",
  confExample: "e.g.",
  confSkipDimensions: "Se non si conoscono ancora le dimensioni, è possibile saltare questo passaggio.",
  confColors: "Colori",
  confColWhole: "TUTTI GLI ELEMENTI COLORE",
  confColAdvanced: "Selezione avanzata dei colori",
  confCol1: "Colore 1",
  confCol2: "Colore 2",
  confMoreColors: "Mostra altri colori",
  confColorTop: "TOP 10 colori più venduti",
  confFindColor: "Trova il colore",
  confColorGroup: "GRUPPO DI COLORI",
  confColorShade: "Tonalità di colore",
  confColMa: "Opaco",
  confColSt: "Marezzato",
  confColShine: "Lucido",
  confColLess: "Nascondere più colori",
  confColReset: "Ripristino (reset)",
  confColWing: "Anta",
  confColOutside: "Esterno",
  confColInside: "Interno",
  confColFrame: "Telaio",
  confGlass: "Vetri",
  confMotGlass: "DESIGN MOTIVO",
  confOrnGlass: "VETRI ORNAMENTALI",
  confHandle: "Maniglioni",
  confHandleOutside: "MANIGLIONI ESTERNI",
  confHandleInside: "Maniglia interna",
  confHandleDesc: "Descrizione",
  confHandleDim: "Dimensioni",
  confLock: "SERRATURA",
  confLockTop: "Sistemi di chiusura più diffusi",
  confLockTypes: "Mostra altri sistemi di chiusura",
  confLockAll: "Tutti i sistemi di chiusura",
  confLockLess: "Nascondere sistemi di chiusura",
  confExtras: "Accessori",
  confExtrasBattens: "Zoccolo",
  confSummary: "Riepilogo configurazione",
  confContinue: "Continua più tardi",
  confLook: "Vista",
  confBackground: "Sfondo",
  confPicture: "Foto",
  confChooseModel: "Selezionare modello",
  confPickedModel: "Modello scelto",
  confSwitchModel: "Cambiare modello",
  confHouseNo: "Numero civico",
  confPeephole: "Spioncino",
  confKnocker: "Battiporta",
  confHouseInput: "Inserire il numero civico",
  confAdjust: "Adattare",
  confData: "Inserire i dati",
  InqName: "Nome",
  InqSurname: "Cognome",
  InqAddress: "Indirizzo: Via, Nr.",
  InqPost: "CAP",
  InqCity: "Città",
  InqEmail: "E-mail",
  InqEmDesc: "L'indirizzo e-mail serve per poter inviare un preventivo indicativo per la porta d'ingresso scelta.",
  InqPhone: "Telefono",
  InqPhoneDesc: "Il numero di telefono serve solo nel caso in cui avessimo bisogno di ulteriori informazioni per poter preparare l'offerta.",
  InqMessage: "Ancora qualcosa da dirci?",
  InqDoor: "Si tratta di una ristrutturazione o una nuova costruzione?",
  InqMark: "Segnare",
  InqNew: "Nuova costruzione",
  InqChange: "Ristrutturazione",
  InqContact: "Vorrei essere contattato per ricevere il mio preventivo o per l'installazione della vostra porta.",
  InqAgreement: "Accetto che i miei dati vengano trasmessi a venditori o partner autorizzati in relazione al mio preventivo.",
  InqNews: "L'invio di cataloghi gratuiti.",
  InqFinish: "Invia la richiesta",
  InqRequired: "Campo obbligatorio",
  InqSummary: "Riepilogo configurazione",
  InqConfSummary: "Elenco della configurazione completa",
  InqPDF: "Scarica PDF",
  InqShare: "Condividere il link",
  InqVisit: "Visita il nostro showroom",
  InqModel: "Modello",
  InqColOutside: "Esterno",
  InqColInside: "Interno",
  InqLeverInside: "Maniglia interna",
  InqLeverOutside: "Maniglione esterno",
  InqHouseNo: "Numero civico",
  InqDripBattens: "Zoccolo",
  InqKnocker: "Battiporta",
  InqPeephole: "Spioncino",
  close: "Chiudere",
  classicType: "Classico",
  modernType: "Moderno",
  extravagantType: "Stravagante",
  simpleType: "Semplice",
  topSeller: "I più venduti",
  glassNone: "Senza",
  glassSmall: "Piccolo",
  glassMedium: "Medio",
  glassBig: "Grande",
  shapeRectangle: "Rettangoli",
  shapeSquare: "Quadrati",
  shapeCurves: "Curve e ad arco",
  shapeOther: "Altre forme",
  searchMulticolor: "Bicolore",
  searchInox: "Applicazioni in acciaio inox",
  searchUtori: "Fresate",
  BGColor: "Scelta del colore di sfondo",
  SelectBGColor: "Scelta del colore",
  BGPictureTitle: "Inserire immagine",
  BGPictureDesc: "Inserire foto della vostra casa o scegliere un'immagine dalle foto",
  BGPicturestep1: "Passo 1",
  BGPictureTip1: "Caricare o scegliere immagine",
  BGPicturestep2: "Passo 2",
  BGPictureTip2: "Posizionare la porta",
  BGPicturestep3: "Passo 3",
  BGPictureTip3: "Continuare la configurazione",
  BGPictureUpload: "Caricare foto dal computer",
  BGPictureDesc2: "o scegliere un'immagine dalle foto",
  BGPictureChoose: "usare immagine",
  confOpening: "Apertura",
  confThankYouPage: "Grazie per la tua richiesta!",
  noModelResults: "Nessun risultato",
  confAppOut: "Applicazione all'esterno",
  confAppIn: "Applicazione all'interno",
  confDekorOut: "Decoro esterno",
  confDekorIn: "Decoro interno",
  confWindowsOut: "Cornici all'esterno",
  confWindowsIn: "Cornici all'interno",
  confHandleOutsideMainColor: "Colore selezionato",
  CustomerInformation: "I tuoi dati",
  CustomerContact: "Dettagli del contatto",
  CustomerNameSurname: "Nome e cognome",
  CustomerAddress: "Via e numero civico",
  CustomerPostCity: "Codice postale e luogo",
  CustomerCountry: "Paese",
  CustomerEmail: "Indirizzo e-mail",
  CustomerPhone: "Numero di telefono",
  CustomerNote: "Note sull'ordine",
  CustomerDoorSetup: "Hai bisogno di una porta per una ristrutturazione o una nuova costruzione?",
  TyPageDownload: "Scaricare la configurazione in formato pdf",
  TyPageShare: "Condividere il link della configurazione",
  TyPageAppreciation: "Siamo felici e orgogliosi che sia interessato/a all'acquisto di una porta d'ingresso Inotherm. Siamo sicuri che sia la scelta ideale per la tua facciata e, scegliendole, ti unirai alle migliaia di clienti soddisfatti in tutto il mondo la cui casa è già impreziosita da una porta d'ingresso Inotherm.",
  TyPageHeader: "Grazie per la sua richiesta!",
  TyPageDescription1: "Nella tabella qui sopra potete vedere tutti i dettagli della vostra configurazione attuale, per la quale riceverete anche un preventivo non vincolante entro le prossime 24 ore.",
  TyPageDescription2: "A breve sarai ricontattato/a da uno dei nostri rivenditori specializzati, che sarà in grado di discutere ulteriori fasi come: le modifiche alla configurazione, le misure dell'apertura della parete e l'ordine finale.",
  model: "Modello",
  confHandleOutsideExtraColoring: "Colori aggiuntivi",
  confHandleOutsideColor: "Colore del maniglione",
  confHandleOutsideAdditionalColor: "Colore dell'incavo",
  confColorsOutside: "Colori esterni",
  confColorsInside: "Colori interni",
  TyPageCopyRights: "All rights reserved 2023",
  logoLink: "https://www.inotherm.it/",
  InoWebsite: "Procedere al nostro sito web",
  positionHouseNum: "Posizione del numero civico sulla porta",
  positionKnocker: "Posizione di battiporta sulla porta",
  positionPeephole: "Posizione dello spioncino sulla porta",
  positionInfo: "Utilizzare lo strumento sottostante per posizionare l'accessorio nella posizione desiderata sulla porta.",
  positionHorizontal: "ORIZZONTALE",
  positionVertical: "VERTICALE",
  headerFilterVsi: "Tutti i modelli",
  headerFilterAkcija24: "Promozione 24",
  headerFilterZimska: "Promozione invernale",
  headerFilterTop: "Più venduti",
  headerFilterAkcija: "Black Edition",
  openFilters: "FILTRI",
  clearFilters: "Reset filtri",
  useFilters: "Mostra i risultati",
  bannerText: "SCEGLIETE IL MODELLO DI PORTA D'INGRESSO E INIZIATE A PROGETTARE IL VOSTRO INGRESSO",
  miniCokel: "Zoccolo basso",
}
