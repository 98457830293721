export const translationRo = {
	modelType: "Model",
	chooseModel: "Programe usi de intrare",
	glass: "Sticla",
	modelShape: "Forma sticla",
	SearchApp: "Aplicatii model",
	chosenModel: "Model selectat",
	configureDoors: "Creaza usa ta",
	allDoors: "Toate",
	findModel: "Cauta model",
	showResults: "Afiseaza rezultate",
	filter: "Filtre",
	save: "Salveaza si continua mai tarziu",
	sendInquiry: "Trimite cererea",
	headerModel: "Selctie model",
	configurator: "Configurator",
	confDoorOpening: "DIN - Directia",
	confLeft: "Stanga",
	confRight: "Dreapta",
	confConstructionType: "Tip si constructie",
	confDimensions: "Dimensiuni",
	confHeight: "Inaltime",
	confWidth: "Latime",
	confHeightExample: "de exemplu 2100 mm",
	confWidthExample: "de exemplu 1050 mm",
	confExample: "de exemplu",
	confSkipDimensions: "Daca nu ai dimensiunile, poti sari peste acest pas.",
	confColors: "Culori",
	confColWhole: "CULOARE PENTRU TOATE ELEMENTELE",
	confColAdvanced: "Extinde selectia",
	confCol1: "Culoare 1",
	confCol2: "Culoare 2",
	confMoreColors: "Afiseaza mai multe culori",
	confColorTop: "TOP 10 cele mai vandute culori",
	confFindColor: "Gaseste culoare",
	confColorGroup: "GRUP DE CULORI",
	confColorShade: "Umbra culorii",
	confColMa: "Mat",
	confColSt: "Texturat",
	confColShine: "Lucios",
	confColLess: "Ascunde selectia extinsa",
	confColReset: "Reset",
	confColWing: "Foaie",
	confColOutside: "Exterior",
	confColInside: "Interior",
	confColFrame: "Usa cadru",
	confGlass: "Sticla",
	confMotGlass: "MODEL DESIGN",
	confOrnGlass: "STICLA ORNAMENTALA",
	confHandle: "Manere",
	confHandleOutside: "MANER EXTERIOR",
	confHandleInside: "Maner interior",
	confHandleDesc: "Descriere",
	confHandleDim: "Dimensiuni",
	confLock: "Sisteme de blocare",
	confLockTop: "Cele mai populare sisteme de blocare",
	confLockTypes: "Afiseaza mai multe sisteme de blocare",
	confLockAll: "Toate sistemele de blocare",
	confLockLess: "Ascunde sistemele de blocare",
	confExtras: "Accesorii",
	confExtrasBattens: "Element de protectie",
	confSummary: "Sumar configuratie",
	confContinue: "Continua mai tarziu",
	confLook: "Vedere",
	confBackground: "Fundal",
	confPicture: "Foto",
	confChooseModel: "Selectie model usa",
	confPickedModel: "Model selectat",
	confSwitchModel: "Schimba modelul",
	confHouseNo: "Numar de casa",
	confPeephole: "Vizor digital",
	confKnocker: "Dispozitiv pentru bataie in usa",
	confHouseInput: "Insereaza numar de casa",
	confAdjust: "Ajusteaza",
	confData: "Informatiile tale",
	InqName: "Prenume",
	InqSurname: "Nume de familie",
	InqAddress: "Strada, Nr.",
	InqPost: "Cod postal",
	InqCity: "Oras",
	InqEmail: "E-Mail",
	InqEmDesc: "Avem nevoie de adresa ta de email pentru a putea trimite oferta pentru usa ta de intrare.",
	InqPhone: "Telefon",
	InqPhoneDesc: "Folosim numarul tau de telefon doar in cazul in care avem nevoie de informatii suplimentare ca sa pregatim oferta.",
	InqMessage: "Doresti sa ne spui ceva?",
	InqDoor: "Aceasta cerere este pentru Renovare sau pentru Constructie Noua?",
	InqMark: "Selectati conform preferintelor",
	InqNew: "Constructie Noua",
	InqChange: "Renovare",
	InqContact: "Contactati-ma pentru oferta / vanzari / informatii tehnice.",
	InqAgreement: "Sunt de acord ca datele mele de contact sa fie transmise vanzatorului sau unui partener autorizat in scopul consultantei si a generarii ofertei.",
	InqNews: "Trimite-mi un catalog.",
	InqFinish: "Trimite cererea de oferta",
	InqRequired: "Camp obligatoriu",
	InqSummary: "Sumar configuratie",
	InqConfSummary: "Lista configuratiei complete",
	InqPDF: "Descarca PDF",
	InqShare: "Distribuie link-ul",
	InqVisit: "Viziteaza showroom-ul nostru",
	InqModel: "Model",
	InqColOutside: "Exterior",
	InqColInside: "Interior",
	InqLeverInside: "Maner interior",
	InqLeverOutside: "Maner",
	InqHouseNo: "Numar de casa",
	InqDripBattens: "Element de protectie",
	InqKnocker: "Dispozitiv pentru bataie in usa",
	InqPeephole: "Vizor digital",
	close: "Inchide",
	classicType: "Clasic",
	modernType: "Modern",
	extravagantType: "Extravagant",
	simpleType: "Simplu",
	topSeller: "Top vanzari",
	glassNone: "Fara",
	glassSmall: "Mic",
	glassMedium: "Mediu",
	glassBig: "Mare",
	shapeRectangle: "Dreptunghiuri",
	shapeSquare: "Patrate",
	shapeCurves: "Curbe si arcuri de cerc",
	shapeOther: "Alte forme",
	searchMulticolor: "Decor",
	searchInox: "Aplicatii din otel",
	searchUtori: "Relief",
	BGColor: "Selecteaza culoare fundal",
	SelectBGColor: "Selecteaza culoare",
	BGPictureTitle: "Insereaza imagine",
	BGPictureDesc: "Insereaza imaginea casei tale sau selecteaza una din urmatoarele fotografii",
	BGPicturestep1: "Pasul 1",
	BGPictureTip1: "Incarca sau selecteaza imagine",
	BGPicturestep2: "Pasul 2",
	BGPictureTip2: "Pozitia usii",
	BGPicturestep3: "Pasul 3",
	BGPictureTip3: "Continua configurarea",
	BGPictureUpload: "Incarca o imagine din computer",
	BGPictureDesc2: "sau alege o imagine",
	BGPictureChoose: "foloseste imaginea",
	confOpening: "Deschidere",
	confThankYouPage: "Multumim pentru cererea ta!",
	noModelResults: "Nu exista rezultate",
	confAppOut: "Aplicare exterioara",
	confAppIn: "Aplicare interioara",
	confDekorOut: "Decor exterior",
	confDekorIn: "Decor interior",
	confWindowsOut: "Cadru exterior",
	confWindowsIn: "Cadru interior",
	confHandleOutsideMainColor: "Alegere culoare",
	CustomerInformation: "Datele tale",
	CustomerContact: "Date de contact",
	CustomerNameSurname: "Nume si prenume",
	CustomerAddress: "Strada si numar",
	CustomerPostCity: "Cod postal si oras",
	CustomerCountry: "Tara",
	CustomerEmail: "Adresa de e-mail",
	CustomerPhone: "Numar de telefon",
	CustomerNote: "Note comanda",
	CustomerDoorSetup: "Ai nevoie de usa pentru inlocuire sau pentur constructie noua?",
	TyPageDownload: "Descarca configurarea in document PDF",
	TyPageShare: "Distribuie link-ul catre configurare",
	TyPageAppreciation: "Suntem incantati si onorati ca va intereseaza achizitia unei usi de intrare Inotherm. Credem cu tarie ca aceasta se va potrivi perfect fatadei, si facand aceasta alegere, va veti alatura miilor de clienti multumiti din toata lumea, ale caror case sunt deja dotate cu usi de intrare Inotherm.",
	TyPageHeader: "Multumim pentru cererea ta!",
	TyPageDescription1: "In tabelul de deasupra poti vedea toate detaliile configurarii curente, pentru care vei primi o oferta fara-obligatii in urmatoarele 24 de ore.",
	TyPageDescription2: "Vei fi contactat in cel mai scurt timp de catre unul din reprezentantii nostrii de vanzari, cu care poti discuta urmatorii pasi, schimbari ale configuratiei, masuratorile golului din perete si comanda finala.",
	model: "Model",
	confHandleOutsideExtraColoring: "Alte culori",
	confHandleOutsideColor: "Culoare maner",
	confHandleOutsideAdditionalColor: "Culoarea piesei incastrate",
	confColorsOutside: "Culori exterior",
	confColorsInside: "Culori interior",
	TyPageCopyRights: "Toate drepturile rezervate 2023",
	logoLink: "https://www.inotherm.com/",
	InoWebsite: "Catre site-ul nostru",
	positionHouseNum: "Pozitia numarului de casa pe usa",
	positionKnocker: "Pozitia dispozitivului pentru batut pe usa",
	positionPeephole: "Pozitia vizorului pe usa",
	positionInfo: "Foloseste unealta de mai jos pentru a pozitiona accesoriul in pozitia dorita pe usa.",
	positionHorizontal: "ORIZONTAL",
	positionVertical: "VERTICAL",
	headerFilterVsi: "Toate modelele",
	headerFilterAkcija24: "Aktion 24",
	headerFilterZimska: "Promotie de iarna",
	headerFilterTop: "Cele mai vandute",
	headerFilterAkcija: "Black Edition",
	openFilters: "FILTRE",
	clearFilters: "Sterge tot",
	useFilters: "Afiseaza rezultatele",
	bannerText: "ALEGE UN MODEL PENTRU USA DE INTRARE SI INCEPE SA ITI CONFIGUREZI USA",
	miniCokel: "profil de bază scăzut",
}