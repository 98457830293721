import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from 'shared-home/src/home/Context';
import { useTranslation } from 'react-i18next';

const IzborModela = React.forwardRef((props, ref) => {
  const { form, setForm, setCatalogue, catalogue } = useContext(Context);
  let navigate = useNavigate();

  const { t } = useTranslation();

  const goHome = () => {
    //PARAMETERS THAT NEED TO STAY IN FORM ON MODEL CHANGE -> constructionType, dimensionHeight, dimensionWidth, doorOpeningRightSide, doorOpeningLeftSide
    setForm((f) => ({
      izberiModel: f.izberiModel,
      constructionType: f.constructionType,
      dimensionHeight: f.dimensionHeight,
      dimensionWidth: f.dimensionWidth,
      doorOpeningRightSide: f.doorOpeningRightSide,
      doorOpeningLeftSide: f.doorOpeningLeftSide
    }));

    Object.entries(catalogue).map(() => {
      //ohranit samo nekatere
      setCatalogue((cat) => ({ tolkala: catalogue.tolkala, kukala: catalogue.kukala, odkapneLetve: catalogue.odkapneLetve, hisneStevilke: catalogue.hisneStevilke, zaklepanja: catalogue.zaklepanja }));
    });

    navigate('../home', { replace: true });
  };

  return (
    <>
      <div className="section select-model-door" id="conf-step-num-1" step="1" ref={ref}>
        <div className="container">
          <h2 className="upper-case">{t('confChooseModel')}</h2>
          <div className="selected-model flex-line align-top">
            <p className="selected-model__title">{t('confPickedModel')}</p>
            <i className="ico ico-delimiter-straight ico-delimiter-straight--longer"></i>
            <div className="selected-model__selected">
              <p className="selected-model__name">{form.modelName}</p>
              <button type="button" className="orange-link" onClick={goHome}>
                {t('confSwitchModel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default IzborModela;
