import React from 'react';
import ConfigurationSummary from './povprasevanjeComponents/PovzetekKonfiguracije';
import CustomerInfo from './povprasevanjeComponents/PodatkiStranke';
import { useTranslation } from 'react-i18next';

function Inquiry() {
  const { t } = useTranslation();

  const goHomePage = () => {
    const getLink = window.inoAPI.getHomePageLink();

    if (getLink === null) {
      // navigate(`/${t('logoLink')}`, { replace: true })
      window.location.replace(`${t('logoLink')}`);
    } else {
      window.location.replace(`${getLink}`);
    }
  };

  return (
    <>
      <body id="main">
        <div id="grid-wrapper">
          <header className="header">
            <div className="header__main flex-line justify-center">
              <div className="logo cursor-pointer" onClick={goHomePage}>
                <div className="logo__image"></div>
              </div>
            </div>
          </header>
          <div className="main-canvas">
            <div className="thank-you-desktop full-screen-container-bigger flex-line align-top">
              <CustomerInfo />
              <div className="review-configurator-wrapper container--move-from-top one-third">
                <div className="review-header">
                  <h2 className="upper-case">{t('InqSummary')}</h2>
                </div>
                <div className="review-configurator-wrapper__bg-grey">
                  <ConfigurationSummary />
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
}

export default Inquiry;
