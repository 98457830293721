import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from 'shared-home/src/home/Context';
import { useNavigate } from 'react-router-dom';
import SpisekKonfiguracije from './SpisekKonfiguracije';
import { getPartnerCodeEmbedded } from 'shared-home/src/home/PartnerCode';

function ConfigurationSummary() {
  const { t } = useTranslation();
  const { form, doorSvg } = useContext(Context);
  let navigate = useNavigate();

  //MOBILE CHECK
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768; // TRUE/FALSE

  const onDownloadPDF = () => {
    window.dataLayer.push({
      event: 'export_to_pdf'
    });
    const loadingScreen = document.getElementById('loading-wait');
    loadingScreen.style.display = 'flex';
    console.log('start pdf');
    window.inoAPI.getOffer().printKonfigurator(form.pozicioniranaFasada ? form.pozicioniranaFasada : false, form.BGColor ? form.BGColor.id : '#EEEEEE', getPartnerCodeEmbedded(), form.configuratorURL, (success, data, error) => {
      loadingScreen.style.display = 'none';
      if (success) {
        var byteArray = new Uint8Array(data);
        const blob = new Blob([byteArray], { type: 'application/pdf;charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', form.modelName + '_' + Date.now() + '.pdf');

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        if (link.parentNode) {
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        }
        console.log('end pdf, success!');
      } else {
        window.alert('An internal error occurred. Please try again later.');
        console.log('end pdf, error!');
      }
    });
  };

  const onLinkShare = () => {
    window.dataLayer.push({
      event: 'configuration_share'
    });

    if (window.location.protocol === 'https:') {
      // SHOWS INSTALLED APPS WHERE LINK CAN BE SHARED ON MOBILE; ELSE COPIES LINK TO CLIPBOARD
      if (navigator.share) {
        navigator
          .share({
            url: form.configuratorURL
          })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing:', error));
      } else {
        navigator.clipboard.writeText(form.configuratorURL);
        window.alert('URL Copied to clipboard');
      }
    } else {
      window.alert('Copy link in the URL');
    }
  };

  const goHomePage = () => {
    const getLink = window.inoAPI.getHomePageLink();

    if (getLink === null) {
      window.location.replace(`${t('logoLink')}`);
    } else {
      window.location.replace(`${getLink}`);
    }
  };

  return (
    <>
      <header className="header fixed-position desktop-hide">
        <div className="header__main flex-line justify-center">
          <div className="logo cursor-pointer" onClick={goHomePage}>
            <div className="logo__image"></div>
          </div>
        </div>
      </header>
      <div className="form-mobile-wrapper main-canvas" style={{ paddingTop: '50px' }}>
        <div id="loading-wait" className="loading-wait">
          <i className="ico ico-loading"></i>
        </div>
        <div className={'container--move-from-top ' + (isMobile && 'container')}>
          <div className="form__header desktop-hide">
            <button className="go-back cursor-pointer">
              <i className="ico ico-arrow-back cursor-pointer" onClick={() => navigate('/inquiryCustomerInfo', { replace: true })}></i>
            </button>
            <h2 className="upper-case" style={{ overflow: 'auto' }}>
              {t('confSummary')}
            </h2>
          </div>
          <div id="svgContainer" className={'show-door show-door--review selected-door'} dangerouslySetInnerHTML={{ __html: window.inoAPI.getDoorSketcher().getSVGClean() }}>
            {/* SPODNJA "PREČKA" POD VRATI

                        <i className="ico ico-door-bottom">
                            <svg width="100%" height="100%" viewBox="0 0 267 12" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M266.982 0H0.03125V11.8123H266.982V0Z"
                                    fill="url(#paint0_linear_1016_11649)" />
                                <path d="M267 4.29453H0V5.84988H267V4.29453Z" fill="#F5F5F5" />
                                <defs>
                                    <linearGradient id="paint0_linear_1016_11649" x1="133.509" y1="2.10062"
                                        x2="133.509"
                                        y2="17.3621" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.01" stop-color="#E0E0E0" />
                                        <stop offset="0.35" stop-color="#DCDCDC" />
                                        <stop offset="0.68" stop-color="#D0D0D0" />
                                        <stop offset="1" stop-color="#BDBDBD" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </i> */}
          </div>
          {!isMobile && (
            <div className="review-actions">
              <div className="review-actions__container">
                <div className="review-actions__single">
                  <button onClick={onLinkShare} className="white-link flex-line">
                    <i className="ico ico-send-link review-actions__icon"></i>
                    <h3 className="upper-case">{t('InqShare')}</h3>
                  </button>
                </div>
                <div className="review-actions__single">
                  <button onClick={onDownloadPDF} className="white-link flex-line">
                    <i className="ico ico-download-pdf review-actions__icon"></i>
                    <h3 className="upper-case">{t('InqPDF')}</h3>
                  </button>
                </div>
                {/* <div className="review-actions__single">
                                    <a href="https://www.inotherm.si/prodajna-mesta/" className="white-link flex-line">
                                        <i className="ico ico-salon review-actions__icon"></i>
                                        <h3 className="upper-case">{t('InqVisit')}</h3>
                                    </a>
                                </div> */}
              </div>
            </div>
          )}
          <SpisekKonfiguracije />
          {isMobile && (
            <div className="container" style={{ paddingLeft: '0', paddingRight: '0', paddingBottom: '25px' }}>
              <div className="review-actions">
                <div className="review-actions__container">
                  <div className="review-actions__single">
                    <button onClick={onLinkShare} className="white-link flex-line">
                      <i className="ico ico-send-link review-actions__icon"></i>
                      <h3 className="upper-case align-left">{t('InqShare')}</h3>
                    </button>
                  </div>
                  <div className="review-actions__single">
                    <button onClick={onDownloadPDF} className="white-link flex-line">
                      <i className="ico ico-download-pdf review-actions__icon"></i>
                      <h3 className="upper-case align-left">{t('InqPDF')}</h3>
                    </button>
                  </div>
                  <div className="review-actions__single">
                    <a href="https://www.inotherm.si/prodajna-mesta/" className="white-link flex-line">
                      <i className="ico ico-salon review-actions__icon"></i>
                      <h3 className="upper-case align-left">{t('InqVisit')}</h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ConfigurationSummary;
