export const translationHu = {
	modelType: "Modell",
	chooseModel: "Alumínium bejárati ajtó modell",
	glass: "üveg",
	modelShape: "üveg kialakítása",
	SearchApp: "appliáció a modellen",
	chosenModel: "kiválasztott mdell",
	configureDoors: "ajtó konfigurálása",
	allDoors: "összes",
	findModel: "modell keresése",
	showResults: "keresési találat",
	filter: "szűrő",
	save: "mentés és",
	sendInquiry: "ajánlatkérés",
	headerModel: "Ajtómodell kiválasztása",
	configurator: "konfigurátor",
	confDoorOpening: "DIN szerinti nyitásirány",
	confLeft: "balos",
	confRight: "jobbos",
	confConstructionType: "Típus és konstrukció",
	confDimensions: "méretek",
	confHeight: "magasság",
	confWidth: "szélesség",
	confHeightExample: "pl. 2100 mm",
	confWidthExample: "pl. 1050 mm",
	confExample: "pl.",
	confSkipDimensions: "Ha nem tudja a méreteket, ez a lépés átugorható",
	confColors: "színek",
	confColWhole: "minden elem színe",
	confColAdvanced: "Bővebb színválaszték",
	confCol1: "Szín 1",
	confCol2: "Szín 2",
	confMoreColors: "További színválaszték",
	confColorTop: "legnépszerűbb ajtók - TOP 10",
	confFindColor: "Szín keresése",
	confColorGroup: "F1-es színcsoport",
	confColorShade: "Színárnyalat",
	confColMa: "matt",
	confColSt: "feinstruktúr",
	confColShine: "fényes",
	confColLess: "Színek elrejtése",
	confColReset: "visszaállít",
	confColWing: "szárny",
	confColOutside: "Kívül",
	confColInside: "Belül",
	confColFrame: "tok",
	confGlass: "üvegek",
	confMotGlass: "design minta",
	confOrnGlass: "ornamens üveg",
	confHandle: "Kilincsek",
	confHandleOutside: "FOGANTYÚK",
	confHandleInside: "belső kilincs",
	confHandleDesc: "leírás",
	confHandleDim: "méretek",
	confLock: "zár típus",
	confLockTop: "Legkedveltebb zárrendszerek",
	confLockTypes: "Mutass több variációt",
	confLockAll: "Minden zárrendszer",
	confLockLess: "Zárrendszerek elrejtése",
	confExtras: "tartozék",
	confExtrasBattens: "vízvető",
	confSummary: "Kunfiguráció összefoglalása",
	confContinue: "Későbbi folytatás",
	confLook: "nézet",
	confBackground: "Háttér",
	confPicture: "kép",
	confChooseModel: "Modell kiválasztása",
	confPickedModel: "Kiválasztott modell",
	confSwitchModel: "Modell csere",
	confHouseNo: "házszám",
	confPeephole: "kukucskáló",
	confKnocker: "kopogtató",
	confHouseInput: "Házszám megadása",
	confAdjust: "Beállítások",
	confData: "Adatok",
	InqName: "Vezetéknév",
	InqSurname: "Keresztnév",
	InqAddress: "utca, házszám",
	InqPost: "Irányítószám",
	InqCity: "Helyszín",
	InqEmail: "E-mail",
	InqEmDesc: "Az előzetes árajánlat készítéshez szükséges e-mail cím",
	InqPhone: "telefon",
	InqPhoneDesc: "A telenfonszám az esetlegesen felmerő kérdések miatt szükséges",
	InqMessage: "Közlemény:",
	InqDoor: "Felújítás vagy új építésű?",
	InqMark: "Ennek megfelelően határozza meg",
	InqNew: "új építés",
	InqChange: "felújítás",
	InqContact: "kapcsolatfelvétel,",
	InqAgreement: "Beleegyezek, hogy az ajálnathoz az adataimat viszonteladó partner részére továbbítsák.",
	InqNews: "Küljenek részemre információt az aktuláis újdonságokról, akciókról.",
	InqFinish: "Ajánlatkérés elküldése",
	InqRequired: "Kötelező",
	InqSummary: "Konfuguráció összefoglalása",
	InqConfSummary: "A teljes konfiguráció listája",
	InqPDF: "PDF letöltése",
	InqShare: "Link megosztása",
	InqVisit: "Látogasson meg minket",
	InqModel: "Modell",
	InqColOutside: "Kívül",
	InqColInside: "Belül",
	InqLeverInside: "Belső oldali kilincs",
	InqLeverOutside: "Fogantyúk",
	InqHouseNo: "Házszám",
	InqDripBattens: "Vízvető",
	InqKnocker: "Kopogtató",
	InqPeephole: "Kukucskáló",
	close: "zár típus",
	classicType: "Kalsszikus",
	modernType: "Modern",
	extravagantType: "Extravagáns",
	simpleType: "Egyszerű",
	topSeller: "legnépszerűbb",
	glassNone: "Nélkül",
	glassSmall: "Kicsi",
	glassMedium: "Közepes",
	glassBig: "Nagy",
	shapeRectangle: "szögletes",
	shapeSquare: "négyzetes",
	shapeCurves: "íves",
	shapeOther: "más formák",
	searchMulticolor: "több színű",
	searchInox: "rozsdamentes applikáció",
	searchUtori: "marás minta",
	BGColor: "háttérszín kiválasztása",
	SelectBGColor: "szín választása",
	BGPictureTitle: "kép beolvasása",
	BGPictureDesc: "Olvassa be saját fotóját, vagy válsszon az alábbi képek közül",
	BGPicturestep1: "1. lépés",
	BGPictureTip1: "Kép betöltése vagy kiválasztása",
	BGPicturestep2: "2. lépés",
	BGPictureTip2: "Ajtó pozicionálása",
	BGPicturestep3: "3. lépés",
	BGPictureTip3: "konfiguráció folytatása",
	BGPictureUpload: "Feltöltés saját gépről",
	BGPictureDesc2: "vagy válasszon az alábbi képek közül",
	BGPictureChoose: "vagy válasszon az alábbi képek közül",
	confOpening: "Nyitás",
	confThankYouPage: "Köszönjük érdeklődését!",
	noModelResults: "Nincs eredmény",
	confAppOut: "Applikáció a külső oldalon",
	confAppIn: "Applikáció a belső oldalon",
	confDekorOut: "Dekor kívül",
	confDekorIn: "Dekor belül",
	confWindowsOut: "Alumínium ornamens elemek külső oldalon",
	confWindowsIn: "Alumínium ornamens elemek belső oldalon",
	confHandleOutsideMainColor: "Színválasztás",
	CustomerInformation: "Az Ön adatai",
	CustomerContact: "Kapcsolattartási adatok",
	CustomerNameSurname: "Név és vezetéknév",
	CustomerAddress: "Utca és házszám",
	CustomerPostCity: "Postai irányítószám és helység",
	CustomerCountry: "Ország",
	CustomerEmail: "E-mail cím",
	CustomerPhone: "Telefonszám",
	CustomerNote: "Egyedi igények, megjegyzések",
	CustomerDoorSetup: "Új építésű ház, vagy felújítás?",
	TyPageDownload: "Konfiguráció letöltése pdf dokumentumban",
	TyPageShare: "A konfiguráció linkjének megosztása",
	TyPageAppreciation: "Megtiszteltetés számunkra, hogy érdeklődik az Inotherm bejárati ajtók iránt. Hisszük, hogy ajtóink minden igényt kielégítenek, és ha ezt a terméket választja, Ön is csatlakozhat ahhoz a világszerte több ezer elégedett ügyfélhez, akiknek otthonát már Inotherm bejárati ajtó díszíti.",
	TyPageHeader: "Köszönjük érdeklődését!",
	TyPageDescription1: "A fenti táblázatban megtekintheti az aktuális konfiguráció minden részletét, amelyre a következő 24 órában egy kötelezettség nélküli ajánlatot is kap.",
	TyPageDescription2: "Rövidesen felveszi Önnel a kapcsolatot egyik értékesítési szakértőnk is, akivel megbeszélhetik a következő lépéseket, például a konfiguráció módosítását, a falnyílás mérését és a végleges megrendelést.",
	model: "Modell",
	confHandleOutsideExtraColoring: "További színek",
	confHandleOutsideColor: "Fogantyú színe",
	confHandleOutsideAdditionalColor: "A süllyesztett rész színe",
	confColorsOutside: "Külső színek",
	confColorsInside: "Belső színek",
	TyPageCopyRights: "All rights reserved 2023",
	logoLink: "https://www.inotherm.com/",
	InoWebsite: "A weboldalunkhoz",
	positionHouseNum: "A házszám helyzete az ajtón",
	positionKnocker: "A kopogtató helyzete az ajtón",
	positionPeephole: "A kukucskálónyílás helyzete az ajtón",
	positionInfo: "Használja az alábbi eszközt a tartozéknak az ajtón a kívánt helyre történő elhelyezéséhez.",
	positionHorizontal: "HORIZONTÁLIS",
	positionVertical: "VERTIKÁLIS",
	headerFilterVsi: "Minden modell",
	headerFilterAkcija24: "Aktion 24",
	headerFilterZimska: "Téli akció",
	headerFilterTop: "Legjobb eladók",
	headerFilterAkcija: "Fekete kiadás",
	openFilters: "FILTEREK",
	clearFilters: "Törölje az összeset",
	useFilters: "Eredmények megjelenítése",
	bannerText: "VÁLASSZON EGY BEJÁRATI AJTÓ MODELLT ÉS KEZDJE EL A BEJÁRAT TERVEZÉSÉT",
	miniCokel: "Alacsony küszöbérték profil",
}
