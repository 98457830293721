import React, { useState, useContext, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { Context } from 'shared-home/src/home/Context';
import { useTranslation } from 'react-i18next';
import { settings } from '../common/sliderSettings';

const Stekla = React.forwardRef((props, ref) => {
  const { form, onComponentChange, catalogue } = useContext(Context);
  const [tabMotivna, setTabMotivna] = useState(false);
  const [tabOrnamentna, setTabOrnamentna] = useState(false);
  const sliderOrnamentGlassRef = useRef();
  const sliderMotiveGlassRef = useRef();

  const url = './images/';
  const { t } = useTranslation();

  useEffect(() => {
    if (window.inoAPI) {
      if (form.motivType !== -1 && form.motivType !== null) {
        setTabMotivna(true);
        setTabOrnamentna(false);
      } else {
        setTabMotivna(false);
        setTabOrnamentna(true);
      }
    }
  }, [form.glassType, form.motivType, window.inoAPI]);

  //MOBILE CHECK
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width < 782 || height <= 612; // TRUE/FALSE

  const selectedMotiv = (item) => {
    onComponentChange({
      motivType: item.id.id,
      glassType: null
    });
  };
  const selectedGlass = (item) => {
    onComponentChange({
      glassType: item.id,
      motivType: null
    });
  };

  const onTabChange = (e) => {
    if (e.target.name === 'tabMotivna') {
      setTabMotivna(true);
      setTabOrnamentna(false);
    }

    if (e.target.name === 'tabOrnamentna') {
      setTabMotivna(false);
      setTabOrnamentna(true);
    }
  };

  //SCROLL INSTANTLY TO SELECTED GLASS ON RELOAD
  useEffect(() => {
    if (sliderMotiveGlassRef.current) {
      const selectedMotiveGlassIdx = catalogue.steklaMot && catalogue.steklaMot.findIndex((item) => form.motivType === item.id.id);
      sliderMotiveGlassRef.current.slickGoTo(Math.max(Math.min(selectedMotiveGlassIdx, selectedMotiveGlassIdx - 4), 0), false);
    }

    if (sliderOrnamentGlassRef.current) {
      const selectedOrnamentGlassIdx = catalogue.steklaOrn && catalogue.steklaOrn.findIndex((item) => form.glassType === item.id);
      sliderOrnamentGlassRef.current.slickGoTo(Math.max(Math.min(selectedOrnamentGlassIdx, selectedOrnamentGlassIdx - 4), 0), false);
    }
  }, [catalogue.steklaMot, catalogue.steklaOrn, tabMotivna, tabOrnamentna]);

  return (
    <>
      {(catalogue.steklaOrn || catalogue.steklaMot) && (
        <div className="section select-glass" id="conf-step-num-6" step="6" ref={ref}>
          <div className="container">
            <h2 className="upper-case">{t('confGlass')}</h2>
            <ul className="no-bullets flex-line tabs-menu tabs-menu--glass">
              <li className="tabs-menu__element one-half" style={{ display: 'flex', justifyContent: 'center' }}>
                <button name="tabMotivna" className={'cursor-pointer upper-case tabs-titles tabs-menu__link tabs-button-width ' + (tabMotivna && 'tabs-menu__link--active')} onClick={onTabChange}>
                  {t('confMotGlass')}
                </button>
              </li>
              <li className="tabs-menu__element one-half">
                <button name="tabOrnamentna" className={'cursor-pointer upper-case tabs-titles tabs-menu__link tabs-button-width ' + (tabOrnamentna && 'tabs-menu__link--active')} onClick={onTabChange}>
                  {t('confOrnGlass')}
                </button>
              </li>
            </ul>
            <div className="tabs tabs--glass">
              {tabMotivna && (
                <div id="motive-glass" className="tabs__tab tabs--glass__motivna">
                  <ul className={'no-bullets align-top '}>
                    {(() => {
                      const Children =
                        catalogue.steklaMot &&
                        catalogue.steklaMot.map((item, idx) => (
                          <li key={idx} className={'glass-specific more-colors__pattern inline-blocks ' + (form.motivType === item.id.id && ' more-colors__pattern--active ')} onClick={() => selectedMotiv(item)}>
                            <span id={form.motivType === item.id.id ? 'glass-border' : 'glass-border-none'} className={'glass-specific image-wrapper-flex-glass more-colors__pattern-color color-pattern--big '} style={!isMobile ? { border: '3px solid transparent' } : {}}>
                              <img className="glass-specific" src={url + item.id.previewSlika} alt={item.name} style={!isMobile ? { height: '100px !important' } : {}} />
                            </span>
                            <p className="more-colors__pattern-txt xSmall inline-as-block">{item.id.model}</p>
                          </li>
                        ));
                      return (
                        <Slider ref={sliderMotiveGlassRef} {...settings(width)}>
                          {Children}
                        </Slider>
                      );
                    })()}
                  </ul>
                </div>
              )}
              {tabOrnamentna && (
                <div id="ornament-glass" className="tabs__tab">
                  <ul className={'no-bullets align-top '}>
                    {(() => {
                      const Children =
                        catalogue.steklaOrn &&
                        catalogue.steklaOrn.map((item, idx) => (
                          <li key={idx} className={'more-colors__pattern ' + (form.glassType === item.id && ' more-colors__pattern--active ')} onClick={() => selectedGlass(item)}>
                            <span className="image-wrapper-flex more-colors__pattern-color inline-as-block color-pattern color-pattern--big">
                              <img src={url + item.previewPath} alt={item.name} />
                            </span>
                            <span className="more-colors__pattern-txt xSmall inline-as-block">{item.model}</span>
                          </li>
                        ));
                      return (
                        <Slider ref={sliderOrnamentGlassRef} {...settings(width)}>
                          {Children}
                        </Slider>
                      );
                    })()}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default Stekla;
