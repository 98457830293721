import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Context } from "./Context";

export default function List({ list, allowEmpty, title, mobile, mobileHeader, search, setSearch, setList }) {
  const { onComponentChange } = useContext(Context);
  const { t } = useTranslation();

  function onFilterSelect(name) {
    if (search) {
      setSearch("");
    }
    const newList = [...list];
    newList.map((item) => {
      if (allowEmpty === true && item.name === name && item.selected === true) {
        //item že zbran
        item.selected = false; //nastavi false
      } else if (item.name === name) {
        //item ni zbran
        item.selected = true; //nastavi true
      } else {
        item.selected = false; // preostali nastavi na false
      }
      return null;
    });

    if (!setList) {
      onComponentChange({
        list: newList,
      });
    } else {
      setList(newList);
    }
  }

  useEffect(() => {
    let programSelected;

    list &&
      list.forEach((item) => {
        if ((item.id === 2 || item.id === 5 || item.id === 74) && item.selected === true) {
          programSelected = true;
        }
      });

    if (search) {
      //če search ni prazen deselectamo vse filtre
      list &&
        list.forEach((item) => {
          item.selected = false;
        });
    } else if (programSelected !== true) {
      //če je search prazen in noben izmed filtrov programov ni izbran se po defaultu nastavi allDoors na selected
      list &&
        list.forEach((item) => {
          if (item.name === t("allDoors")) {
            item.selected = true;
          }
        });
    }
  }, [search]);

  return (
    <>
      {mobileHeader ? (
        <div className="header__secondary ">
          <ul className="type-of-doors buttons-in-line no-bullets flex-line  door-filter-container">
            {/*{list && list.map((item, index) => (*/}
            {/*    <li key={index} className={"square-round square-round--yes " + (item.selected && "square-round--active")} onClick={onFilterSelect} name={item.name}>*/}
            {/*        <span className="square-round__text no-wrap-text" name={item.name}>{t(item.name)}*/}
            {/*            {!search &&*/}
            {/*                (item.resultCount !== undefined && item.resultCount() !== 0 && !(item.name === 'allDoors de' && item.selected === false) &&*/}
            {/*                    '(' + item.resultCount() + ')'*/}
            {/*                )*/}
            {/*            }*/}
            {/*        </span>*/}
            {/*    </li>*/}
            {/*))}*/}
          </ul>
        </div>
      ) : mobile ? (
        <div className="mobile-header-filters__section">
          <h2 className="mobile-header-filters__title upper-case h2-filters">{title}</h2>
          <ul className="buttons-in-line overflow-buttons no-bullets flex-line" style={{ scrollbarWidth: "none" }}>
            {list &&
              list
                .filter(((item) => !item.directFilter || (item.directFilter && item.id===74)))
                .map((item, index) => (
                  <li key={index} className={"square-round " + (item.selected && "square-round--active")} onClick={() => onFilterSelect(item.name)}>
                    <span className="square-round__text no-wrap-text" name={item.name}>
                      {t(item.name)}{" "}
                    </span>
                  </li>
                ))}
          </ul>
        </div>
      ) : (
        <div className="desktop-filters__section">
          <h2 className="desktop-filters__title upper-case h2-filters">{title}</h2>
          <ul className="buttons-in-line overflow-buttons overflow-buttons--desktop-flex no-bullets flex-line">
            {list &&
              list
                .filter(((item) => !item.directFilter || (item.directFilter && item.id===74)))
                .map((item, index) => (
                  <li key={index}>
                    <div className={"checkbox-filter"} onClick={() => onFilterSelect(item.name)}>
                      <input className={"desktop-filter-checkbox"} type={"checkbox"} checked={item.selected} />
                      {t(item.name)}
                    </div>
                  </li>
                ))}
          </ul>
        </div>
      )}
    </>
  );
}
