//MULTI

import React, { useContext } from 'react';
import { Context } from 'shared-home/src/home/Context';
import Barvanje from './Barvanje';
import InoAPIclient from '../../api/InoAPIclient.js';
export default function BarveMultiBarvanja({ title, colForm, topList, list, mainColors, blackEdition = false, blackEditionLimitColors = false }) {
  const { form, onComponentChange, setCatalogue } = useContext(Context);
  let barveArray = form[colForm];
  const inoApiCall = new InoAPIclient();

  const setColors = () => {
    return (color, clicked, reset) => {
      const sestav = window.inoAPI.getSestav().getBarveUtil();
      let sestavaBarv = JSON.parse(window.inoAPI.getSestav().getBarveUtil().getBarve());
      let isInside = colForm.includes('Inside') ? true : false;
      //FUNKCIJA ZA NASTAVLJANJE NAPREDNIH BARV
      function setAdvancedColors(colorsArray, colorForm) {
        const newBarveArray = [];
        colorsArray.forEach((color, index) => {
          newBarveArray[index] = color;
        });

        const newBarveIdArray = newBarveArray.map((e) => e && e.barva && e.barva.composedId && e.barva.composedId.replace(/[^a-zA-Z0-9]/g, ''));
        onComponentChange({
          [colorForm]: newBarveArray,
          [colorForm + 'Id']: newBarveIdArray
        });
      }

      //RESET BARVE IN KLIC NOVEGA SESTAVA MAIN/ADVANCED
      if (reset) {
        if (mainColors) {
          sestav.resetAggregatedBarva(JSON.stringify(color), (success, error) => {
            if (success) {
              sestavaBarv = JSON.parse(window.inoAPI.getSestav().getBarveUtil().getBarve());
            } else if (error) {
              console.log('err');
            }
          });
        } else {
          sestav.resetBarvanLayer(JSON.stringify(color), isInside, (success, error) => {
            if (success) {
              sestavaBarv = JSON.parse(window.inoAPI.getSestav().getBarveUtil().getBarve());
            } else if (error) {
              console.log('err');
            }
          });
        }
      }
      //SET ZUNANJE NAPREDNE BARVE
      setAdvancedColors(sestavaBarv.zunanje.krilo, 'colorWingOutside');
      setAdvancedColors(sestavaBarv.zunanje.okvir, 'colorFrameOutside');
      sestavaBarv.zunanje.aplikacije.length > 0 && setAdvancedColors(sestavaBarv.zunanje.aplikacije, 'colorAplikacijeOutside');
      sestavaBarv.zunanje.dekor.length > 0 && setAdvancedColors(sestavaBarv.zunanje.dekor, 'colorDekorOutside');
      sestavaBarv.zunanje.okvircki.length > 0 && setAdvancedColors(sestavaBarv.zunanje.okvircki, 'colorOkvirckiOutside');

      //SET NOTRANJE NAPREDNE BARVE
      setAdvancedColors(sestavaBarv.notranje.krilo, 'colorWingInside');
      setAdvancedColors(sestavaBarv.notranje.okvir, 'colorFrameInside');
      sestavaBarv.notranje.aplikacije.length > 0 && setAdvancedColors(sestavaBarv.notranje.aplikacije, 'colorAplikacijeInside');
      sestavaBarv.notranje.dekor.length > 0 && setAdvancedColors(sestavaBarv.notranje.dekor, 'colorDekorInside');
      sestavaBarv.notranje.okvircki.length > 0 && setAdvancedColors(sestavaBarv.notranje.okvircki, 'colorOkvirckiInside');

      onComponentChange({
        colorsMain: sestavaBarv.aggregatedBarva
      });

      if (!mainColors && clicked) {
        const barvaLayer = JSON.parse(window.inoAPI.getSestav().getBarveUtil().getFilteredProspekt());
        if (sestavaBarv.enableBasicEditor) {
          let mainColorsList = [];
          let mainColorsListTop = [];
          onComponentChange({
            colorsMain: sestavaBarv.aggregatedBarva
          });
          sestavaBarv.aggregatedBarva.forEach((color) => {
            inoApiCall.barveTop({ types: color.types, filterProspekt: barvaLayer }).then((res) => {
              if (res) {
                mainColorsListTop.push(res);
              }
            });
            inoApiCall.barveTip({ types: color.types, filterProspekt: barvaLayer }).then((res) => {
              if (res) {
                mainColorsList.push(res);
              }
            });
          });
          setCatalogue((cat) => ({ ...cat, mainColorsListTop: mainColorsListTop, mainColorsList: mainColorsList }));
        }
      }
    };
  };

  const changeColor = (index) => {
    return (color) => {
      const newBarveArray = [...barveArray];
      newBarveArray[index] = color;
      const newBarveIdArray = newBarveArray.map((e) => e && e.barva && e.barva.composedId && e.barva.composedId.replace(/[^a-zA-Z0-9]/g, ''));
      onComponentChange({
        [colForm]: newBarveArray,
        [colForm + 'Id']: newBarveIdArray
      });
    };
  };

  //value id from url
  var colorIdsFromUrl = form[colForm + 'Id'];

  return <>{barveArray && barveArray.map((value, index) => <Barvanje key={index} title={title + (barveArray.length > 1 ? ' ' + (index + 1) : '')} colForm={colForm} topList={topList && topList[index]} list={list && list[index]} col={value} colType={mainColors ? value.types : value.type} colLayer={value.layer} mainColors={mainColors} colKey={index} colorIdFromUrl={colorIdsFromUrl && colorIdsFromUrl[index]} changeColor={changeColor(index)} setColors={setColors(index)} blackEdition={blackEdition} blackEditionLimitColors={blackEditionLimitColors} />)}</>;
}
