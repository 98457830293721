export const translationSk = {
	modelType: "Modely",
	chooseModel: "PROGRAMY VCHODOVÝCH DVIER",
	glass: "Sklo",
	modelShape: "Tvar skla",
	SearchApp: "Aplikácie na modeli",
	chosenModel: "Vybraný model",
	configureDoors: "Nakonfigurujte dvere",
	allDoors: "Všetko",
	findModel: "Hladať model",
	showResults: "Zobraziť Výsledok",
	filter: "Filter",
	save: "Uložte a pokračujte neskôr",
	sendInquiry: "Poslať dopyt",
	headerModel: "VYBERTE MODEL",
	configurator: "Konfigurátor",
	confDoorOpening: "Umiestnenie kľučky",
	confLeft: "Vľavo",
	confRight: "Vpravo",
	confConstructionType: "Typ konštrukcie",
	confDimensions: "Rozmery",
	confHeight: "Výška",
	confWidth: "Šírka",
	confHeightExample: "napr. 2100 mm",
	confWidthExample: "napr. 1050 mm",
	confExample: "napr.",
	confSkipDimensions: "Ak momentálne nemáte informácie o rozmeroch, môžete tento krok preskočiť",
	confColors: "Farba",
	confColWhole: "VŠETKY FARBY",
	confColAdvanced: "Rozšírený výber farieb",
	confCol1: "Farba 1",
	confCol2: "Farba 2",
	confMoreColors: "Zobraziť viac farieb",
	confColorTop: "TOP 10 najpredávanejších farieb",
	confFindColor: "hľadať farbu",
	confColorGroup: "F1 - FARBA SKUPINA 1",
	confColorShade: "odtieň",
	confColMa: "Matné",
	confColSt: "Štruktúra",
	confColShine: "lesklý",
	confColLess: "skryť farby",
	confColReset: "Obnoviť",
	confColWing: "Krídlo",
	confColOutside: "Exteriérový",
	confColInside: "Interiérový",
	confColFrame: "Dverový rám",
	confGlass: "Sklo",
	confMotGlass: "MOTÍV SKLA",
	confOrnGlass: "ORNAMENTOVÉ SKLO",
	confHandle: "Madlo",
	confHandleOutside: "MADLO VONKAJŠIE",
	confHandleInside: "Vnútorná kľučka",
	confHandleDesc: "Popis",
	confHandleDim: "Rozmery",
	confLock: "ZÁMOK",
	confLockTop: "Najpopulárnejší uzamykací systém",
	confLockTypes: "Zobraziť viac variantov",
	confLockAll: "Všetky uzamykacie systémy",
	confLockLess: "Skryť uzamykacie systémy",
	confExtras: "Príslušenstvo",
	confExtrasBattens: "Okapnica",
	confSummary: "Súhrn konfigurácie",
	confContinue: "Pokračujte neskôr",
	confLook: "Pohľad",
	confBackground: "pozadie",
	confPicture: "obrázok",
	confChooseModel: "vyberte model",
	confPickedModel: "Vybraný model",
	confSwitchModel: "zmeniť model",
	confHouseNo: "číslo domu",
	confPeephole: "kukátko",
	confKnocker: "Klopadlo",
	confHouseInput: "Zadajte číslo vášho domu",
	confAdjust: "Upraviť",
	confData: "vaše údaje",
	InqName: "Meno",
	InqSurname: "Priezvisko",
	InqAddress: "Ulica, č. domu",
	InqPost: "PSČ",
	InqCity: "Miesto",
	InqEmail: "E-mail",
	InqEmDesc: "Vašu e-mailovú adresu potrebujeme, aby sme Vám mohli poslať nezáväznú ponuku na Vaše vchodové dvere.",
	InqPhone: "Telefón",
	InqPhoneDesc: "Vaše telefónne číslo potrebujeme len v prípade potreby ďalších informácií pre správne vypracovanie cenovej ponuky.",
	InqMessage: "Chceš nám niečo povedať?",
	InqDoor: "Je táto ponuka pre rekonštrukciu alebo pre novostavbu?",
	InqMark: "Podľa toho špecifikujte",
	InqNew: "Novostavba",
	InqChange: "Rekonštrukcia",
	InqContact: "Konzultácie kvôli predaju a montáži",
	InqAgreement: "Súhlasím s tým, že pre potreby vypracovania cenovej ponuky, alebo inej pomoci, sa moje kontaktné údaje odošlú autorizovanému predajcovi INOTHERM!",
	InqNews: "Bezplatné a nezáväzné zasielanie brožúr poštou.",
	InqFinish: "Poslať žiadosť",
	InqRequired: "Povinné pole",
	InqSummary: "Súhrn konfigurácie",
	InqConfSummary: "Zoznam úplnej konfigurácie",
	InqPDF: "Stiahnuť ako PDF",
	InqShare: "zdieľať odkaz",
	InqVisit: "Navštívte náš showroom",
	InqModel: "Model",
	InqColOutside: "Exteriérový",
	InqColInside: "Interiérový",
	InqLeverInside: "Vnútorná kľučka",
	InqLeverOutside: "Madlo",
	InqHouseNo: "číslo domu",
	InqDripBattens: "Okapnica",
	InqKnocker: "Klopadlo",
	InqPeephole: "Kukátko",
	close: "Zavrieť",
	classicType: "Klasický",
	modernType: "Moderný",
	extravagantType: "Extravagantný",
	simpleType: "Jednoduchý",
	topSeller: "Najpredávanejšie",
	glassNone: "bez",
	glassSmall: "Malé",
	glassMedium: "Stredné",
	glassBig: "Veľké",
	shapeRectangle: "Obdĺžniky",
	shapeSquare: "Štvorce",
	shapeCurves: "Krivky a oblúky:",
	shapeOther: "Iné tvary",
	searchMulticolor: "S dekoráciou:",
	searchInox: "S ušľachtilou oceľou Apl.:",
	searchUtori: "Drážky",
	BGColor: "Vyberte farbu pozadia",
	SelectBGColor: "vyberte farbu",
	BGPictureTitle: "vložiť obrázok",
	BGPictureDesc: "Vložte obrázok svojho domova alebo vyberte obrázok z fotografií nižšie.",
	BGPicturestep1: "1. krok",
	BGPictureTip1: "Nahrajte alebo vyberte obrázok",
	BGPicturestep2: "2. krok",
	BGPictureTip2: "dvere umiestniť",
	BGPicturestep3: "3. krok",
	BGPictureTip3: "pokračovať v konfigurácii",
	BGPictureUpload: "Nahrajte obrázok z počítača",
	BGPictureDesc2: "alebo vyberte jeden z obrázkov nižšie",
	BGPictureChoose: "POUŽÍVAJTE OBRÁZOK",
	confOpening: "smer otvárania",
	confThankYouPage: "ďakujeme za Váš dotaz.",
	noModelResults: "Neboli nájdené žiadne výsledky.",
	confAppOut: "Aplikácia vonku",
	confAppIn: "Aplikácia vo vnútri",
	confDekorOut: "dekorácia vonku",
	confDekorIn: "dekor vo vnútri",
	confWindowsOut: "Vonkajší hliníkový ozdobný rám",
	confWindowsIn: "Vo vnútri hliníkový ozdobný rám",
	confHandleOutsideMainColor: "Vybraná farba",
	CustomerInformation: "vaše údaje",
	CustomerContact: "kontaktné údaje",
	CustomerNameSurname: "Meno a priezvisko",
	CustomerAddress: "Ulica a číslo domu",
	CustomerPostCity: "Poštové smerovacie číslo",
	CustomerCountry: "Krajina",
	CustomerEmail: "Emailové adresy",
	CustomerPhone: "Telefónne číslo",
	CustomerNote: "Poznámky k objednávke",
	CustomerDoorSetup: "Potrebujete dvere na rekonštrukciu alebo novostavbu?",
	TyPageDownload: "Preveďte konfiguráciu do PDF.",
	TyPageShare: "Zdieľajte odkaz na konfiguráciu.",
	TyPageAppreciation: "Teší nás a je nám cťou , že máte záujem o kúpu vchodových dverí Inotherm. Pevne veríme, že dvere sa budú perfektne hodiť do vášho domova. Ak si ich vyberiete, zaradíte sa medzi tisícky spokojných zákazníkov po celom svete, ktorých domácnosti sú už vybavené vchodovými dverami Inotherm.",
	TyPageHeader: "Ďakujeme za dopyt!",
	TyPageDescription1: "V tabuľke vyššie vidíte všetky detaily vašej aktuálnej konfigurácie, na ktorú dostanete aj nezáväznú ponuku do 24 hodín.",
	TyPageDescription2: "V krátkom čase sa vám ozve aj náš obchodný špecialista, s ktorým sa dohodnete na ďalšom postupe, ako sú zmeny konfigurácie, meranie otvoru v stene a konečná objednávka.",
	model: "Model",
	confHandleOutsideExtraColoring: "Ďalšie farby",
	confHandleOutsideColor: "Farba madla",
	confHandleOutsideAdditionalColor: "farba zápustného madla",
	confColorsOutside: "exteriérové farby",
	confColorsInside: "interiérové farby",
	TyPageCopyRights: "Všetky práva vyhradené 2023",
	logoLink: "https://www.inotherm.com/",
	InoWebsite: "Na náš web",
	positionHouseNum: "Umiestnenie čísla domu na dverách",
	positionKnocker: "Poloha klopadla na dverách",
	positionPeephole: "Poloha kukátka",
	positionInfo: "Pomocou nástroja nižšie umiestnite príslušenstvo do požadovanej polohy na dverách.",
	positionHorizontal: "HORIZONTÁLNY",
	positionVertical: "VERTIKÁLNY",
	headerFilterVsi: "Všetky modely",
	headerFilterAkcija24: "Akcia 24",
	headerFilterZimska: "Zimná akcia",
	headerFilterTop: "Top modely",
	headerFilterAkcija: "Black Edition",
	openFilters: "FILTER",
	clearFilters: "Vymazať filtre",
	useFilters: "Zobraziť výsledky",
	bannerText: "VYBERTE SI MODEL VCHODOVÝCH DVERÍ A ZAČNITE NAVRHOVAŤ SVOJ VCHOD",
	miniCokel: "nízky základný profil",
}
