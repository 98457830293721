export default class InoAPIclient {


  //STEKLA
  steklaOrnamentna = (data) => this.sendRequest('rest/ZasteklitevRestService/getOrnamentnaSteklaSestava ', 'POST', data);
  steklaZasteklitev = (data) => this.sendRequest('rest/ZasteklitevRestService/getMotiveCelote', 'POST', data);
  steklaOsvetloba = (data) => this.sendRequest('rest/ZasteklitevRestService/getMotivCeloteBySidepanel', 'POST', data);
  steklaNadsvetloba = (data) => this.sendRequest('rest/ZasteklitevRestService/getMotivCeloteByNadsvetloba', 'POST', data);

  //BARVE
  barveTop = (data) => this.sendRequest('rest/BarvaService/getTop10Barva', 'POST', data);
  barveKrilo = (data) => this.sendRequestXForm('rest/BarvaService/getKriloBarva', 'POST', data);
  barveOkvir = (data) => this.sendRequestXForm('rest/BarvaService/getOkvirBarva', 'POST', data);
  barveSkupaj = (data) => this.sendRequestXForm('rest/BarvaService/getSkupajBarva', 'POST', data);
  barveTip = (data) => this.sendRequest('rest/BarvaService/getBarvaByTip', 'POST', data);
  barveVse = (data) => this.sendRequest('rest/BarvaService/getAllBarve', 'POST');

  //ZAKLEPANJA
  zaklepanjaMehanizem = (data) => this.sendRequest('rest/DodatkiRestService/getDodatekMehanizemZaklepa/0', 'POST', data);

  //KLJUKE
  notranjeKljuke = (data) => this.sendRequestXForm('rest/KljukaRestService/getNotranjeKljuke', 'POST', {navzven: false, inoSerieSestav: data.serieSestav, prospektPopust: data.prospektPopust, blackEdition: data.blackEdition, akcija2024: data.akcija2024});

  //ZUNANJA DRZALA
  zunanjaDrzala = (data) => this.sendRequest('rest/KljukaRestService/getKljukeConfigurator', 'POST', data)

  //ODKAPNE LETVE
  dodatkiOdkapneLetve = (data) => this.sendRequest('rest/DodatkiRestService/getOdkapneLetve/0', 'POST', data);

  //KUKALA
  dodatkiKukala = (data) => this.sendRequest('rest/DodatkiRestService/getDodatekKukala/0', 'POST', data)

  //TOLKALA
  dodatekTolkala = (data) => this.sendRequest('rest/DodatkiRestService/getDodatekTolkala/0', 'POST', data);

  //TOLKALA
  dodatekMiniCokel = () => this.sendRequest('rest/DodatkiRestService/getDodatekMiniCokel/0', 'POST');

  //HISNE STEVILKE
  dodatekHisneStevilke = (data) => this.sendRequest('rest/DodatkiRestService/getDodatekHisnaStevilka/0', 'POST', data)

  //FASADE - UPDATE -
  // getFasadaUpload = (data) => this.sendRequest('./getFasadaImage/', 'GET', {data})
  // getFasadaUpload = (fasada_id, current_milis) => this.sendRequest('./getFasadaImage/', 'GET', {fasada_id, current_milis})
  // getFasadaUpload = (fasada_id, current_milis, data) => this.sendRequest('./getFasadaImage/' + fasada_id + '&' + current_milis + 'GET', data);


  handleErrors = (resp) => {
    if (resp.ok) {
      if (resp.status === 200) {
        const contentType = resp.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          return resp.json();
        } else if (contentType && contentType.includes('text/plain')) {
          return resp.text();
        } else {
          window.location.href = '/';
        }
      }
    } else {
      throw Error(resp.statusText);
    }
  };

  sendRequest = (path, method = 'GET', data = {}, key) => {
    const headers = new Headers();
    const parameters = {method: method, headers: headers};
    let _path = path;
    if (method === 'GET' && data && Object.keys(data).length > 0) {
      const queryString = Array.isArray(data) ? getArrayString(key, data) : getQueryString(data);
      _path += '?' + queryString;
    }
    if (method !== 'GET') parameters.body = JSON.stringify(data);
    if (method === 'POST' || method === 'PUT') {
      headers.append('Content-Type', 'application/json');
    }
    return fetch(_path, parameters)
      .then((resp) => this.handleErrors(resp))
      .catch(e => console.error(e));
  };

  sendRequestXForm = (path, method = 'GET', data = {}, key) => {
    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    return fetch(path, {
      method: method,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: formBody
    })
      .then((resp) => this.handleErrors(resp))
      .catch(e => console.error(e));

  };
}

function getQueryString(data) {
  return Object.keys(data).map((key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
  }).join('&');
}

function getArrayString(key, arr) {
  return arr.map(el => key + '=' + el).join('&');
}