import React, { useContext, useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import { Context } from 'shared-home/src/home/Context';
import { useTranslation } from 'react-i18next';
import { settings } from '../common/sliderSettings';
import DodatkiPremik from './DodatkiPremik';

const Dodatki = React.forwardRef((props, ref) => {
  const step = 5;

  function createPremikDodatkov(item, position, setter, label) {
    if (item) {
      if (!position) {
        position = [item.pozicija.x, item.pozicija.y];
      }

      const horizontal = (value) => {
        setter(item, position[0] + value, position[1]);
      };
      const vertical = (value) => {
        setter(item, position[0], position[1] + value);
      };

      return (
        <div className={'premik-dodatkov'}>
          <h3 className={'title'}>{t(label)}</h3>
          <div className={'xSmall'}>{t('positionInfo')}</div>
          <div style={{ display: 'inline-block', textAlign: 'center' }} className={'left'}>
            <div className={'image-container'}>
              <img className={'premik-image select-extras color-pattern--big '} src={'./assets/images/icons/premik-hort.png'} />
            </div>
            <label className={'direction-label xSmall'}>{t('positionHorizontal')}</label>
            <div>
              <div style={{ display: 'inline-flex' }} className={'slick-arrow slick-prev left'} onMouseDown={() => horizontal(-step)} />
              <div style={{ display: 'inline-flex' }} className={'slick-arrow slick-next right'} onMouseDown={() => horizontal(step)} />
            </div>
          </div>
          <div style={{ display: 'inline-block', textAlign: 'center' }} className={'right'}>
            <div className={'image-container'}>
              <img className={'premik-image select-extras color-pattern--big '} src={'./assets/images/icons/premik-vert.png'} />
            </div>
            <label className={'direction-label xSmall'}>{t('positionVertical')}</label>
            <div>
              <div style={{ display: 'inline-flex' }} className={'slick-arrow slick-down left'} onMouseDown={() => vertical(-step)} />
              <div style={{ display: 'inline-flex' }} className={'slick-arrow slick-up right'} onMouseDown={() => vertical(step)} />
            </div>
          </div>
        </div>
      );
    }
  }

  const { form, onComponentChange, catalogue } = useContext(Context);
  const [selectedHisnaSt, setSelectedHisnaSt] = useState(false);
  const [scrollPremikDodatkov, setScrollPremikDodatkov] = useState({ knocker: false, peephole: false, houseNumber: false });
  const sliderDripBattenRef = useRef();
  const sliderMiniCokelRef = useRef();
  const sliderHouseNumberRef = useRef();
  const sliderPeepholeRef = useRef();
  const sliderKnockerRef = useRef();
  const dovoljeniDodatki = form.defaults && form.defaults.dovoljeniDodatki;

  const { t } = useTranslation();

  //MOBILE CHECK
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    let elementId;
    if (scrollPremikDodatkov.knocker) {
      elementId = 'positionKnocker';
    } else if (scrollPremikDodatkov.peephole) {
      elementId = 'positionPeephole';
    } else if (scrollPremikDodatkov.houseNumber) {
      elementId = 'positionHouseNum';
    }

    if (elementId) {
      const dodatkiPremik = document.getElementById(elementId);
      dodatkiPremik.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [scrollPremikDodatkov]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width < 782 || height <= 612; // TRUE/FALSE
  // const isDesktopView = (width >= 782 && width < 1050); // TRUE/FALSE : za tablice!!!

  const selectedDripBatten = (item) => {
    if (form.dripBatten !== item.id) {
      onComponentChange({
        dripBatten: item.id
      });
    }

    if (form.dripBatten === item.id) {
      onComponentChange({
        dripBatten: undefined
      });
    }
  };

  const selectedMiniCokel = (item) => {
    if (form.miniCokel !== item.id) {
      onComponentChange({
        miniCokel: item.id
      });
    }

    if (form.miniCokel === item.id) {
      onComponentChange({
        miniCokel: undefined
      });
    }
  };

  const selectedKnocker = (item) => {
    const formKnocker = form.knocker;
    const formKnockerPosition = form.knockerPosition;

    if (formKnocker !== item.id) {
      if (!formKnocker) {
        setScrollPremikDodatkov({ knocker: true, houseNumber: false, peephole: false });
      }
      onComponentChange({
        knocker: item.id,
        knockerPosition: formKnockerPosition ? formKnockerPosition : [item.pozicija.x, item.pozicija.y]
      });
    }

    if (formKnocker === item.id) {
      onComponentChange({
        knocker: undefined,
        knockerPosition: undefined
      });
    }
  };

  const selectedKnockerPosition = (item, x, y) => {
    if (form.knocker === item.id && (form.knocker[0] != x || form.knocker[1] != y)) {
      onComponentChange({
        knocker: item.id,
        knockerPosition: [x, y]
      });
    }
  };

  const selectedPeephole = (item) => {
    if (form.peephole !== item.id) {
      if (!form.peephole) {
        setScrollPremikDodatkov({ knocker: false, houseNumber: false, peephole: true });
      }
      onComponentChange({
        peephole: item.id,
        peepholePosition: form.peepholePosition ? form.peepholePosition : [item.pozicija.x, item.pozicija.y]
      });
    }

    if (form.peephole === item.id) {
      onComponentChange({
        peephole: undefined
      });
    }
  };

  const selectedPeepholePosition = (item, x, y) => {
    if (form.peephole === item.id && (form.peephole[0] != x || form.peephole[1] != y)) {
      onComponentChange({
        peephole: item.id,
        peepholePosition: [x, y]
      });
    }
  };

  const setHisnaStColor = (item) => {
    let houseColId;
    if (form.houseNumberDef) {
      houseColId = form.houseNumberDef.id;
    }
    if (houseColId !== item.id || form.houseNumberDef === undefined) {
      if (!form.houseNumberColId) {
        setScrollPremikDodatkov({ knocker: false, houseNumber: true, peephole: false });
      }
      setSelectedHisnaSt(true);
      item.napis = form.houseNumber;

      onComponentChange({
        houseNumberColId: item.id,
        houseNumberDef: item,
        houseNumberPosition: form.houseNumberPosition ? form.houseNumberPosition : [item.pozicija.x, item.pozicija.y]
      });
    }

    if (houseColId === item.id) {
      setSelectedHisnaSt(false);
      onComponentChange({
        houseNumberColId: undefined,
        houseNumberDef: undefined,
        houseNumber: undefined
      });
    }
  };

  const selectedHouseNumberPosition = (item, x, y) => {
    if (form.houseNumberColId === item.id && (form.houseNumberPosition[0] != x || form.houseNumberPosition[1] != y)) {
      onComponentChange({
        houseNumberPosition: [x, y],
        houseNumberColId: item.id,
        houseNumberDef: item
      });
    }
  };

  const setHisnaSt = (e) => {
    if (e.target.value.length > 0) {
      form.houseNumberDef.napis = e.target.value;
      onComponentChange({
        houseNumber: e.target.value
      });
    } else {
      onComponentChange({
        houseNumber: undefined
      });
    }
  };

  //SCROLL INSTANTLY TO SELECTED EXTRAS ON RELOAD
  useEffect(() => {
    if (sliderDripBattenRef.current) {
      const selectedDripBattenIdx = catalogue.odkapneLetve && catalogue.odkapneLetve.findIndex((item) => form.dripBatten === item.id);
      sliderDripBattenRef.current.slickGoTo(Math.max(Math.min(selectedDripBattenIdx, selectedDripBattenIdx - 4), 0), false);
    }

    if (sliderHouseNumberRef.current) {
      const selectedHouseNumberIdx = catalogue.hisneStevilke && catalogue.hisneStevilke.findIndex((item) => form.houseNumberColId === item.id);
      sliderHouseNumberRef.current.slickGoTo(Math.max(Math.min(selectedHouseNumberIdx, selectedHouseNumberIdx - 4), 0), false);
    }

    if (sliderPeepholeRef.current) {
      const selectedPeepholeIdx = catalogue.kukala && catalogue.kukala.findIndex((item) => form.peephole === item.id);
      sliderPeepholeRef.current.slickGoTo(Math.max(Math.min(selectedPeepholeIdx, selectedPeepholeIdx - 4), 0), false);
    }

    if (sliderKnockerRef.current) {
      const selectedKnockerIdx = catalogue.tolkala && catalogue.tolkala.findIndex((item) => form.knocker === item.id);
      sliderKnockerRef.current.slickGoTo(Math.max(Math.min(selectedKnockerIdx, selectedKnockerIdx - 4), 0), false);
    }
  }, [form.dripBatten, form.houseNumberColId, form.peephole, form.knocker]);

  const currentKnocker = catalogue.tolkala ? catalogue.tolkala.find((e) => e.id === form.knocker) : undefined;
  const currentKnockerPosition = form.knockerPosition ? form.knockerPosition : currentKnocker ? [currentKnocker.pozicija.x, currentKnocker.pozicija.y] : undefined;

  const currentPeephole = catalogue.kukala ? catalogue.kukala.find((e) => e.id === form.peephole) : undefined;
  const currentPeepholePosition = form.peepholePosition ? form.peepholePosition : currentPeephole ? [currentPeephole.pozicija.x, currentPeephole.pozicija.y] : undefined;

  const currentHouseNumber = catalogue.hisneStevilke ? catalogue.hisneStevilke.find((e) => e.id === form.houseNumberColId) : undefined;
  const currentHouseNumberPosition = form.houseNumberPosition ? form.houseNumberPosition : currentHouseNumber ? [currentHouseNumber.pozicija.x, currentHouseNumber.pozicija.y] : undefined;

  const moreThanOneDodatek = dovoljeniDodatki && dovoljeniDodatki.tolkala + dovoljeniDodatki.kukala + dovoljeniDodatki.hisneStevilke + dovoljeniDodatki.odkapnaLetev > 1;

  return (
    <>
      <div className="section select-extras" id="conf-step-num-9" step="9" ref={ref}>
        <div className="container">
          {dovoljeniDodatki && dovoljeniDodatki.odkapnaLetev && (
            <>
              <h2 className="upper-case">{t('confExtras')}</h2>
              <h3 className="upper-case">{t('confExtrasBattens')}</h3>
              <ul className={'extras-list no-bullets align-top '}>
                {(() => {
                  const Children =
                    catalogue.odkapneLetve &&
                    catalogue.odkapneLetve.map((item, idx) => (
                      <li key={idx} className={'more-colors__pattern ' + (item.id === form.dripBatten && 'more-colors__pattern--active')} onClick={() => selectedDripBatten(item)}>
                        <span className="more-colors__pattern-color inline-as-block color-pattern color-pattern--big">
                          <img src={'./images/' + item.previewPath} alt={item.name} />
                        </span>
                        <span className="more-colors__pattern-txt xSmall inline-as-block">{item.model}</span>
                        <span className="more-colors__pattern-txt-extra square-round__text label-info" style={isMobile ? { width: '84px' } : {}}>
                          {item.infoB2C}
                        </span>
                      </li>
                    ));
                  return (
                    <Slider ref={sliderDripBattenRef} {...settings(width)}>
                      {Children}
                    </Slider>
                  );
                })()}
              </ul>
            </>
          )}
          {dovoljeniDodatki && dovoljeniDodatki.hisneStevilke && (
            <>
              {moreThanOneDodatek && <div className="ico-delimiter-line"></div>}
              <h3 className="upper-case">{t('confHouseNo')}</h3>
              <ul className={'extras-list no-bullets align-top '}>
                {(() => {
                  const Children =
                    catalogue.hisneStevilke &&
                    catalogue.hisneStevilke.map((item, idx) => (
                      <li key={idx} className={'more-colors__pattern ' + (item.id === form.houseNumberColId && 'more-colors__pattern--active')} onClick={() => setHisnaStColor(item)}>
                        <span className="more-colors__pattern-color inline-as-block color-pattern color-pattern--big">
                          <img src={'./images/' + item.previewPath} alt={item.name} />
                        </span>
                        <p className="more-colors__pattern-txt xSmall inline-as-block">{item.model}</p>
                        <p className="more-colors__pattern-txt-extra square-round__text label-info" style={isMobile ? { width: '84px' } : {}}>
                          {item.infoB2C}
                        </p>
                      </li>
                    ));
                  return (
                    <Slider ref={sliderHouseNumberRef} {...settings(width)}>
                      {Children}
                    </Slider>
                  );
                })()}
              </ul>
            </>
          )}
          {dovoljeniDodatki && dovoljeniDodatki.hisneStevilke && (form.houseNumber || selectedHisnaSt) && (
            <input
              type="text"
              value={form.houseNumber}
              name="houseNumber"
              maxLength="3"
              // {...selectedHisnaSt ? onChange={onInputChange} : ''}
              onChange={setHisnaSt}
              id="dimension-width"
              className="square-round square-round--active square-round--input-big"
              placeholder={t('confHouseInput')}
            />
          )}
          <DodatkiPremik item={currentHouseNumber} position={currentHouseNumberPosition} setter={selectedHouseNumberPosition} gwtSetter={'moveHisnaStevilka'} label={'positionHouseNum'}></DodatkiPremik>

          {dovoljeniDodatki && dovoljeniDodatki.kukala && (
            <>
              {moreThanOneDodatek && <div className="ico-delimiter-line"></div>}
              <h3 className="upper-case">{t('confPeephole')}</h3>
              <ul className={'extras-list no-bullets align-top '}>
                {(() => {
                  const Children =
                    catalogue.kukala &&
                    catalogue.kukala.map((item, idx) => (
                      <li key={idx} className={'more-colors__pattern ' + (item.id === form.peephole && 'more-colors__pattern--active')} onClick={() => selectedPeephole(item)}>
                        <span className="more-colors__pattern-color inline-as-block color-pattern color-pattern--big">
                          <img src={'./images/' + item.previewPath} alt={item.name} />
                        </span>
                        <p className="more-colors__pattern-txt xSmall inline-as-block">{item.model}</p>
                        <p className="more-colors__pattern-txt-extra square-round__text label-info" style={isMobile ? { width: '84px' } : {}}>
                          {item.infoB2C}
                        </p>
                      </li>
                    ));
                  return (
                    <Slider ref={sliderPeepholeRef} {...settings(width)}>
                      {Children}
                    </Slider>
                  );
                })()}
              </ul>
            </>
          )}
          <DodatkiPremik item={currentPeephole} position={currentPeepholePosition} setter={selectedPeepholePosition} gwtSetter={'moveKukalo'} label={'positionPeephole'}></DodatkiPremik>
          {/* INLINE STYLE CREATES THE LINE  */}
          {dovoljeniDodatki && dovoljeniDodatki.tolkala && (
            <>
              {moreThanOneDodatek && <div className="ico-delimiter-line"></div>}
              <h3 className="upper-case">{t('confKnocker')}</h3>
              <ul className={'extras-list no-bullets align-top '}>
                {(() => {
                  const Children =
                    catalogue.tolkala &&
                    catalogue.tolkala.map((item, idx) => (
                      <li key={idx} className={'more-colors__pattern ' + (item.id === form.knocker && ' more-colors__pattern--active')} onClick={() => selectedKnocker(item)}>
                        <span className="more-colors__pattern-color inline-as-block color-pattern color-pattern--big">
                          <img src={'./images/' + item.previewPath} alt={item.name} />
                        </span>
                        <p className="more-colors__pattern-txt xSmall inline-as-block">{item.model}</p>
                        <p className="more-colors__pattern-txt-extra square-round__text label-info" style={isMobile ? { width: '84px' } : {}}>
                          {item.infoB2C}
                        </p>
                      </li>
                    ));
                  return (
                    <Slider ref={sliderKnockerRef} {...settings(width)}>
                      {Children}
                    </Slider>
                  );
                })()}
              </ul>
            </>
          )}
          <DodatkiPremik item={currentKnocker} position={currentKnockerPosition} setter={selectedKnockerPosition} gwtSetter={'moveTolkalo'} label={'positionKnocker'}></DodatkiPremik>

          {window.inoAPI.getSestav().isMiniCokelEnabled() && !window.inoAPI.getSestav().isBlackEdition() && !window.inoAPI.getSestav().isAkcija2024() && (
            <>
              <h3 className="upper-case">{t('miniCokel')}</h3>
              <ul className={'extras-list no-bullets align-top '}>
                {(() => {
                  const Children =
                    catalogue.miniCokel &&
                    catalogue.miniCokel.map((item, idx) => (
                      <li key={idx} className={'more-colors__pattern ' + (item.id === form.miniCokel && 'more-colors__pattern--active')} onClick={() => selectedMiniCokel(item)}>
                        <span className="more-colors__pattern-color inline-as-block color-pattern color-pattern--big">
                          <img src={'./images/' + item.previewPath} alt={item.name} />
                        </span>
                        <span className="more-colors__pattern-txt xSmall inline-as-block">{item.model}</span>
                        <span className="more-colors__pattern-txt-extra square-round__text label-info" style={isMobile ? { width: '84px' } : {}}>
                          {item.infoB2C}
                        </span>
                      </li>
                    ));
                  return (
                    <Slider ref={sliderMiniCokelRef} {...settings(width)}>
                      {Children}
                    </Slider>
                  );
                })()}
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
});

export default Dodatki;
