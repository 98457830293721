import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom';
import App from './App';
import {initReactI18next} from 'react-i18next';
import i18n from 'i18next';
import {translationSl} from './langs/SL.js';
import {translationEn} from './langs/EN.js';
import {translationDe} from './langs/DE.js';
import {translationIt} from './langs/IT.js';
import {translationHr} from './langs/HR.js';
import {translationHu} from './langs/HU.js';
import {translationPl} from './langs/PL.js';
import {translationSk} from './langs/SK.js';
import {translationCs} from './langs/CS.js';
import {translationNl} from './langs/NL.js';
import {translationFr} from './langs/FR.js';
import {translationRo} from "./langs/RO.js";
import LanguageDetector from 'i18next-browser-languagedetector';
import {getPartnerCodeEmbedded} from 'shared-home/src/home/PartnerCode';


const langOptions = {
  order: ['cookie', 'path', 'querystring'],
  lookupFromPathIndex: 2,
  lookupQuerystring: 'lang',
  lookupCookie: 'lang',
}
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      sl: {translation: translationSl},
      en: {translation: translationEn},
      de: {translation: translationDe},
      it: {translation: translationIt},
      hr: {translation: translationHr},
      hu: {translation: translationHu},
      pl: {translation: translationPl},
      sk: {translation: translationSk},
      cs: {translation: translationCs},
      nl: {translation: translationNl},
      fr: {translation: translationFr},
      ro: {translation: translationRo},
    },
    detection: langOptions,
    fallbackLng: 'de',
    supportedLngs: ['de', 'en', 'sl', 'it', 'hr', 'hu', 'pl', 'sk', 'cs', 'nl', 'fr', 'ro'],
    interpolation: {escapeValue: false},
  });


const partnerCodeEmbedded = getPartnerCodeEmbedded();
if (partnerCodeEmbedded) {
  const linkElement = document.createElement("link");
  linkElement.setAttribute("rel", "stylesheet");
  linkElement.setAttribute("type", "text/css");
  linkElement.setAttribute("id", "custom-css");
  linkElement.setAttribute(
    "href",
    "partnerCSS?partnerCode=" + partnerCodeEmbedded
  );
  document.head.appendChild(linkElement);
}

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <App/>
    </HashRouter>
  </React.StrictMode>,

  document.getElementById('main')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

