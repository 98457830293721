import React, { useContext, useEffect, useState } from "react";
import { Context } from "./Context.jsx";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { conf } from "./const.js";
import DoorGrid from "./DoorGrid";
import FooterHome from "./FooterHome";
import homeParams from "./DomovURLParams";
import { FastFilters } from "./FastFilters";
import Konfiguracija from "./Konfiguracija";
import { getPartnerCodeEmbedded } from "./PartnerCode";
import { Modal } from "react-bootstrap";

function Domov({configPath, preloadSestav, showPrice}) {
  const navigate = useNavigate();

  const [doorData, setDoorData] = useState();
  const [search, setSearch] = useState();
  const [selectedModel, setSelectedModel] = useState();
  const [filtersMobile, setFiltersMobile] = useState(false);
  const [filtersDesktop, setFiltersDesktop] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState();
  const [bannerActive, setBannerActive] = useState(true);

  const { t } = useTranslation();
  let urlParams = useParams();

  const { form, onComponentChange } = useContext(Context);
  const abortController = React.useRef();

  //MOBILE CHECK
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768; // TRUE/FALSE
  const isPortrait = width < height;

  useEffect(() => {
    if (!form.izberiModel) {
      void fetch(`rest/DoorModelRestService/getDoorTreeKonfigurator`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json; charset=utf8",
        },
        body: getPartnerCodeEmbedded(),
      })
          .then(
              (response) => response.json(),
              (error) => console.log("An error occurred.", error)
          )
          .then((data) => {
            let izbiraModelaList = [];
            for (let i in data) {
              izbiraModelaList.push({
                name: data[i].desc.charAt(0) + data[i].desc.slice(1).toLowerCase(),
                label: data[i].desc.toLowerCase(),
                selected: false,
                directFilter: data[i].id === 74,
                id: data[i].id,
              });
            }
            izbiraModelaList.push({
              name: "all",
              label: t("allDoors"),
              selected: false,
              id: 0,
              directFilter: true,
            });

            onComponentChange({
              izberiModel: izbiraModelaList,
            });
          });
    }
  }, []);

  function updateSearch(search) {
    setSearch(search);
  }

  useEffect(() => {
    if (Object.keys(form).length === 0) {
      let filtered = Object.entries(urlParams).reduce((arr, [key, value]) => {
        if (
            (key === "izberiModel" && value !== "0") ||
            (key === "tipModela" && value !== "0") ||
            (key === "steklo" && value !== "0") ||
            (key === "oblikaStekla" && value !== "0") ||
            (key === "iskanje" && value !== "0") ||
            key === "directFilters"
        ) {
          arr && arr.push([key, value.split(",")]);
        }
        return arr;
      }, []);

      setSelectedFilters(filtered);
    }
  }, []);

  useEffect(() => {
    selectedFilters &&
    form.izberiModel &&
    selectedFilters.forEach((filtri) => {
      for (const [key, value] of Object.entries(form)) {
        if (filtri[0] === key) {
          if (key !== "directFilters") {
            filtri[1].forEach((filterURL) => {
              value &&
              value.forEach((filter) => {
                if (filterURL === filter.name) {
                  filter.selected = true;
                }

                if (filterURL !== filter.name && filter.name === t("allDoors")) {
                  filter.selected = false;
                }
              });
            });
          }
        }
      }
    });

    selectedFilters &&
    form.izberiModel &&
    selectedFilters.forEach((filtri) => {
      for (const [key, value] of Object.entries(form)) {
        if (filtri[0] === key) {
          if (key === "directFilters") {
            const urlValues = filtri[1];
            form.directFilters = { all: urlValues[0] === "1", sale: urlValues[1] === "1", top15: urlValues[2] === "1", akcija: urlValues[3] === "1" };
          }
        }
      }
    });

    // handling prejsnjega nacina, brez direktnih filtrov (ohranjanje oglasevalskih linkov)
    if (form.izberiModel) {
      let akcijaPredizbrana = false;
      form.izberiModel.forEach((i) => {
        if (i.selected && i.id === 32) {
          akcijaPredizbrana = true;
        }
      });
      if (akcijaPredizbrana) {
        form.directFilters = { all: false, sale: false, top15: false, akcija: true };
      }
    }
  }, [selectedFilters, form.izberiModel]);

  useEffect(() => {
    const url = "/home/" + homeParams(form);
    if (!url.includes("undefined")) {
      navigate(url);
    }
  }, [form]);

  useEffect(() => {
    if (!form.steklo || !form.oblikaStekla || !form.tipModela || !form.iskanje) {
      onComponentChange({
        steklo: conf.stekloList,
        oblikaStekla: conf.oblikaSteklaList,
        tipModela: conf.tipModelaList,
        iskanje: conf.iskanjeList,
        directFilters: { all: true, sale: false, top15: false, akcija: false },
      });
    }
  }, []);

  // function getSelecteds(options) {
  //     if (!options || !Array.isArray(options)) return [];
  //     else return options.map(e => e.selected);
  // }

  //POST ZA PRIDOBITEV VRAT GLEDE NA FILTRE
  useEffect(() => {
    if ((form.tipModela || form.oblikaStekla || form.steklo || form.iskanje) && form.izberiModel) {
      let tipMod = {
        klasicni: form.tipModela && form.tipModela[0].selected,
        moderni: form.tipModela && form.tipModela[1].selected,
        ekstravagantni: form.tipModela && form.tipModela[2].selected,
        enostavni: form.tipModela && form.tipModela[3].selected,
        topProdajni: form.tipModela && form.tipModela[4].selected,
      };

      let oblStek = {
        pravokotniki: form.oblikaStekla && form.oblikaStekla[0].selected,
        kvadrati: form.oblikaStekla && form.oblikaStekla[1].selected,
        okrogline: form.oblikaStekla && form.oblikaStekla[2].selected,
        ostalo: form.oblikaStekla && form.oblikaStekla[3].selected,
      };

      let kolStek = {
        brez: form.steklo && form.steklo[0].selected,
        malo: form.steklo && form.steklo[1].selected,
        srednje: form.steklo && form.steklo[2].selected,
        veliko: form.steklo && form.steklo[3].selected,
      };
      let directFilters;
      // if one direct filter selected, prepare data, else leave as null!
      const fdf = form.directFilters;
      if (fdf && (fdf.all || fdf.sale || fdf.top15 || fdf.akcija)) {
        directFilters = {
          all: fdf.all,
          sale: fdf.sale,
          top15: fdf.top15,
          akcija: fdf.akcija,
        };
      }
      let vecbarv = { yes: form.iskanje && form.iskanje[0].selected, no: false };
      let ino = { yes: form.iskanje && form.iskanje[1].selected, no: false };
      let ut = { yes: form.iskanje && form.iskanje[2].selected, no: false };

      if (abortController.current) {
        abortController.current.abort();
      }

      abortController.current = new AbortController();
      const { signal } = abortController.current;

      let rootIds = [];
      form.izberiModel.forEach((item) => {
        if (item.selected) {
          if (item.id === 0) {
            rootIds = [5, 2, 32, 74];
          }
          rootIds.push(item.id);
        }
      });

      void fetch(`rest/DoorModelRestService/getAllTuerenKonfigurator`, {
        signal: signal,
        method: "POST",
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json; charset=utf8",
        },
        body: JSON.stringify({
          modelSearch: {
            directFilters: directFilters,
            tipiModelovSearch: tipMod,
            oblikaSteklaSearch: oblStek,
            kolicinaSteklaSearch: kolStek,
            aplikacijeSearch: { vecbarvno: vecbarv, inox: ino, utori: ut },
            // "rootId": izberiMod ? izberiMod.id : null,
            model: search ? search : null,
            rootIds: rootIds,
          },
          cenikId: 0,
          selectedDin: { dinType: "E1_Levo_Noter", image_path: "DIN/E1-left-in.svg", levoDesno: "Levo", noterVen: "Noter", ime: "E1", styleName: null },
          selectedHeight: 2100,
          selectedWidth: 1050,
          kriloVisina: 0,
          kriloSirina: 0,
          pageSize: 2147483647,
          offset: 0,
          partnerCodeEmbedded: getPartnerCodeEmbedded(),
        }),
      })
          .then(
              (response) => response.json(),
              (error) => console.log("An error occurred.", error)
          )
          .then((data) => {
            if (data) {
              updateDoorData(data);
            }
          });
    }
  }, [JSON.stringify(form.tipModela), JSON.stringify(form.oblikaStekla), JSON.stringify(form.steklo), JSON.stringify(form.iskanje), JSON.stringify(form.izberiModel), form.directFilters, search, filtersMobile]);

  function updateDoorData(data) {
    // if (form.izberiModel && !search) {
    //   form.izberiModel.forEach(el => {
    //     el.resultCount = getCounterFor(data, el.resultCountInput);
    //   })
    // }
    setDoorData(data);
  }

  function filterGoBack() {
    for (let i in conf) {
      for (let j = 0; j <= conf[i]; j++) {
        conf[i].map((item) => {
          item.selected = false;
          return null;
        });
      }
    }
    setFiltersMobile(false);
  }

  let filterTitleId;
  form.izberiModel &&
  form.izberiModel.forEach((element) => {
    if (element.selected === true) {
      filterTitleId = element.id;
    }
  });

  const getTitle = () => {
    if (doorData[0] && (doorData[0].value || doorData[1].value || doorData[2].value)) {
      let firstDoorElement;

      if (filterTitleId === 74 && doorData[2].value) {
        return doorData[2].value[0].parentTitle;
      } else if (filterTitleId === 2 && doorData[1].value) {
        return doorData[1].value[0].parentTitle;
      } else if (filterTitleId === 5 && doorData[0].value) {
        return doorData[0].value[0].parentTitle;
      } else if (filterTitleId === 0 || !filterTitleId) {
        for (let i = 0; i < doorData.length; i++) {
          if (doorData[i] && doorData[i].value) {
            firstDoorElement = doorData[i].value[0].parentTitle;
            break;
          }
        }
        return firstDoorElement;
      }
    }
  };

  const noResults = () => {
    let selectedFilterId;
    let allDoors;

    form.izberiModel &&
    form.izberiModel.forEach((element) => {
      if (element.id === 0) {
        allDoors = element;
      }
    });

    if (form.izberiModel && allDoors.selected !== true) {
      form.izberiModel &&
      form.izberiModel.forEach((element) => {
        if (element.selected === true) {
          selectedFilterId = element.id;
        }
      });

      doorData &&
      doorData.forEach((el) => {
        if (el.treeId === selectedFilterId) {
          if (doorData[el].value) {
            return true;
          }
        }
      });
    } else if (doorData && doorData[0] && doorData[0].value === null && doorData[1].value === null && doorData[2].value === null) {
      return true;
    }
  };

  const clearFilters = () => {
    form.izberiModel.forEach((el) => (el.selected = false));
    form.tipModela.forEach((el) => (el.selected = false));
    form.oblikaStekla.forEach((el) => (el.selected = false));
    form.iskanje.forEach((el) => (el.selected = false));
    form.steklo.forEach((el) => (el.selected = false));
    onComponentChange({
      izberiModel: form.izberiModel,
      steklo: form.steklo,
      oblikaStekla: form.oblikaStekla,
      tipModela: form.tipModela,
      iskanje: form.iskanje,
    });
  };

  const goHomePage = () => {
    const getLink = window.inoAPI.getHomePageLink();

    if (getLink === null) {
      // navigate(`/${t('logoLink')}`, { replace: true })
      window.location.replace(`${t("logoLink")}`);
    } else {
      window.location.replace(`${getLink}`);
    }
  };
  const disableFilters = form.directFilters && (form.directFilters.akcija || form.directFilters.top15);

  const countFilters = () => {
    if (!disableFilters && !search) {
      let count = 0;
      form.izberiModel &&
      form.izberiModel.forEach((e) => {
        if (e.selected) {
          count++;
        }
      });
      form.tipModela &&
      form.tipModela.forEach((e) => {
        if (e.selected) {
          count++;
        }
      });
      form.oblikaStekla &&
      form.oblikaStekla.forEach((e) => {
        if (e.selected) {
          count++;
        }
      });
      form.steklo &&
      form.steklo.forEach((e) => {
        if (e.selected) {
          count++;
        }
      });
      form.iskanje &&
      form.iskanje.forEach((e) => {
        if (e.selected) {
          count++;
        }
      });
      return count > 0 ? "(" + count + ")" : "";
    }
    return "";
  };

  return (
      <>
        {bannerActive && (
            <div className="sales-banner mobile-hide">
              <p>{t("bannerText")}</p>
              <i className="ico ico-close-white" onClick={() => setBannerActive(false)}></i>
            </div>
        )}
        <div id="grid-wrapper">
          <header className="header fixed-position">
            <div className="header__main flex-line space-between">
              <div className="logo cursor-pointer" onClick={goHomePage}>
                <div className="logo__image"></div>
              </div>
              <div
                  className={"filter-hamburger flex-line " + (disableFilters && "disabled")}
                  onClick={() => {
                    if (!disableFilters) {
                      setFiltersMobile(!filtersMobile);
                    }
                  }}
                  style={{ cursor: "pointer" }}
              >
                <div className="filter-hamburger__ico">
                  <i className="ico ico-filter-hamburger"></i>
                </div>
                <div className="filter-hamburger__number">
                  <span>{countFilters()}</span>
                </div>
              </div>
            </div>
            <div className="header__secondary ">
              <ul className="type-of-doors buttons-in-line no-bullets flex-line  door-filter-container">
                <FastFilters initialState={form.directFilters} onFilterChange={(filterState) => console.log(filterState)}></FastFilters>
              </ul>
            </div>
            {filtersMobile === true && (
                <div className="mobile-header-filters-homepage">
                  <div className="mobile-header-filters__top">
                    <div className="mobile-header-filters__section mobile-header-filters__section--header">
                      <div className="close-filters flex-line">
                        <div className="close-filters__ico" onClick={() => filterGoBack()}>
                          <i className="ico ico-arrow-back"></i>
                        </div>
                        <div className="close-filters__text">
                          <h2 className="mobile-header-filters__title upper-case">{t("filter")}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="mobile-header-filters__section search-doors-mobile">
                      <p className="grid-doors-header__search search-combo">
                        <input type="search" id="search-doors-mobile" name="search-doors" placeholder={t("findModel")} value={search} onChange={(e) => updateSearch(e.target.value)} />
                        <button type="button" name="search-doors-action" className={"home-button"}>
                          <i className="ico ico-search-mobile"></i>
                        </button>
                      </p>
                    </div>
                    <Konfiguracija list={form.izberiModel} allowEmpty title={t("chooseModel")} search={search} setSearch={setSearch} mobile />
                    <Konfiguracija list={form.tipModela} allowEmpty title={t("modelType")} search={search} setSearch={setSearch} mobile />
                    <Konfiguracija list={form.steklo} allowEmpty title={t("glass")} search={search} setSearch={setSearch} mobile />
                    <Konfiguracija list={form.oblikaStekla} allowEmpty title={t("modelShape")} search={search} setSearch={setSearch} mobile />
                    <Konfiguracija list={form.iskanje} allowEmpty title={t("SearchApp")} search={search} setSearch={setSearch} mobile />
                  </div>
                  <div className="mobile-header-filters__section mobile-header-filters__section--footer flex-line align-stretch">
                    <button
                        className="button-footer button-footer--orange upper-case full flex-line justify-center home-button"
                        onClick={() => {
                          setFiltersMobile(!filtersMobile);
                        }}
                    >
                      <span>{t("showResults")}</span>
                    </button>
                  </div>
                </div>
            )}
          </header>
          <div style={{ backgroundColor: "#F9F9F9", height: "71px" }}>
            <div className={"door-filter-container door-selector-wrapper full-screen-container"}>
              <FastFilters initialState={form.directFilters} onFilterChange={(filterState) => console.log(filterState)}></FastFilters>
              <p className="grid-doors-header__search search-combo mobile-hide">
                <input type="search" id="search-doors" name="search-doors" placeholder={t("findModel")} value={search} onChange={(e) => updateSearch(e.target.value)} />
                <button type="button" name="search-doors-action" className={"home-button"}>
                  <i className="ico ico-search"></i>
                </button>
              </p>

              <button className={"home-button open-filters " + (disableFilters && "disabled")} disabled={disableFilters} onClick={() => setFiltersDesktop(true)}>
              <span>
                {t("openFilters")}
                {countFilters()}
              </span>
                <img src={"./assets/images/icons/filters/filter.svg"} />
              </button>
            </div>
          </div>
          <div className={"door-selector-wrapper main-canvas container " + ((isMobile || isPortrait) && "container-position")} style={{ width: "100%" }}>
            <div className="main-canvas grid-doors-wrapper full-screen-container">
              {/*<div className="grid-doors-header flex-line space-between mobile-hide">*/}
              {/*  <h1 className={"grid-doors-header__title upper-case mobile-hide"}>{getTitle()}</h1>*/}
              {/*</div>*/}

              {/* ONLY EXCLUSIV DOORS */}
              {/*{*/}
              {/*  (doorData[0] && (filterTitleId !== 2 && filterTitleId !== 32) &&*/}
              {/*    <DoorGrid doors={*/}
              {/*      (() => {*/}
              {/*        const filtered = filterDoors(doorData[0]);*/}
              {/*        displayedDoorCount += filtered.length;*/}
              {/*        return filtered;*/}
              {/*      })()*/}
              {/*    }>*/}
              {/*    </DoorGrid>*/}
              {/*  )*/}
              {/*}*/}
              <div className={"grid-doors"}>
                {/* MAP OVER THE REST */}
                {doorData && <DoorGrid doors={doorData} search={search} selectedModel={selectedModel} setSelectedModel={setSelectedModel} configPath={configPath} preloadSestav={preloadSestav} showPrice={showPrice}/>}
              </div>
              {/* NO MODEL RESULTS */}
              {Array.isArray(doorData) && doorData.length === 0 && (
                  <>
                    <p className="upper-case" style={{ textAlign: "center", padding: "250px 0", fontSize: "30px" }}>
                      {t("noModelResults")}
                    </p>
                    <div className="grid-doors" />
                  </>
              )}
            </div>
            {!isMobile && (
                <Modal backdrop="static" centered show={filtersDesktop}>
                  <div className="popup popup-wall-color">
                    <div className="popup__scroller popup-wall-color__thin">
                      <div className={"modal-title"}>
                        <span>{t("openFilters")}</span>
                      </div>
                      <button className="innerpopup__close home-button">
                        <i className="ico ico-close" onClick={() => setFiltersDesktop(false)} />
                      </button>
                      <div className="desktop-filters-modal innerpopup">
                        <div className="desktop-filters mobile-hide">
                          <div className="desktop-filters__top upper-case">
                            <Konfiguracija list={form.izberiModel} title={t("chooseModel")} allowEmpty search={search} setSearch={setSearch} />
                            <Konfiguracija list={form.tipModela} title={t("modelType")} allowEmpty search={search} setSearch={setSearch} />
                            <Konfiguracija list={form.steklo} title={t("glass")} allowEmpty search={search} setSearch={setSearch} />
                            <Konfiguracija list={form.oblikaStekla} title={t("modelShape")} allowEmpty search={search} setSearch={setSearch} />
                            <Konfiguracija list={form.iskanje} title={t("SearchApp")} allowEmpty search={search} setSearch={setSearch} />
                          </div>
                          <div className={"flex-line space-between"}>
                            <div className={"desktop-filters__clear-filters upper-case"} onClick={() => clearFilters()}>
                              {t("clearFilters")}
                            </div>
                            <button className={"desktop-filters__use-filters button-footer--orange upper-case home-button"} onClick={() => setFiltersDesktop(false)}>
                              {t("useFilters")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
            )}
          </div>
          <FooterHome selectedModel={form.modelName} configPath={configPath} preloadSestav={preloadSestav}></FooterHome>
        </div>
      </>
  );
}

export default Domov;
