import React from "react";
import { useTranslation } from "react-i18next";

export function FastFilter(props) {
  const { t } = useTranslation();

  return (
    <div onClick={() => props.onClick()} className={"fast-filter " + (props.selected ? "home-active" : "")}>
      <img src={"./assets/images/icons/filters/" + props.image} />
      <span>{t(props.label)}</span>
    </div>
  );
}
