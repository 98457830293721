import React, { useContext } from 'react';
import { t } from 'i18next';
import { cloneDeep } from 'lodash';
import { Context } from 'shared-home/src/home/Context';

const move = 5;
const intervalMs = 75;

function DodatkiPremik(props) {
  let position = cloneDeep(props.position);
  const item = props.item;
  const setter = props.setter;
  const gwtSetter = props.gwtSetter;
  const range = window.inoAPI ? JSON.parse(window.inoAPI.getSestav().getMoveApi().getDefaultRange()) : undefined;

  const { form } = useContext(Context);

  let fireInterval = null;

  function disableUserSelect() {
    document.body.style.setProperty('-webkit-touch-callout', 'none');
    document.body.style.setProperty('-webkit-user-select', 'none');
    document.body.style.setProperty('-webkit-text-size-adjust', 'none');
    document.body.style.setProperty('user-select', 'none');
  }

  function enableUserSelect() {
    document.body.style.removeProperty('-webkit-touch-callout');
    document.body.style.removeProperty('-webkit-user-select');
    document.body.style.removeProperty('-webkit-text-size-adjust');
    document.body.style.removeProperty('user-select');
  }

  function stopDefault(event) {
    event.stopPropagation();
    event.preventDefault();
    event.returnValue = false;
  }

  function left(event) {
    disableUserSelect();
    stopDefault(event);
    horizontal(-move);
    fireInterval = setInterval(() => horizontal(-move), intervalMs);
  }

  function right(event) {
    disableUserSelect();
    stopDefault(event);
    horizontal(move);
    fireInterval = setInterval(() => horizontal(move), intervalMs);
  }

  function down(event) {
    disableUserSelect();
    stopDefault(event);
    vertical(-move);
    fireInterval = setInterval(() => vertical(-move), intervalMs);
  }

  function up(event) {
    disableUserSelect();
    stopDefault(event);
    vertical(move);
    fireInterval = setInterval(() => vertical(move), intervalMs);
  }

  function stop(event) {
    enableUserSelect();
    stopDefault(event);
    clearInterval(fireInterval);
    setter(item, position[0], position[1]);
  }

  const horizontalSwitch = form.doorOpeningRightSide ? -1 : 1;

  function horizontal(value) {
    const x = position[0] + value * horizontalSwitch;
    if (x > range.rangeXStart && x < range.rangeXStop) {
      position = [x, position[1]];
      redraw();
    }
  }

  const vertical = (value) => {
    const y = position[1] + value;
    if (y > range.rangeYStart && y < range.rangeYStop) {
      position = [position[0], y];
      redraw();
    }
  };

  function redraw() {
    if (window.inoAPI) {
      window.inoAPI
        .getSestav()
        .getMoveApi()
        [gwtSetter](position[0], position[1], (r, e) => {
          let svg;
          if (form.doorViewInside) {
            svg = window.inoAPI.getDoorSketcher().getSVGNotranji();
          } else {
            svg = window.inoAPI.getDoorSketcher().getSVG();
          }
          document.getElementById('svgContainer').innerHTML = svg;
        });
    }
  }

  if (item && position) {
    return (
      <div className={'premik-dodatkov'} id={props.label}>
        <h3 className={'title'}>{t(props.label)}</h3>
        <div className={'xSmall'}>{t('positionInfo')}</div>
        <div style={{ display: 'inline-block', textAlign: 'center' }} className={'premik left'}>
          <div className={'image-container'}>
            <img className={'premik-image select-extras color-pattern--big '} src={'./assets/images/icons/premik-hort.png'} />
          </div>
          <label className={'direction-label xSmall'}>{t('positionHorizontal')}</label>
          <div>
            <div className={'action-container left'} style={{ display: 'inline-flex' }} onMouseDown={left} onMouseUp={stop} onMouseOut={stop} onTouchStart={left} onTouchEnd={stop} onTouchCancel={stop}>
              <div className={'slick-arrow slick-prev left'} />
            </div>
            <div className={'action-container right'} style={{ display: 'inline-flex' }} onMouseDown={right} onMouseUp={stop} onMouseOut={stop} onTouchStart={right} onTouchEnd={stop} onTouchCancel={stop}>
              <div className={'slick-arrow slick-next right'} />
            </div>
          </div>
        </div>
        <div style={{ display: 'inline-block', textAlign: 'center' }} className={'premik right'}>
          <div className={'image-container'}>
            <img className={'premik-image select-extras color-pattern--big '} src={'./assets/images/icons/premik-vert.png'} />
          </div>
          <label className={'direction-label xSmall'}>{t('positionVertical')}</label>
          <div>
            <div className={'action-container left'} style={{ display: 'inline-flex' }} onMouseDown={down} onMouseUp={stop} onMouseOut={stop} onTouchStart={down} onTouchEnd={stop} onTouchCancel={stop}>
              <div className={'slick-arrow slick-down left'} />
            </div>
            <div className={'action-container right'} style={{ display: 'inline-flex' }} onMouseDown={up} onMouseUp={stop} onMouseOut={stop} onTouchStart={up} onTouchEnd={stop} onTouchCancel={stop}>
              <div className={'slick-arrow slick-up right'} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
}

export default DodatkiPremik;
