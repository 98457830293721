export const translationEn = {
	modelType: "Model",
	chooseModel: "Entrance doors programmes",
	glass: "Glass",
	modelShape: "Glass shape",
	SearchApp: "Model applications",
	chosenModel: "Selected model",
	configureDoors: "Create your door",
	allDoors: "All",
	findModel: "Search model",
	showResults: "Show results",
	filter: "Filters",
	save: "Save and continue later",
	sendInquiry: "Send enquiry",
	headerModel: "Model selection",
	configurator: "Configurator",
	confDoorOpening: "DIN - Direction",
	confLeft: "Left",
	confRight: "Right",
	confConstructionType: "Type and construction",
	confDimensions: "Dimensions",
	confHeight: "Height",
	confWidth: "Width",
	confHeightExample: "e.g. 2100 mm",
	confWidthExample: "e.g.1050 mm",
	confExample: "e.g.",
	confSkipDimensions: "If you don't have the dimensions yet, you can skip this step.",
	confColors: "Colours",
	confColWhole: "ALL ELEMENTS COLOR",
	confColAdvanced: "Expand selection",
	confCol1: "Colour 1",
	confCol2: "Colour 2",
	confMoreColors: "Show more colours",
	confColorTop: "TOP 10 most sold colours",
	confFindColor: "Find colour",
	confColorGroup: "COLOUR GROUP",
	confColorShade: "Colour shade",
	confColMa: "Matte",
	confColSt: "Textured",
	confColShine: "Glossy",
	confColLess: "Hide expanded selection",
	confColReset: "Reset",
	confColWing: "Leaf",
	confColOutside: "Outside",
	confColInside: "Inside",
	confColFrame: "Frame Door",
	confGlass: "Glass",
	confMotGlass: "DESIGN MOTIF",
	confOrnGlass: "ORNAMENTAL GLASS",
	confHandle: "Handles",
	confHandleOutside: "EXTERIOR HANDLE",
	confHandleInside: "Lever inside",
	confHandleDesc: "Description",
	confHandleDim: "Dimensions",
	confLock: "LOCKS",
	confLockTop: "Most popular locking systems",
	confLockTypes: "Show more locking systems",
	confLockAll: "All locking systems",
	confLockLess: "Hide locking systems",
	confExtras: "Accessories",
	confExtrasBattens: "Protective trim",
	confSummary: "Configuration summary",
	confContinue: "Continue later",
	confLook: "View",
	confBackground: "Background",
	confPicture: "Photo",
	confChooseModel: "Door model selection",
	confPickedModel: "Selected model",
	confSwitchModel: "Change model",
	confHouseNo: "House number",
	confPeephole: "Digital door viewer",
	confKnocker: "Doorknocker",
	confHouseInput: "Insert house number",
	confAdjust: "Make adjustments",
	confData: "Your information",
	InqName: "Name",
	InqSurname: "Surname",
	InqAddress: "Street, Nr.",
	InqPost: "ZIP",
	InqCity: "Place",
	InqEmail: "E-mail",
	InqEmDesc: "We need your email address to send you an indicative quotation for your front door.",
	InqPhone: "Telephone",
	InqPhoneDesc: "We only require your phone number in case we need any additional information to prepare your quote.",
	InqMessage: "Anything you want to tell us?",
	InqDoor: "Is this enquiry for a Refurbishment or a New Build?",
	InqMark: "Mark accordingly",
	InqNew: "New Build",
	InqChange: "Refurbishment",
	InqContact: "Contact me for a quote / sales / technical information.",
	InqAgreement: "I agree that my contact details will be passed on to the seller or an authorised partner for the purpose of advice and quotation.",
	InqNews: "Send me a catalogue.",
	InqFinish: "Send enquiry",
	InqRequired: "Required field",
	InqSummary: "Configuration summary",
	InqConfSummary: "List of the complete configuration",
	InqPDF: "Download PDF",
	InqShare: "Share link",
	InqVisit: "Visit our showroom",
	InqModel: "Model",
	InqColOutside: "Outside",
	InqColInside: "Inside",
	InqLeverInside: "Lever inside",
	InqLeverOutside: "Handle",
	InqHouseNo: "House number",
	InqDripBattens: "Protective trim",
	InqKnocker: "Doorknocker",
	InqPeephole: "Digital door viewer",
	close: "Close",
	classicType: "Classic",
	modernType: "Modern",
	extravagantType: "Extravagant",
	simpleType: "Simple",
	topSeller: "Top sellers",
	glassNone: "Without",
	glassSmall: "Small",
	glassMedium: "Medium",
	glassBig: "Big",
	shapeRectangle: "Rectangles",
	shapeSquare: "Squares",
	shapeCurves: "Curves and arc",
	shapeOther: "Other shapes",
	searchMulticolor: "Decor",
	searchInox: "Steel applications",
	searchUtori: "Relief",
	BGColor: "Select background colour",
	SelectBGColor: "Select colour",
	BGPictureTitle: "Insert picture",
	BGPictureDesc: "Insert picture of your house or select one of the following photos",
	BGPicturestep1: "Step 1",
	BGPictureTip1: "Upload or select picture",
	BGPicturestep2: "Step 2",
	BGPictureTip2: "Position the door",
	BGPicturestep3: "Step 3",
	BGPictureTip3: "Continue with configuration",
	BGPictureUpload: "Upload picture from computer",
	BGPictureDesc2: "or choose a photo",
	BGPictureChoose: "use picture",
	confOpening: "Opening",
	confThankYouPage: "Thank you for your enquiry!",
	noModelResults: "No results",
	confAppOut: "Application outside",
	confAppIn: "Application inside",
	confDekorOut: "Decor outside",
	confDekorIn: "Decor inside",
	confWindowsOut: "Frames outside",
	confWindowsIn: "Frames inside",
	confHandleOutsideMainColor: "Choice of colour",
	CustomerInformation: "Your details",
	CustomerContact: "Contact details",
	CustomerNameSurname: "Name and surname",
	CustomerAddress: "Street and house number",
	CustomerPostCity: "Post code and place",
	CustomerCountry: "Country",
	CustomerEmail: "E-mail address",
	CustomerPhone: "Telephone number",
	CustomerNote: "Order notes",
	CustomerDoorSetup: "Do you need a door for a replacement or new build?",
	TyPageDownload: "Download configuration in pdf document",
	TyPageShare: "Share link to configuration",
	TyPageAppreciation: "We are delighted and honoured that you are interested in purchasing an Inotherm entrance door. We firmly believe that they would be a perfect match for your facade, and by choosing them, you would be joining the thousands of satisfied customers around the world whose homes are already adorned with Inotherm entrance doors.",
	TyPageHeader: "Thank you for your enquiry!",
	TyPageDescription1: "In the table above you can see all the details of your current configuration, for which you will also receive a no-obligation offer within the next 24 hours.",
	TyPageDescription2: "You will also be contacted shortly by one of our sales experts, who will be able to discuss the next steps, such as configuration changes, wall opening measurements and the final order.",
	model: "Model",
	confHandleOutsideExtraColoring: "Additional colours",
	confHandleOutsideColor: "Handle colour",
	confHandleOutsideAdditionalColor: "Colour of recessed part",
	confColorsOutside: "External colours",
	confColorsInside: "Interior colours",
	TyPageCopyRights: "All rights reserved 2023",
	logoLink: "https://www.inotherm.com/",
	InoWebsite: "To our website",
	positionHouseNum: "Position of the house number on the door",
	positionKnocker: "Position of the knocker on the door",
	positionPeephole: "Position of the peephole on the door",
	positionInfo: "Use the tool below to position the accessory in the desired position on the door.",
	positionHorizontal: "HORIZONTALLY",
	positionVertical: "VERTICAL",
	headerFilterVsi: "All models",
	headerFilterAkcija24: "Promotion 24",
	headerFilterZimska: "Winter action",
	headerFilterTop: "Best sellers",
	headerFilterAkcija: "Black Edition",
	openFilters: "FILTERS",
	clearFilters: "Clear all",
	useFilters: "Show results",
	bannerText: "CHOOSE AN ENTRANCE DOOR MODEL AND START DESIGNING YOUR ENTRANCE",
	miniCokel: "Low threshold profile",
}
