import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from 'shared-home/src/home/Context';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import navigateToConf from '../konfiguratorComponents/KonfiguratorURLParams';
import { getPartnerCodeEmbedded } from 'shared-home/src/home/PartnerCode';
import i18n from 'i18next';

function CustomerInfo() {
  const { form, onComponentChange } = useContext(Context);
  const { t } = useTranslation();
  const [requiredFields, setRequiredFields] = useState([]);
  const [novogradnja, setNovogradnja] = useState(2);
  let navigate = useNavigate();

  //Scroll to top on page render
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (window.inoAPI) {
      const fieldsString = window.inoAPI.getOffer().getRequiredFields();
      setRequiredFields(fieldsString);
    }
  }, [window.inoAPI]);

  //MOBILE CHECK
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768; // TRUE/FALSE

  const validate = (values) => {
    const errors = {};

    if (!values.inputName && requiredFields.includes('NAME')) {
      errors.inputName = t('InqRequired');
    }

    if (!values.inputLastName && requiredFields.includes('NAME')) {
      errors.inputLastName = t('InqRequired');
    }

    if (!values.inputAddress && requiredFields.includes('ADDRESS')) {
      errors.inputAddress = t('InqRequired');
    }

    if (!values.inputPostCode && requiredFields.includes('POST_NUMBER')) {
      errors.inputPostCode = t('InqRequired');
    }

    if (!values.inputPost && requiredFields.includes('POST_NAME')) {
      errors.inputPost = t('InqRequired');
    }

    if (!values.inputMarketingAgree && requiredFields.includes('GDPR_CONSENT')) {
      errors.inputMarketingAgree = t('InqRequired');
    }

    if (!values.typeOfDoorNeedNew && !values.typeOfDoorNeedChange && requiredFields.includes('CONSTRUCTION_TYPE')) {
      errors.typeOfDoorNeedNew = t('InqRequired');
      errors.typeOfDoorNeedChange = t('InqRequired');
    }

    if (!values.inputEmail) {
      errors.inputEmail = t('InqRequired');
    }

    if (values.inputEmail && !validateEmail(values.inputEmail)) {
      errors.inputEmail = t('InqInvalidEMail');
    }

    if (!values.inputTel && requiredFields.includes('PHONE')) {
      errors.inputTel = t('InqRequired');
    }

    return errors;
  };

  const validateEmail = (email) => {
    return email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };

  const formik = useFormik({
    initialValues: {
      inputName: '',
      inputLastName: '',
      inputAddress: '',
      inputPostCode: '',
      inputPost: '',
      inputEmail: '',
      inputTel: '',
      inputMessage: '',
      typeOfDoorNeedNew: false,
      typeOfDoorNeedChange: false,
      inputMarketingContact: false,
      inputMarketingAgree: false,
      inputMarketingNewsletter: false
    },
    validate
  });

  const onNovoGradnjaChange = (e, value, deselect) => {
    if (deselect) {
      formik.setFieldValue('typeOfDoorNeedNew', false);
      formik.setFieldValue('typeOfDoorNeedChange', false);
      setNovogradnja(2);
    } else {
      formik.setFieldValue('typeOfDoorNeedNew', value == 1);
      formik.setFieldValue('typeOfDoorNeedChange', value == 0);
      setNovogradnja(value);
    }
  };

  const onSubmitInquiry = () => {
    const values = formik.values;

    //formik.isValid is TRUE by default, one of the input fields must be added in condition to trigger isValid validation check
    if (formik.isValid === true && Object.keys(validate(values)).length === 0) {
      //TO TRIGGER "THANK YOU PAGE" LAYOUTS
      onComponentChange({
        customerName: formik.values.inputName,
        customerSurname: formik.values.inputLastName,
        customerAddress: formik.values.inputAddress,
        customerPostNo: formik.values.inputPostCode,
        customerCity: formik.values.inputPost,
        customerEmail: formik.values.inputEmail,
        customerPhone: formik.values.inputTel,
        customerNote: formik.values.inputMessage,
        customerSetupNewDoor: novogradnja == 1,
        customerSetupChangeDoor: novogradnja == 0
      });

      var kupecJSON = JSON.parse('{"kupec":"","telefon":"","mail":"","ulica":"","postnaStevilka":"","posta":"","comment":"","katalog":false,"consultingContact":false,"novogradnja":false,"menjava":false,"gdprCheck":false}');
      kupecJSON.kupec = values.inputName.trim() + ' ' + values.inputLastName.trim();
      kupecJSON.ulica = values.inputAddress;
      kupecJSON.postnaStevilka = values.inputPostCode;
      kupecJSON.mail = values.inputEmail;
      kupecJSON.posta = values.inputPost;
      kupecJSON.telefon = values.inputTel;
      kupecJSON.comment = values.inputMessage;
      kupecJSON.consultingContact = values.inputMarketingContact;
      kupecJSON.gdprCheck = values.inputMarketingAgree;
      kupecJSON.katalog = values.inputMarketingNewsletter;
      kupecJSON.novogradnja = novogradnja;
      console.log('send offer');
      const formString = JSON.stringify(kupecJSON);

      window.inoAPI.getOffer().sendOffer(formString, form.BGColor ? form.BGColor.id : '#EEEEEE', getPartnerCodeEmbedded(), form.configuratorURL, (r, e) => {
        console.log('Send offer, status=', r, ' Error: ', e);
      });
      console.log('sent, navigate to thank you pages');

      try {
        function getModelType() {
          const doorModelDetails = JSON.parse(window.inoAPI.getSestav().getModelFilterState());

          if (doorModelDetails.tipModela.length > 1) {
            return doorModelDetails.tipModela;
          } else {
            return doorModelDetails.tipModela[0];
          }
        }

        window.dataLayer.push({
          event: 'generate_lead',
          door_model: form.modelName,
          door_programme: form.modelProgram ? form.modelProgram : window.inoAPI.getSestav().getProgram(),
          door_model_type: form.modelType ? form.modelType : getModelType(),
          type_of_door_need: novogradnja ? 'new' : 'change',
          user_location: values.inputPost
        });
      } catch (e) {
        console.log('Strange error on gtm push', e);
      }
      navigate('/thankyou', { replace: true });
    } else {
      try {
        localStorage.setItem('form', JSON.stringify(values));
      } catch (e) {
        console.log('Local Storage Not Allowed!');
      }
    }
  };

  //SET VALUES IF USER ALREADY FILLED FIELDS
  useEffect(() => {
    try {
      const storage = localStorage.getItem('form');
      const values = formik.values;

      if (storage) {
        const parsed = JSON.parse(storage);

        values.inputName = parsed.inputName;
        values.inputLastName = parsed.inputLastName;
        values.inputAddress = parsed.inputAddress;
        values.inputPostCode = parsed.inputPostCode;
        values.inputEmail = parsed.inputEmail;
        values.inputPost = parsed.inputPost;
        values.inputTel = parsed.inputTel;
        values.inputMessage = parsed.inputMessage;
      }
    } catch (e) {
      console.log('Local Storage Not Allowed!');
    }
  }, []);

  const goHomePage = () => {
    const getLink = window.inoAPI.getHomePageLink();

    if (getLink === null) {
      // navigate(`/${t('logoLink')}`, { replace: true })
      window.location.replace(`${t('logoLink')}`);
    } else {
      window.location.replace(`${getLink}`);
    }
  };

  function setStorageValue(value) {
    try {
      localStorage.setItem('form', value);
    } catch (e) {
      console.log('Local Storage Not Allowed!');
    }
  }

  const lang = i18n.language;

  return (
    <>
      <div className="your-info-form-wrapper two-third">
        <header className="header fixed-position desktop-hide">
          <div className="header__main flex-line justify-center">
            <div className="logo cursor-pointer" onClick={goHomePage}>
              <div className="logo__image"></div>
            </div>
          </div>
        </header>
        <div className="form__header desktop-hide">
          <button
            onClick={() => {
              setStorageValue(JSON.stringify(formik.values));
              navigate('/configurator/' + navigateToConf(form));
            }}
            className="go-back cursor-pointer">
            <i className="ico ico-arrow-back"></i>
          </button>
          <h2 className="upper-case">{t('confData')}</h2>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className={'container--move-from-top ' + (isMobile && 'container')}>
            <div className="form__header">
              <button
                onClick={() => {
                  setStorageValue(JSON.stringify(formik.values));
                  navigate('/configurator/' + navigateToConf(form));
                }}
                className="go-back cursor-pointer">
                <i className="ico ico-arrow-back"></i>
              </button>
              <h2 className="upper-case">{t('confData')}</h2>
            </div>
            <div className="form__body">
              <div className="form__line flex-line-form space-between">
                <p className="form__input-wrapper one-half-with-space">
                  <input className="form__input square-round square-round--active" placeholder={requiredFields.includes('NAME') ? t('InqName') + '*' : t('InqName')} id="input-name" name="inputName" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.inputName} />
                  {formik.touched.inputName && formik.errors.inputName && <div className="field-validation">{formik.errors.inputName}</div>}
                </p>
                <p className="form__input-wrapper one-half-with-space">
                  <input className="form__input square-round square-round--active" placeholder={requiredFields.includes('NAME') ? t('InqSurname') + '*' : t('InqSurname')} id="input-lastname" name="inputLastName" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.inputLastName} />
                  {formik.touched.inputLastName && formik.errors.inputLastName && <div className="field-validation">{formik.errors.inputLastName}</div>}
                </p>
              </div>
              <div className="form__line">
                <p className="form__input-wrapper">
                  <input className="form__input square-round square-round--active" placeholder={requiredFields.includes('ADDRESS') ? t('InqAddress') + '*' : t('InqAddress')} id="input-address" name="inputAddress" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.inputAddress} />
                  {formik.touched.inputAddress && formik.errors.inputAddress && <div className="field-validation">{formik.errors.inputAddress}</div>}
                </p>
              </div>
              <div className="form__line flex-line-form space-between">
                <p className="form__input-wrapper one-half-with-space">
                  <input className="form__input square-round square-round--active" placeholder={requiredFields.includes('POST_NUMBER') ? t('InqPost') + '*' : t('InqPost')} id="input-postCode" name="inputPostCode" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.inputPostCode} />
                  {formik.touched.inputPostCode && formik.errors.inputPostCode && <div className="field-validation">{formik.errors.inputPostCode}</div>}
                </p>
                <p className="form__input-wrapper one-half-with-space">
                  <input className="form__input square-round square-round--active" placeholder={requiredFields.includes('POST_NAME') ? t('InqCity') + '*' : t('InqCity')} id="input-post" name="inputPost" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.inputPost} />
                  {formik.touched.inputPost && formik.errors.inputPost && <div className="field-validation">{formik.errors.inputPost}</div>}
                </p>
              </div>
              <div className="form__line">
                <p className="form__input-wrapper">
                  <input className="form__input square-round square-round--active" placeholder={t('InqEmail') + '*'} id="input-email" name="inputEmail" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.inputEmail} />
                  {formik.touched.inputEmail && formik.errors.inputEmail && <div className="field-validation">{formik.errors.inputEmail}</div>}
                  <label htmlFor="input-email">{t('InqEmDesc')}</label>
                </p>
              </div>
              <div className="form__line">
                <p className="form__input-wrapper">
                  <input className="form__input square-round square-round--active" placeholder={requiredFields.includes('PHONE') ? t('InqPhone') + '*' : t('InqPhone')} id="input-tel" name="inputTel" type="phone" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.inputTel} />
                  {formik.touched.inputTel && formik.errors.inputTel && <div className="field-validation">{formik.errors.inputTel}</div>}
                  <label htmlFor="input-tel">{t('InqPhoneDesc')}</label>
                </p>
              </div>
              <div className="form__line">
                <p className="form__input-wrapper">
                  <textarea className="form__input square-round square-round--active" placeholder={t('InqMessage')} id="input-message" name="inputMessage" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.inputMessage} />
                </p>
              </div>
              <div className="form__line">
                <h2 className="form__break-title upper-case semi-bold">{t('InqDoor')}</h2>
                <label className="form__break-subtitle">{requiredFields.includes('CONSTRUCTION_TYPE') ? t('InqMark') + '*' : t('InqMark')}</label>
                <fieldset name="type-of-door-need" className="form__button-group flex-line-form align-top">
                  <p className="form__input-wrapper">
                    <input
                      type="button"
                      name="typeOfDoorNeedNew"
                      id="type-of-door-need-new"
                      className={'square-round square-round--input-medium ' + (novogradnja === 1 && 'square-round--active')}
                      onClick={(e) => onNovoGradnjaChange(e, 1, novogradnja === 1)}
                      // value={formik.values.typeOfDoorNeedNew}
                      value={t('InqNew')}
                      onChange={() => formik.handleChange()}
                    />
                  </p>
                  <p className="form__input-wrapper">
                    <input
                      type="button"
                      name="typeOfDoorNeedChange"
                      id="type-of-door-need-change"
                      className={'square-round square-round--input-medium ' + (novogradnja === 0 && 'square-round--active')}
                      onClick={(e) => onNovoGradnjaChange(e, 0, novogradnja === 0)}
                      // value={formik.values.typeOfDoorNeedChange}
                      value={t('InqChange')}
                      onChange={() => formik.handleChange()}
                    />
                  </p>
                </fieldset>
                {requiredFields.includes('CONSTRUCTION_TYPE') && novogradnja == 2 && formik.touched.typeOfDoorNeedNew && formik.errors.typeOfDoorNeedNew && <div className="field-validation">{formik.errors.typeOfDoorNeedNew}</div>}
              </div>
              <div className="form__line">
                <p className="form__input-wrapper form__input-wrapper--checkbox flex-line align-top">
                  <input name="inputMarketingAgree" id="input-marketing-agree" type="checkbox" label={t('InqAgreement')} checked={formik.inputMarketingAgree} onChange={formik.handleChange} />
                  <label htmlFor="input-marketing-agree" className="form__label-for-checkbox" style={formik.touched.inputMarketingAgree && formik.errors.inputMarketingAgree ? { color: 'red' } : {}}>
                    {requiredFields.includes('GDPR_CONSENT') ? t('InqAgreement') + '*' : t('InqAgreement')}
                  </label>
                </p>
                {formik.touched.inputMarketingAgree && formik.errors.inputMarketingAgree && <div style={{ color: 'red', paddingBottom: '14px', paddingLeft: '34px', fontWeight: 'bold', fontSize: '14px' }}>{formik.errors.inputMarketingAgree}</div>}
                <p className="form__input-wrapper form__input-wrapper--checkbox flex-line align-top">
                  <input name="inputMarketingNewsletter" id="input-marketing-newsletter" type="checkbox" label={t('InqAgreement')} checked={formik.inputMarketingNewsletter} onChange={formik.handleChange} />
                  <label htmlFor="input-marketing-newsletter" className="form__label-for-checkbox">
                    {t('InqNews')}
                  </label>
                </p>

                <p className="form__input-wrapper form__input-wrapper--checkbox flex-line align-top">
                  <input name="inputMarketingContact" id="input-marketing-kontakt" type="checkbox" label={t('InqContact')} checked={formik.inputMarketingContact} onChange={formik.handleChange} />
                  <label htmlFor="input-marketing-kontakt" className="form__label-for-checkbox">
                    {t('InqContact')}
                  </label>
                </p>
              </div>
            </div>
          </div>
          {/* DESKTOP */}
          <footer className="form__footer footer mobile-hide">
            <div className="flex-line align-stretch">
              <button type="submit" onClick={() => onSubmitInquiry()} className="button-footer button-footer--orange upper-case full flex-line justify-center">
                {t('InqFinish')}
              </button>
            </div>
          </footer>
          {/* MOBILE */}
          <footer className="form__footer footer desktop-hide">
            <div className="flex-line align-stretch desktop-hide">
              <div className="footer-icon-links footer-button bg-dark-gray flex-line desktop-hide">
                <a>
                  <button
                    onClick={() => {
                      setStorageValue(JSON.stringify(formik.values));
                      navigate('/inquiryConfigurator', { replace: true });
                    }}
                    className="ico ico-pdf-file"></button>
                </a>
              </div>
              <button type="submit" onClick={() => onSubmitInquiry()} className="button-footer button-footer--orange upper-case justify-center" style={{ width: '100%' }}>
                {t('InqFinish')}
              </button>
            </div>
          </footer>
        </form>
      </div>
    </>
  );
}

export default CustomerInfo;
