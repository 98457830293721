//DODATKI
export const doorData = {
    odkapneLetve: [
        {
            id:'1',
            name: 'BREZ-ODKAPNE-LETVE',
            image: 'Brez-odkapne-letve'
        },
        {
            id:'2',
            name: 'Odkapna-letev_E-200',
            image: 'Odkapna-letev-E200'
        },
        {
            id:'3',
            name: 'Odkapna-letev-E300',
            image: 'Odkapna-letev-E300'
        },
        {
            id:'4',
            name: 'Odkapna-letev-E300B',
            image: 'Odkapna-letev-E300B'
        },
        {
            id:'5',
            name: 'Odkapna-letev-E300G',
            image: 'Odkapna-letev-E300G'
        },
        {
            id:'6',
            name: 'Odkapna-letev-E-200B',
            image: 'Odkapna-letev-E-200B'
        },
        {
            id:'7',
            name: 'Odkapna-letev-E-200G',
            image: 'Odkapna-letev-E-200G'
        },
        {
            id:'8',
            name: 'Odkapna-letev-E-200P',
            image: 'Odkapna-letev-E-200P'
        },
        {
            id:'9',
            name: 'Odkapna-letev-E-300P',
            image: 'Odkapna-letev-E-300P'
        },
    ],

    tolkala: [
        {
            id:'1',
            name: 'BT101',
        },
        {
            id:'2',
            name: 'BT103',
        },
        {
            id:'3',
            name: 'BT106',
        },
        {
            id:'4',
            name: 'BT107',
        },
    ],

    kukala: [
        {
            id:'1',
            name: 'Elektronsko kukalo',
            image: 'KUKALO-1'
        },
    ],

    hisna: [
        {
            id:'1',
            name: 'Nerjaveče jeklo',
            image: 'stevilka-he-1'
        }
    ],

    //DRZALA

    vsaDrzala: [
        {
            id:'1',
            name: 'GM_214',
            image: 'outside/GM_214'
        },
        {
            id:'2',
            name: 'GM_314',
            image: 'outside/GM_314'
        },
        {
            id:'3',
            name: 'GM_514',
            image: 'outside/GM_514'
        },
        {
            id:'4',
            name: 'Serija-60',
            image: 'outside/Serija-60'
        },
        {
            id:'5',
            name: 'Serija-70',
            image: 'outside/Serija-70'
        },
        {
            id:'6',
            name: 'Serija-80',
            image: 'outside/Serija-80'
        },
        {
            id:'7',
            name: 'Serija-140',
            image: 'outside/Serija-140'
        },
        {
            id:'8',
            name: 'Serija-150',
            image: 'outside/Serija-150'
        },
        {
            id:'9',
            name: 'Standardna-garnitura',
            image: 'outside/Standardna-garnitura'
        },
        {
            id:'10',
            name: 'Kljuka_Bela_Mat',
            image: 'inside/Kljuka_Bela_Mat'
        },
        {
            id:'11',
            name: 'Kljuka_Crna_Mat',
            image: 'inside/Kljuka_Crna_Mat'
        },
        {
            id:'12',
            name: 'Kljuka_Jeklo-mat',
            image: 'inside/Kljuka_Jeklo-mat'
        },
        {
            id:'13',
            name: 'Kljuka_Jeklo_polirana',
            image: 'inside/Kljuka_Jeklo_polirana'
        },
        {
            id:'14',
            name: 'Kljuka_Zlata_Mat',
            image: 'inside/Kljuka_Zlata_Mat'
        },
        {
            id:'15',
            name: 'ZIE-300',
            image: 'inside/ZIE-300'
        },
        {
            id:'16',
            name: 'ZIE-301',
            image: 'inside/ZIE-301'
        },
        {
            id:'17',
            name: 'ZIE-304',
            image: 'inside/ZIE-304'
        },
        {
            id:'18',
            name: 'ZIE-307',
            image: 'inside/ZIE-307'
        },
        {
            id:'19',
            name: 'ZIE-307-B',
            image: 'inside/ZIE-307-B'
        },
        {
            id:'20',
            name: 'ZIE-308',
            image: 'inside/ZIE-308'
        },
        {
            id:'21',
            name: 'ZIE-330',
            image: 'inside/ZIE-330'
        },
        {
            id:'22',
            name: 'ZIE-350',
            image: 'inside/ZIE-350'
        },
        {
            id:'23',
            name: 'ZIE-350-B',
            image: 'inside/ZIE-350-B'
        },
        {
            id:'24',
            name: 'ZIE-351',
            image: 'inside/ZIE-351'
        },
        {
            id:'25',
            name: 'ZIE-405',
            image: 'inside/ZIE-405'
        },
        {
            id:'26',
            name: 'ZIE-405-B',
            image: 'inside/ZIE-405-B'
        },
        {
            id:'27',
            name: 'ZIE-405-P',
            image: 'inside/ZIE-405-P'
        },
        {
            id:'28',
            name: 'ZIE-405-W',
            image: 'inside/ZIE-405-W'
        },
        {
            id:'29',
            name: 'ZIE-406',
            image: 'inside/ZIE-406'
        },
        {
            id:'30',
            name: 'ZIE-406-P',
            image: 'inside/ZIE-406-P'
        },
        {
            id:'31',
            name: 'ZIE-407',
            image: 'inside/ZIE-407'
        },
        {
            id:'32',
            name: 'ZIE-407-P',
            image: 'inside/ZIE-407-P'
        },
    ],

     //BARVE

     barve: {

        barveTop1: [
            {
                id: '1',
                name: 'RAL 9016',
                img: 'RAL9016.jpg'
            },
            {
                id: '2',
                name: 'RAL 9010',
                img: 'RAL9010.jpg'
            },
            {
                id: '3',
                name: 'RAL 8017',
                img: 'RAL8017.jpg'
            },
            {
                id: '4',
                name: 'RAL 8016',
                img: 'RAL8016.jpg'
            },
            {
                id: '5',
                name: 'RAL 8001',
                img: 'RAL8001.jpg'
            },
            {
                id: '6',
                name: 'RAL 5014',
                img: 'RAL5014.jpg'
            },
            {
                id: '7',
                name: 'RAL 3004',
                img: 'RAL3004.jpg'
            },
            {
                id: '8',
                name: 'RAL 3003',
                img: 'RAL3003.jpg'
            },
            {
                id: '9',
                name: 'RAL 1013',
                img: 'RAL1013.jpg'
            },
            {
                id: '10',
                name: 'HD_206',
                img: 'HD_206.jpg'
            },
        ],

        barvneSkupine1: [
            {
                id: '1',
                name: 'F1 - BARVE SKUPINA 1',
                img: 'RAL9016.jpg'
            },
            {
                id: '2',
                name: 'F2 - BARVE SKUPINA 2: Fino strukturna',
                img: 'RAL9010.jpg'
            },
            {
                id: '3',
                name: 'F2 - BARVE SKUPINA 2: Globoko matirana površina',
                img: 'RAL8017.jpg'
            },
            {
                id: '4',
                name: 'F3 - LESENI DEKORJI',
                img: 'RAL8017.jpg'
            },
            {
                id: '5',
                name: 'F4 - BARVE SKUPINA 4',
                img: 'RAL8001.jpg'
            },
            {
                id: '6',
                name: 'F5 - BARVE SKUPINA 5',
                img: 'RAL5014.jpg'
            },
            {
                id: '7',
                name: 'F6 - BARVE SKUPINA 6',
                img: 'RAL3004.jpg'
            },
            {
                id: '8',
                name: 'F7 - BARVE SKUPINA 7',
                img: 'RAL3003.jpg'
            },
            {
                id: '9',
                name: 'F8 - BARVE SKUPINA 8',
                img: 'RAL1013.jpg'
            },
            {
                id: '10',
                name: 'F9 - BARVE SKUPINA 9',
                img: 'HD_206.jpg'
            },
        ],

        barvniOdtenek1: [
            {
                id: '1',
                name: 'RAL 9016',
                img: 'RAL9016.jpg'
            },
            {
                id: '2',
                name: 'RAL 9010',
                img: 'RAL9010.jpg'
            },
            {
                id: '3',
                name: 'RAL 8017',
                img: 'RAL8017.jpg'
            },
            {
                id: '4',
                name: 'RAL 8016',
                img: 'RAL8016.jpg'
            },
            {
                id: '5',
                name: 'RAL 8001',
                img: 'RAL8001.jpg'
            },
            {
                id: '6',
                name: 'RAL 5014',
                img: 'RAL5014.jpg'
            },
            {
                id: '7',
                name: 'RAL 3004',
                img: 'RAL3004.jpg'
            },
            {
                id: '8',
                name: 'RAL 3003',
                img: 'RAL3003.jpg'
            },
            {
                id: '9',
                name: 'RAL 1013',
                img: 'RAL1013.jpg'
            },
            {
                id: '10',
                name: 'HD_206',
                img: 'HD_206.jpg'
            },
        ],

        barveTip1: [
            {
                id: '1',
                name: 'Matt',
            },
            {
                id: '2',
                name: 'Strukturna'
            },
            {
                id: '3',
                name: 'Sijaj'
            }
        ],

        barveTop2: [
            {
                id: '1',
                name: 'RAL 9016',
                img: 'RAL9016.jpg'
            },
            {
                id: '2',
                name: 'RAL 9010',
                img: 'RAL9010.jpg'
            },
            {
                id: '3',
                name: 'RAL 8017',
                img: 'RAL8017.jpg'
            },
            {
                id: '4',
                name: 'RAL 8016',
                img: 'RAL8016.jpg'
            },
            {
                id: '5',
                name: 'RAL 8001',
                img: 'RAL8001.jpg'
            },
            {
                id: '6',
                name: 'RAL 5014',
                img: 'RAL5014.jpg'
            },
            {
                id: '7',
                name: 'RAL 3004',
                img: 'RAL3004.jpg'
            },
            {
                id: '8',
                name: 'RAL 3003',
                img: 'RAL3003.jpg'
            },
            {
                id: '9',
                name: 'RAL 1013',
                img: 'RAL1013.jpg'
            },
            {
                id: '10',
                name: 'HD_206',
                img: 'HD_206.jpg'
            },
        ],

        barvneSkupine2: [
            {
                id: '1',
                name: 'RAL 9016',
                img: 'RAL9016.jpg'
            },
            {
                id: '2',
                name: 'RAL 9010',
                img: 'RAL9010.jpg'
            },
            {
                id: '3',
                name: 'RAL 8017',
                img: 'RAL8017.jpg'
            },
            {
                id: '4',
                name: 'RAL 8016',
                img: 'RAL8016.jpg'
            },
            {
                id: '5',
                name: 'RAL 8001',
                img: 'RAL8001.jpg'
            },
            {
                id: '6',
                name: 'RAL 5014',
                img: 'RAL5014.jpg'
            },
            {
                id: '7',
                name: 'RAL 3004',
                img: 'RAL3004.jpg'
            },
            {
                id: '8',
                name: 'RAL 3003',
                img: 'RAL3003.jpg'
            },
            {
                id: '9',
                name: 'RAL 1013',
                img: 'RAL1013.jpg'
            },
            {
                id: '10',
                name: 'HD_206',
                img: 'HD_206.jpg'
            },
        ],

        barvniOdtenek2: [
            {
                id: '1',
                name: 'RAL 9016',
                img: 'RAL9016.jpg'
            },
            {
                id: '2',
                name: 'RAL 9010',
                img: 'RAL9010.jpg'
            },
            {
                id: '3',
                name: 'RAL 8017',
                img: 'RAL8017.jpg'
            },
            {
                id: '4',
                name: 'RAL 8016',
                img: 'RAL8016.jpg'
            },
            {
                id: '5',
                name: 'RAL 8001',
                img: 'RAL8001.jpg'
            },
            {
                id: '6',
                name: 'RAL 5014',
                img: 'RAL5014.jpg'
            },
            {
                id: '7',
                name: 'RAL 3004',
                img: 'RAL3004.jpg'
            },
            {
                id: '8',
                name: 'RAL 3003',
                img: 'RAL3003.jpg'
            },
            {
                id: '9',
                name: 'RAL 1013',
                img: 'RAL1013.jpg'
            },
            {
                id: '10',
                name: 'HD_206',
                img: 'HD_206.jpg'
            },
        ],

        barveTip2: [
            {
                id: '1',
                name: 'Matt',
            },
            {
                id: '2',
                name: 'Strukturna'
            },
            {
                id: '3',
                name: 'Sijaj'
            }
        ],

        barveTop3: [
            {
                id: '1',
                name: 'RAL 9016',
                img: 'RAL9016.jpg'
            },
            {
                id: '2',
                name: 'RAL 9010',
                img: 'RAL9010.jpg'
            },
            {
                id: '3',
                name: 'RAL 8017',
                img: 'RAL8017.jpg'
            },
            {
                id: '4',
                name: 'RAL 8016',
                img: 'RAL8016.jpg'
            },
            {
                id: '5',
                name: 'RAL 8001',
                img: 'RAL8001.jpg'
            },
            {
                id: '6',
                name: 'RAL 5014',
                img: 'RAL5014.jpg'
            },
            {
                id: '7',
                name: 'RAL 3004',
                img: 'RAL3004.jpg'
            },
            {
                id: '8',
                name: 'RAL 3003',
                img: 'RAL3003.jpg'
            },
            {
                id: '9',
                name: 'RAL 1013',
                img: 'RAL1013.jpg'
            },
            {
                id: '10',
                name: 'HD_206',
                img: 'HD_206.jpg'
            },
        ],

        barvneSkupine3: [
            {
                id: '1',
                name: 'RAL 9016',
                img: 'RAL9016.jpg'
            },
            {
                id: '2',
                name: 'RAL 9010',
                img: 'RAL9010.jpg'
            },
            {
                id: '3',
                name: 'RAL 8017',
                img: 'RAL8017.jpg'
            },
            {
                id: '4',
                name: 'RAL 8016',
                img: 'RAL8016.jpg'
            },
            {
                id: '5',
                name: 'RAL 8001',
                img: 'RAL8001.jpg'
            },
            {
                id: '6',
                name: 'RAL 5014',
                img: 'RAL5014.jpg'
            },
            {
                id: '7',
                name: 'RAL 3004',
                img: 'RAL3004.jpg'
            },
            {
                id: '8',
                name: 'RAL 3003',
                img: 'RAL3003.jpg'
            },
            {
                id: '9',
                name: 'RAL 1013',
                img: 'RAL1013.jpg'
            },
            {
                id: '10',
                name: 'HD_206',
                img: 'HD_206.jpg'
            },
        ],

        barvniOdtenek3: [
            {
                id: '1',
                name: 'RAL 9016',
                img: 'RAL9016.jpg'
            },
            {
                id: '2',
                name: 'RAL 9010',
                img: 'RAL9010.jpg'
            },
            {
                id: '3',
                name: 'RAL 8017',
                img: 'RAL8017.jpg'
            },
            {
                id: '4',
                name: 'RAL 8016',
                img: 'RAL8016.jpg'
            },
            {
                id: '5',
                name: 'RAL 8001',
                img: 'RAL8001.jpg'
            },
            {
                id: '6',
                name: 'RAL 5014',
                img: 'RAL5014.jpg'
            },
            {
                id: '7',
                name: 'RAL 3004',
                img: 'RAL3004.jpg'
            },
            {
                id: '8',
                name: 'RAL 3003',
                img: 'RAL3003.jpg'
            },
            {
                id: '9',
                name: 'RAL 1013',
                img: 'RAL1013.jpg'
            },
            {
                id: '10',
                name: 'HD_206',
                img: 'HD_206.jpg'
            },
        ],

        barveTip3: [
            {
                id: '1',
                name: 'Matt',
            },
            {
                id: '2',
                name: 'Strukturna'
            },
            {
                id: '3',
                name: 'Sijaj'
            }
        ],

        barveTop4: [
            {
                id: '1',
                name: 'RAL 9016',
                img: 'RAL9016.jpg'
            },
            {
                id: '2',
                name: 'RAL 9010',
                img: 'RAL9010.jpg'
            },
            {
                id: '3',
                name: 'RAL 8017',
                img: 'RAL8017.jpg'
            },
            {
                id: '4',
                name: 'RAL 8016',
                img: 'RAL8016.jpg'
            },
            {
                id: '5',
                name: 'RAL 8001',
                img: 'RAL8001.jpg'
            },
            {
                id: '6',
                name: 'RAL 5014',
                img: 'RAL5014.jpg'
            },
            {
                id: '7',
                name: 'RAL 3004',
                img: 'RAL3004.jpg'
            },
            {
                id: '8',
                name: 'RAL 3003',
                img: 'RAL3003.jpg'
            },
            {
                id: '9',
                name: 'RAL 1013',
                img: 'RAL1013.jpg'
            },
            {
                id: '10',
                name: 'HD_206',
                img: 'HD_206.jpg'
            },
        ],

        barvneSkupine4: [
            {
                id: '1',
                name: 'RAL 9016',
                img: 'RAL9016.jpg'
            },
            {
                id: '2',
                name: 'RAL 9010',
                img: 'RAL9010.jpg'
            },
            {
                id: '3',
                name: 'RAL 8017',
                img: 'RAL8017.jpg'
            },
            {
                id: '4',
                name: 'RAL 8016',
                img: 'RAL8016.jpg'
            },
            {
                id: '5',
                name: 'RAL 8001',
                img: 'RAL8001.jpg'
            },
            {
                id: '6',
                name: 'RAL 5014',
                img: 'RAL5014.jpg'
            },
            {
                id: '7',
                name: 'RAL 3004',
                img: 'RAL3004.jpg'
            },
            {
                id: '8',
                name: 'RAL 3003',
                img: 'RAL3003.jpg'
            },
            {
                id: '9',
                name: 'RAL 1013',
                img: 'RAL1013.jpg'
            },
            {
                id: '10',
                name: 'HD_206',
                img: 'HD_206.jpg'
            },
        ],

        barvniOdtenek4: [
            {
                id: '1',
                name: 'RAL 9016',
                img: 'RAL9016.jpg'
            },
            {
                id: '2',
                name: 'RAL 9010',
                img: 'RAL9010.jpg'
            },
            {
                id: '3',
                name: 'RAL 8017',
                img: 'RAL8017.jpg'
            },
            {
                id: '4',
                name: 'RAL 8016',
                img: 'RAL8016.jpg'
            },
            {
                id: '5',
                name: 'RAL 8001',
                img: 'RAL8001.jpg'
            },
            {
                id: '6',
                name: 'RAL 5014',
                img: 'RAL5014.jpg'
            },
            {
                id: '7',
                name: 'RAL 3004',
                img: 'RAL3004.jpg'
            },
            {
                id: '8',
                name: 'RAL 3003',
                img: 'RAL3003.jpg'
            },
            {
                id: '9',
                name: 'RAL 1013',
                img: 'RAL1013.jpg'
            },
            {
                id: '10',
                name: 'HD_206',
                img: 'HD_206.jpg'
            },
        ],

        barveTip4: [
            {
                id: '1',
                name: 'Matt',
            },
            {
                id: '2',
                name: 'Strukturna'
            },
            {
                id: '3',
                name: 'Sijaj'
            }
        ],

        barveTop5: [
            {
                id: '1',
                name: 'RAL 9016',
                img: 'RAL9016.jpg'
            },
            {
                id: '2',
                name: 'RAL 9010',
                img: 'RAL9010.jpg'
            },
            {
                id: '3',
                name: 'RAL 8017',
                img: 'RAL8017.jpg'
            },
            {
                id: '4',
                name: 'RAL 8016',
                img: 'RAL8016.jpg'
            },
            {
                id: '5',
                name: 'RAL 8001',
                img: 'RAL8001.jpg'
            },
            {
                id: '6',
                name: 'RAL 5014',
                img: 'RAL5014.jpg'
            },
            {
                id: '7',
                name: 'RAL 3004',
                img: 'RAL3004.jpg'
            },
            {
                id: '8',
                name: 'RAL 3003',
                img: 'RAL3003.jpg'
            },
            {
                id: '9',
                name: 'RAL 1013',
                img: 'RAL1013.jpg'
            },
            {
                id: '10',
                name: 'HD_206',
                img: 'HD_206.jpg'
            },
        ],

        barvneSkupine5: [
            {
                id: '1',
                name: 'RAL 9016',
                img: 'RAL9016.jpg'
            },
            {
                id: '2',
                name: 'RAL 9010',
                img: 'RAL9010.jpg'
            },
            {
                id: '3',
                name: 'RAL 8017',
                img: 'RAL8017.jpg'
            },
            {
                id: '4',
                name: 'RAL 8016',
                img: 'RAL8016.jpg'
            },
            {
                id: '5',
                name: 'RAL 8001',
                img: 'RAL8001.jpg'
            },
            {
                id: '6',
                name: 'RAL 5014',
                img: 'RAL5014.jpg'
            },
            {
                id: '7',
                name: 'RAL 3004',
                img: 'RAL3004.jpg'
            },
            {
                id: '8',
                name: 'RAL 3003',
                img: 'RAL3003.jpg'
            },
            {
                id: '9',
                name: 'RAL 1013',
                img: 'RAL1013.jpg'
            },
            {
                id: '10',
                name: 'HD_206',
                img: 'HD_206.jpg'
            },
        ],

        barvniOdtenek5: [
            {
                id: '1',
                name: 'RAL 9016',
                img: 'RAL9016.jpg'
            },
            {
                id: '2',
                name: 'RAL 9010',
                img: 'RAL9010.jpg'
            },
            {
                id: '3',
                name: 'RAL 8017',
                img: 'RAL8017.jpg'
            },
            {
                id: '4',
                name: 'RAL 8016',
                img: 'RAL8016.jpg'
            },
            {
                id: '5',
                name: 'RAL 8001',
                img: 'RAL8001.jpg'
            },
            {
                id: '6',
                name: 'RAL 5014',
                img: 'RAL5014.jpg'
            },
            {
                id: '7',
                name: 'RAL 3004',
                img: 'RAL3004.jpg'
            },
            {
                id: '8',
                name: 'RAL 3003',
                img: 'RAL3003.jpg'
            },
            {
                id: '9',
                name: 'RAL 1013',
                img: 'RAL1013.jpg'
            },
            {
                id: '10',
                name: 'HD_206',
                img: 'HD_206.jpg'
            },
        ],

        barveTip5: [
            {
                id: '1',
                name: 'Matt',
            },
            {
                id: '2',
                name: 'Strukturna'
            },
            {
                id: '3',
                name: 'Sijaj'
            }
        ],

        barveTop6: [
            {
                id: '1',
                name: 'RAL 9016',
                img: 'RAL9016.jpg'
            },
            {
                id: '2',
                name: 'RAL 9010',
                img: 'RAL9010.jpg'
            },
            {
                id: '3',
                name: 'RAL 8017',
                img: 'RAL8017.jpg'
            },
            {
                id: '4',
                name: 'RAL 8016',
                img: 'RAL8016.jpg'
            },
            {
                id: '5',
                name: 'RAL 8001',
                img: 'RAL8001.jpg'
            },
            {
                id: '6',
                name: 'RAL 5014',
                img: 'RAL5014.jpg'
            },
            {
                id: '7',
                name: 'RAL 3004',
                img: 'RAL3004.jpg'
            },
            {
                id: '8',
                name: 'RAL 3003',
                img: 'RAL3003.jpg'
            },
            {
                id: '9',
                name: 'RAL 1013',
                img: 'RAL1013.jpg'
            },
            {
                id: '10',
                name: 'HD_206',
                img: 'HD_206.jpg'
            },
        ],

        barvneSkupine6: [
            {
                id: '1',
                name: 'RAL 9016',
                img: 'RAL9016.jpg'
            },
            {
                id: '2',
                name: 'RAL 9010',
                img: 'RAL9010.jpg'
            },
            {
                id: '3',
                name: 'RAL 8017',
                img: 'RAL8017.jpg'
            },
            {
                id: '4',
                name: 'RAL 8016',
                img: 'RAL8016.jpg'
            },
            {
                id: '5',
                name: 'RAL 8001',
                img: 'RAL8001.jpg'
            },
            {
                id: '6',
                name: 'RAL 5014',
                img: 'RAL5014.jpg'
            },
            {
                id: '7',
                name: 'RAL 3004',
                img: 'RAL3004.jpg'
            },
            {
                id: '8',
                name: 'RAL 3003',
                img: 'RAL3003.jpg'
            },
            {
                id: '9',
                name: 'RAL 1013',
                img: 'RAL1013.jpg'
            },
            {
                id: '10',
                name: 'HD_206',
                img: 'HD_206.jpg'
            },
        ],

        barvniOdtenek6: [
            {
                id: '1',
                name: 'RAL 9016',
                img: 'RAL9016.jpg'
            },
            {
                id: '2',
                name: 'RAL 9010',
                img: 'RAL9010.jpg'
            },
            {
                id: '3',
                name: 'RAL 8017',
                img: 'RAL8017.jpg'
            },
            {
                id: '4',
                name: 'RAL 8016',
                img: 'RAL8016.jpg'
            },
            {
                id: '5',
                name: 'RAL 8001',
                img: 'RAL8001.jpg'
            },
            {
                id: '6',
                name: 'RAL 5014',
                img: 'RAL5014.jpg'
            },
            {
                id: '7',
                name: 'RAL 3004',
                img: 'RAL3004.jpg'
            },
            {
                id: '8',
                name: 'RAL 3003',
                img: 'RAL3003.jpg'
            },
            {
                id: '9',
                name: 'RAL 1013',
                img: 'RAL1013.jpg'
            },
            {
                id: '10',
                name: 'HD_206',
                img: 'HD_206.jpg'
            },
        ],

        barveTip6: [
            {
                id: '1',
                name: 'Matt',
            },
            {
                id: '2',
                name: 'Strukturna'
            },
            {
                id: '3',
                name: 'Sijaj'
            }
        ],
    },

    // const barve = ['RAL9016', 'RAL9010', 'RAL8017']
    barveZunanjihDrzal: [
        {
            id: '1',
            image: 'RAL9016'
        },
        {
            id: '2',
            image: 'RAL9010'
        },
        {
            id: '3',
            image: 'RAL8017'
        },
    ],

    // const dimenzije = ['550 mm', '750 mm', '950 mm']
    dimenzijeZunanjihDrzal: [
        {
            id: '1',
            name: '550 mm'
        },
        {
            id: '2',
            name: '750 mm'
        },
        {
            id: '3',
            name: '950 mm'
        },
    ],

    //ODPIRANJE VRAT

    nacinOdpiranja: [
        {
            id: '1',
            name: 'Levo'
        },
        {
            id: '2',
            name: 'Desno'
        },
    ],

    //STEKLA

    stekla: {

        motivnaStekla: [
            {
                id:'1',
                name: 'MS - 6466',
                img: 'MS-6466.png'
            },
            {
                id:'2',
                name: 'MSS - 1',
                img: 'MSS-1.png'
            },
            {
                id:'3',
                name: 'MS-KLAR-OBEN',
                img: 'MS-KLAR-OBEN-1.png'
            },
            {
                id:'4',
                name: 'MS-KLAR-RAND 21',
                img: 'MS-KLAR-RAND-21.png'
            },
            {
                id:'5',
                name: 'MS-KLAR-RAND-3',
                img: 'MS-KLAR-RAND-3.png'
            },
            {
                id:'6',
                name: 'MS-KLAR-RAND_31',
                img: 'MS-KLAR-RAND_31.png'
            },
            {
                id:'7',
                name: 'MSS',
                img: 'MSS.png'
            }
        ],

        ornamentnaStekla: [
            {
                id:'8',
                name: 'Altdeutsch-K-beli',
                img: 'Altdeutsch-K-beli.png'
            },
            {
                id:'9',
                name: 'Altdeutsch-K-bronze',
                img: 'Altdeutsch-K-bronze.png'
            },
            {
                id:'10',
                name: 'Altdeutsch-K-rumeni',
                img: 'Altdeutsch-K-rumeni.png'
            },
            {
                id:'11',
                name: 'Chinchilla-beli',
                img: 'Chinchilla-beli.png'
            },
            {
                id:'12',
                name: 'Chinchilla-bronze',
                img: 'Chincilla-bronze.png'
            },
            {
                id:'13',
                name: 'Delta-beli',
                img: 'Delta-beli.png'
            },
            {
                id:'14',
                name: 'Gothik-beli',
                img: 'Gothik-beli.png'
            },
            {
                id:'15',
                name: 'Gusantik-beli',
                img: 'Gusantik-beli.png'
            },
            {
                id:'16',
                name: 'Katherdar-beli',
                img: 'Katherdar-beli.png'
            },
            {
                id:'17',
                name: 'Madras-Pave-beli',
                img: 'Madras-Pave-beli.png'
            },
            {
                id:'18',
                name: 'Madras-Pave-modri',
                img: 'Madras-pave-modri.png'
            },
            {
                id:'19',
                name: 'Madras-Uadi-beli',
                img: 'Madras-Uadi-beli.png'
            },
            {
                id:'20',
                name: 'Madras-Uadi-modri',
                img: 'Madras-Uadi-modri.png'
            },
            {
                id:'21',
                name: 'Mastercarre-beli',
                img: 'Mastercarre-beli.png'
            },
            {
                id:'22',
                name: 'Masterligne-beli',
                img: 'Masterligne-beli.png'
            },
            {
                id:'23',
                name: 'Masterpoint-beli',
                img: 'Masterpoint-beli.png'
            },
            {
                id:'24',
                name: 'Masterray-beli',
                img: 'Masterray-beli.png'
            },
            {
                id:'25',
                name: 'Ornament-504',
                img: 'Ornament-504.png'
            },
            {
                id:'26',
                name: 'Ornament-523-beli',
                img: 'Ornament-523-beli.png'
            },
            {
                id:'27',
                name: 'Ornament-597',
                img: 'Ornament-597.png'
            },
            {
                id:'28',
                name: 'Satinato-beli',
                img: 'Satinato-beli.png'
            },
            {
                id:'29',
                name: 'Satinato-modri',
                img: 'Satinato-modri.png'
            },
            {
                id:'30',
                name: 'Satinato-zeleni',
                img: 'Satinato-zeleni.png'
            }
        ]
    },


    //TIP KONSTRUKCIJE

    tip: [
        {
            id: '1',
            image: 'No-glass'
        },
        {
            id: '2',
            image: 'Left'
        },
        {
            id: '3',
            image: 'Right'
        },
        {
            id: '4',
            image: 'Left-and-right'
        },
        {
            id: '5',
            image: 'Up'
        },
        {
            id: '6',
            image: 'Left-and-up'
        },
        {
            id: '7',
            image: 'Right-and-up'
        },
        {
            id: '8',
            image: 'Left-Right-and-up'
        },

    ],

    //ZAKLEPANJE

    sistemiZaklepanja: [
        {
            id:'1',
            name: 'ATM-4',
            image: 'hand/ATM6'
        },
        {
            id:'2',
            name: 'BT-630',
            image: 'finger/BT-630'
        },
        {
            id:'3',
            name: 'BT-640',
            image: 'finger/BT-640'
        },
        {
            id:'4',
            name: 'BT-7',
            image: 'hand/BT-7'
        },
        {
            id:'5',
            name: 'ATM-6',
            image: 'hand/ATM6'
        },
        {
            id:'6',
            name: 'BT-5',
            image: 'hand/BT-5'
        },
        {
            id:'7',
            name: 'BTS-5',
            image: 'hand/BTS-5-TSH'
        },
        {
            id:'8',
            name: 'BT-1020',
            image: 'hand/BT-1020'
        },
        {
            id:'9',
            name: 'BT-600',
            image: 'finger/BT-600'
        },
        {
            id:'10',
            name: 'BT-610',
            image: 'finger/BT-610'
        },
        {
            id:'11',
            name: 'BT-620',
            image: 'finger/BT-620'
        },
        {
            id:'12',
            name: 'BT-630',
            image: 'finger/BT-630'
        },
        {
            id:'13',
            name: 'BT-640',
            image: 'finger/BT-640'
        },

    ],

    barveOzadja: [
        {
            id:'#F2F3F5',
            name: 'white',
        },
        {
            id:'#CECFD4',
            name: 'light-grey',
        },
        {
            id:'#BFBFBF',
            name: 'grey',
        },
        {
            id:'#B9CBCF',
            name: 'grey-blue',
        },
        {
            id:'#C2C9B7',
            name: 'grey-green',
        },
        {
            id:'#F0D083',
            name: 'yellow',
        },
        {
            id:'#E0A676',
            name: 'orange',
        },
        {
            id:'#C6877E',
            name: 'red',
        },
        {
            id:'#7EB3C1',
            name: 'blue',
        },
        {
            id:'#9EABB3',
            name: 'dark-blue',
        },
    ],

    slikeOzadja: [
        {
            id:'1',
            name: 'wall-1',
        },
        {
            id:'2',
            name: 'wall-2',
        },
        {
            id:'3',
            name: 'wall-3',
        },
        {
            id:'4',
            name: 'wall-4',
        },
        {
            id:'5',
            name: 'wall-5',
        },
    ]
}